import React, { useContext, useEffect, useRef, useState } from 'react'
import { Alert, Col, Container, Row, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FixedList } from 'react-recycled-list'
import { useDispatch, useSelector } from 'react-redux'
import cls from 'classnames'
import { differenceInMilliseconds } from 'date-fns'
import { DefaultText, LoadingSpinner } from 'src/core/components'
import { fetchOrders } from 'src/core/store/features/orders/orders'
import { RootState } from 'src/core/store/Store'
import { ThemeContext } from 'src/core/utils/theme/ThemeContext'

import './styles.scss'

import {
  Order,
  ORDERS_SUBSCRIPTION_COUNT,
  TableColumn,
  TFilterCondition,
} from '../../types'
import OrderDetailsModal from '../orderDetailsModal/OrderDetailsModal'
import { useSubOrders } from '../useSubOrders/useSubOrders'
import { OrderTableCell } from './ordersTableCell/OrdersTableCell'
import { OrdersTableHeader } from './ordersTableHeader/OrdersTableHeader'

type RenderRow = {
  firstRenderedRowIndex: number
  firstRenderedDataIndex: number
  lastRenderedRowIndex: number
  lastRenderedDataIndex: number
  lastRowIndex: number
}

type OrdersTableProps = {
  filters?: {
    [filterConditionName: string]: TFilterCondition
  } | null
  height: number
}

export const OrdersTable = ({ filters, height }: OrdersTableProps) => {
  const [t] = useTranslation()
  const dispatch: any = useDispatch()
  const {
    data: orders,
    loading: ordersLoading,
    isFetchedAll: isFetchedAllOrders,
    isFetching: isFetchingOrders,
    error,
  } = useSelector((state: RootState) => state.orders)

  const { subscribeOrders } = useSubOrders()
  const didFiltersMountRef = useRef(false)

  const { theme } = useContext(ThemeContext)
  const [orderDetails, setOrderDetails] = useState<Order | null>(null)

  const [filteredOrders, setFilteredOrders] = useState<Order[]>([])

  // const sortByFilters = useSelector((state: RootState) => state.filters.sortBy)

  const columns: TableColumn[] = [
    {
      title: t('orderFlow:table.time'),
      key: 'time',
      sortable: false,
    },
    {
      title: t('orderFlow:table.ticker'),
      key: 'ticker',
      sortable: false,
    },
    {
      title: t('orderFlow:table.expiration'),
      key: 'expirationDate',
      sortable: false,
    },
    {
      title: t('orderFlow:table.contract'),
      key: 'contract',
      sortable: false,
    },
    {
      title: t('orderFlow:table.strikePrice'),
      key: 'strikePrice',
      sortable: false,
    },
    {
      title: t('orderFlow:table.volumeOI'),
      key: 'volume',
      sortable: false,
    },
    {
      title: t('orderFlow:table.sizePrice'),
      key: 'details',
      sortable: false,
    },
    {
      title: t('orderFlow:table.premium'),
      key: 'premium',
      sortable: false,
    },
    {
      title: t('orderFlow:table.goldenSweep'),
      key: 'isGoldenSweep',
      sortable: false,
    },
    {
      title: t('orderFlow:table.iv'),
      key: 'impliedVolatility',
      sortable: false,
    },
    {
      title: t('orderFlow:table.boomAlgo'),
      key: 'boomAlgo',
      sortable: false,
    },
    {
      title: t('orderFlow:table.consolidation'),
      key: 'type',
      sortable: false,
    },
    {
      title: t('orderFlow:table.sector'),
      key: 'sector',
      sortable: false,
    },
  ]

  /**
   * Method used to fetch more orders while scrolling
   */
  const getOrders = async () => {
    const lastOrder =
      orders && orders.length > 0
        ? orders[orders.length - 1]
          ? orders[orders.length - 1]
          : orders[orders.length - 2]
        : undefined

    dispatch(
      fetchOrders({ conditions: filters, lastDocumentId: lastOrder?.id }),
    )
  }

  /**
   * Method that is triggered whenever the last row in the table is rendered
   */
  const onRenderedRowChange = (renderInfo: RenderRow) => {
    const { lastRenderedRowIndex, lastRowIndex } = renderInfo
    // If the last row is rendered (NOT visible yet!) and we are not already loading data, we fetch new data
    // If you want to fetch data when the last row is visible then use onVisibleRowChange
    if (
      !isFetchingOrders &&
      !ordersLoading &&
      !isFetchedAllOrders &&
      lastRowIndex > 0 &&
      lastRenderedRowIndex === lastRowIndex
    ) {
      getOrders()
    }
  }

  useEffect(() => {
    if (didFiltersMountRef.current) {
      subscribeOrders(filters)
    }
    didFiltersMountRef.current = true
  }, [filters])

  useEffect(() => {
    let formattedOrders: any = [...orders]
    formattedOrders = sortOrders(formattedOrders)
    if (!isFetchedAllOrders && formattedOrders.length > 0) {
      // For Loading Spinner
      formattedOrders = [...formattedOrders, undefined]
    }
    setFilteredOrders(formattedOrders)
  }, [orders])

  useEffect(() => {
    if (orders.length === ORDERS_SUBSCRIPTION_COUNT && !isFetchedAllOrders) {
      getOrders()
    }
  }, [orders])

  const sortOrders = (orders: Order[]) => {
    const key = filters?.boomAlgo ? 'boomAlgo' : 'time'
    orders = orders.sort((a: any, b: any) => {
      if (a[key] > b[key]) {
        return -1
      } else if (a[key] < b[key]) {
        return 1
      } else {
        return 0
      }
    })
    return orders
  }

  const displayOrderDetails = (details: Order) => {
    setOrderDetails(details)
  }

  const hideOrderDetails = () => {
    setOrderDetails(null)
  }

  const filtersChipsHeight =
    document.getElementById('filters-chips')?.getBoundingClientRect().height ??
    0

  const ordersTableHeader =
    document.getElementById('orders-table-header')?.getBoundingClientRect()
      .height ?? 33.5

  const tableHeight = height - filtersChipsHeight - ordersTableHeader

  const noData =
    (!filteredOrders.length || filteredOrders.length === 0) && !ordersLoading

  return (
    <>
      {ordersLoading ? (
        <div className='p-3'>
          <LoadingSpinner />
        </div>
      ) : error ? (
        <Container>
          <Row className='mt-4'>
            <Col sm={12}>
              <Alert variant='danger'>{t('common:loadFailed')}</Alert>
            </Col>
          </Row>
        </Container>
      ) : noData ? (
        <div className='text-center mt-3'>
          <DefaultText title={t('common:noData')} />
        </div>
      ) : (
        <div id='orders-table-wrapper'>
          <Table
            responsive='sm'
            className={cls('orders-table', { dark: theme === 'dark' })}
          >
            <OrdersTableHeader columns={columns} />
            <tbody>
              <FixedList
                height={tableHeight}
                rowComponent={(props) => {
                  return (
                    <OrderRow
                      {...props}
                      displayOrderDetails={displayOrderDetails}
                      columns={columns}
                    />
                  )
                }}
                data={filteredOrders}
                rowHeight={55}
                onRenderedRowChange={onRenderedRowChange}
              />
            </tbody>
          </Table>
        </div>
      )}
      {orderDetails && (
        <OrderDetailsModal
          data={orderDetails}
          hideOrderDetails={hideOrderDetails}
        />
      )}
    </>
  )
}

type OrderRowProps = {
  data: Order[]
  dataIndex: number
  top: number
  height: number
  displayOrderDetails: Function
  columns: TableColumn[]
}

// eslint-disable-next-line
const OrderRow = React.memo(
  ({
    data,
    dataIndex,
    top,
    height,
    displayOrderDetails,
    columns,
  }: OrderRowProps) => {
    const order: any = data[dataIndex]

    // Important!, make sure you inline-style your component with the the provided top, height. Also make sure to set your container element to position absolute
    return (
      <div
        style={{ top, height, position: 'absolute', padding: 0 }}
        className='react-recycled-row'
      >
        {order === undefined ? (
          <tr key='loading'>
            <div className='h-100 d-flex align-items-center justify-content-center'>
              <LoadingSpinner />
            </div>
          </tr>
        ) : (
          <tr
            key={order.id}
            className={
              differenceInMilliseconds(new Date(), order.animationDate) <= 3000
                ? 'animate'
                : ''
            }
            onClick={() => displayOrderDetails(order)}
          >
            {columns.map((column) => (
              <OrderTableCell
                key={column.key}
                dataKey={column.key}
                order={order}
              />
            ))}
          </tr>
        )}
      </div>
    )
  },
)
