import { useRef, useState } from 'react'
import { GrUploadOption } from 'react-icons/gr'
import cls from 'classnames'

import './styles.scss'

interface Props {
  file?: File
  onFileChange: (file: File) => void
  isUploading: boolean
}

const FileInput = ({ onFileChange, file }: Props) => {
  const [dragActive, setDragActive] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleDrag = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const onFileSubmit = (type: string, e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (type === 'drag') {
      if (
        e.dataTransfer &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length > 0
      ) {
        onFileChange(e.dataTransfer.files[0])
      }
    } else if (
      type === 'button' &&
      e.target.files &&
      e.target.files.length > 0
    ) {
      onFileChange(e.target.files[0])
    }
  }

  const onButtonClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <div
      className='file-input'
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={(e) => {
        onFileSubmit('drag', e)
      }}
      onClick={onButtonClick}
    >
      <div
        className={cls('file-drop', { 'drag-active': dragActive })}
        style={{
          height: 200,
        }}
      >
        <input
          ref={inputRef}
          type='file'
          accept='.csv'
          id='input-file-upload'
          onChange={(e) => {
            onFileSubmit('button', e)
          }}
        />
        {file ? (
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <GrUploadOption className='upload-icon mb-3' size={40} />
            <p className='title'>
              <span>{file.name}</span>
            </p>
          </div>
        ) : (
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <GrUploadOption className='upload-icon mb-3' size={40} />
            <p className='title'>
              <span>Drop File</span> here to upload or
            </p>
            <button className='choose-file mt-3'>Choose File</button>
          </div>
        )}
        <div />
      </div>
    </div>
  )
}

export default FileInput
