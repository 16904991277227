import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiCheckCircle } from 'react-icons/hi'
import cls from 'classnames'
import AlpacaIcon from 'src/assets/images/trade-journal/connections/alpaca-icon.svg'
import CharlesSchwabIcon from 'src/assets/images/trade-journal/connections/charles-schwab-icon.svg'
import ETradeIcon from 'src/assets/images/trade-journal/connections/e-trade-icon.svg'
import FidelityIcon from 'src/assets/images/trade-journal/connections/fidelity-icon.svg'
import RobinHoodIcon from 'src/assets/images/trade-journal/connections/robin-hood-icon.svg'
import TDIcon from 'src/assets/images/trade-journal/connections/td-icon.svg'
import WebullIcon from 'src/assets/images/trade-journal/connections/webull-icon.svg'
import { DefaultButton } from 'src/core/components'
import DefaultModal from 'src/core/components/modals/DefaultModal/DefaultModal'
import {
  useDeleteBrokerageAccountMutation,
  useGetBrokeragesConnectedQuery,
} from 'src/core/store/features/tradeJournal/api'
import { displaySuccessMessage } from 'src/core/utils/alerts/ToastAlerts'

import './styles.scss'

const clientId = '2QR4CUP4VGNGPI9X9HRQCYQJYTVXZJDD'

export interface ChosenConnection {
  id: string
  type: 'brokerage' | 'csv'
}

interface Props {
  visible: any
  hideDetails: any
  onSubmit?: (arg0: ChosenConnection) => void
}

const AddBrokerageConnection = ({ visible, hideDetails, onSubmit }: Props) => {
  const [t] = useTranslation('tradeJournal')
  const [connection, setConnection] = useState<ChosenConnection | undefined>(
    undefined,
  )

  const {
    data: brokeragesConnected,
    isLoading,
    refetch,
  } = useGetBrokeragesConnectedQuery()
  const [deleteBrokerageAccount, { isLoading: isDeleting }] =
    useDeleteBrokerageAccountMutation()

  const isAlreadyConnected = useMemo(() => {
    if (!connection) return false

    if (!isLoading) {
      const brokerage = brokeragesConnected?.find(
        (b) => b.brokerage === connection.id && b.isConnected,
      )
      return !!brokerage
    }
    return false
  }, [brokeragesConnected, connection, isLoading])

  const [showClearModal, setShowClearModal] = useState(false)

  const handleSubmit = () => {
    if (isAlreadyConnected) {
      setShowClearModal(true)
    } else {
      if (
        connection?.type === 'brokerage' &&
        connection?.id === 'td_ameritrade'
      ) {
        const redirectUri = window.location.href
        window.location.replace(
          `https://auth.tdameritrade.com/auth?response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}%40AMER.OAUTHAP&scope=AccountAccess+PlaceTrades`,
        )
      } else if (connection?.type === 'csv') {
        if (onSubmit) {
          onSubmit(connection)
        }
      }
    }
  }

  return (
    <DefaultModal
      visible={visible}
      onHide={hideDetails}
      title={t('addConnection.title')}
      DescriptionNode={
        <div className='add-brokerage-connection-modal-header mb-0 mt-3'>
          <p className='header-desc'>{t('addConnection.desc')}</p>
        </div>
      }
    >
      <div className='add-brokerage-connection-modal'>
        <div className='connections-container'>
          <ConnectionBox
            title={t('addConnection.ameritrade')}
            img={TDIcon}
            onClick={() => {
              setConnection({ id: 'td_ameritrade', type: 'brokerage' })
            }}
            isSelected={
              connection?.id === 'td_ameritrade' &&
              connection.type === 'brokerage'
            }
          />
          <ConnectionBox
            title={t('addConnection.robinHood')}
            img={RobinHoodIcon}
            isDisabled
          />
          <ConnectionBox
            title={t('addConnection.eTrade')}
            img={ETradeIcon}
            isDisabled
          />
        </div>
        <div className='connections-container mt-3'>
          <ConnectionBox
            title={t('addConnection.charlesSchwab')}
            img={CharlesSchwabIcon}
            isDisabled
          />
          <ConnectionBox
            title={t('addConnection.fidelity')}
            img={FidelityIcon}
            isDisabled
          />
          <ConnectionBox
            title={t('addConnection.alpaca')}
            img={AlpacaIcon}
            isDisabled
          />
        </div>
        <hr className='mt-4' />
        <p className='title'>{t('addConnection.csvTitle')}</p>
        <p className='desc'>{t('addConnection.csvDesc')}</p>
        <div className='connections-container mt-3'>
          <ConnectionBox
            title={t('addConnection.ameritrade')}
            isSelected={
              connection?.id === 'td_ameritrade' && connection.type === 'csv'
            }
            img={TDIcon}
            onClick={() => {
              setConnection({ id: 'td_ameritrade', type: 'csv' })
            }}
          />
          <ConnectionBox
            title={t('addConnection.webull')}
            isSelected={
              connection?.id === 'webull' && connection.type === 'csv'
            }
            img={WebullIcon}
            onClick={() => {
              setConnection({ id: 'webull', type: 'csv' })
            }}
          />
          <div className='w-100' />
        </div>
        <div className='d-flex align-items-center justify-content-end mt-5'>
          <DefaultButton
            className='px-4 w-fit-content me-3'
            variant='secondary'
            title={t('addConnection.cancel')}
            onClick={hideDetails}
          />
          {isAlreadyConnected ? (
            <>
              <DefaultButton
                className='px-4 w-fit-content clear-btn'
                type='submit'
                title={t('Clear Trade Logs')}
                disabled={!connection}
                onClick={handleSubmit}
              />
              <DefaultModal
                visible={showClearModal}
                onHide={() => setShowClearModal(false)}
                title={t('All Trade Journal data will be deleted')}
              >
                <div className='d-flex flex-row align-items-center justify-content-end gap-4'>
                  <DefaultButton
                    className='px-4 w-fit-content cancel-btn'
                    variant='secondary'
                    title={t('addConnection.cancel')}
                    onClick={() => setShowClearModal(false)}
                    disabled={isDeleting}
                  />
                  <DefaultButton
                    className='px-4 w-fit-content me-3 clear-btn disconnect-btn'
                    variant='secondary'
                    title={t('Disconnect')}
                    onClick={async () => {
                      if (connection?.id) {
                        await deleteBrokerageAccount({
                          brokerage: connection.id,
                        }).then(() => {
                          displaySuccessMessage(t('Successfully disconnected'))
                          setShowClearModal(false)
                          hideDetails()
                          refetch()
                        })
                      }
                    }}
                    loading={isDeleting}
                    disabled={isDeleting}
                  />
                </div>
              </DefaultModal>
            </>
          ) : (
            <DefaultButton
              className='px-4 w-fit-content'
              type='submit'
              title={t('addConnection.add')}
              disabled={!connection}
              onClick={handleSubmit}
            />
          )}
        </div>
      </div>
    </DefaultModal>
  )
}

interface ConnectionBoxProps {
  title: string
  img: string
  isSelected?: boolean
  isDisabled?: boolean
  onClick?: () => void
}

const ConnectionBox = ({
  title,
  img,
  isSelected,
  isDisabled,
  onClick,
}: ConnectionBoxProps) => {
  const [t] = useTranslation('tradeJournal')

  return (
    <div
      className={cls('connection-box w-100', {
        selected: isSelected,
        disabled: isDisabled,
      })}
      onClick={
        onClick ||
        (() => {
          return undefined
        })
      }
    >
      <div className='d-flex justify-content-between'>
        <img src={img} />
        {isSelected ? (
          <HiCheckCircle className='check' size={30} />
        ) : (
          isDisabled && (
            <div className='coming-soon'>
              <p>{t('addConnection.comingSoon')}</p>
            </div>
          )
        )}
      </div>
      <p className='title'>{title}</p>
    </div>
  )
}

export default AddBrokerageConnection
