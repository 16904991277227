import { CircularProgressbar } from 'react-circular-progressbar'
import BearMarketLogo from 'src/assets/images/order-flow/bear-market-3x.png'
import BullMarketLogo from 'src/assets/images/order-flow/bull-market-3x.png'
import NeutralMarketLogo from 'src/assets/images/order-flow/neutral-market.svg'
import { SectionTitle } from 'src/core/components'

import './styles.scss'

import { FlowSentiment } from '../../types'

type ChartCardProps = {
  sentiment: FlowSentiment
  noBackground?: boolean
}

export const ChartCard = ({ sentiment, noBackground }: ChartCardProps) => {
  const { title, type, value, content } = sentiment

  return (
    <div
      className={`chart-card d-flex ${type || ''} ${
        noBackground ? 'no-background' : ''
      }`}
    >
      <div className='chart-card_graph'>
        {value !== undefined ? (
          <CircularProgressbar
            value={value}
            strokeWidth={8}
            className='chart-card_graph-bar'
          />
        ) : (
          <img
            className='img-consolidation-split'
            src={
              sentiment.sentiment === 'neutral'
                ? NeutralMarketLogo
                : sentiment.sentiment === 'bearish'
                ? BearMarketLogo
                : BullMarketLogo
            }
            alt='Consolidation Block'
            height={64}
            width={64}
          />
        )}
      </div>
      <div className={`chart-card_body ${noBackground ? 'no-background' : ''}`}>
        <SectionTitle size='md' title={title} />
        <p className='chart-card_description'>{content}</p>
      </div>
    </div>
  )
}
