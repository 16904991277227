import { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CgProfile } from 'react-icons/cg'
// import { MdEmail } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { DefaultButton, DefaultInput, DefaultText } from 'src/core/components'
// import { AuthService } from 'src/core/services'
import Users from 'src/core/services/firebase/firestore/Users'
import { setUserDetails } from 'src/core/store/features/user/userDetails'
import { RootState } from 'src/core/store/Store'
import { displaySuccessMessage } from 'src/core/utils/alerts/ToastAlerts'
// import { validateEmail } from 'src/core/utils/validation/ValidationHelper'
import { GeneralFormFields } from 'src/modules/settings/types'

import './styles.scss'

const General = () => {
  const [t] = useTranslation()
  const userDetails = useSelector((state: RootState) => state.userDetails.data)
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState(userDetails?.firstName)
  const [lastName, setLastName] = useState(userDetails?.lastName)
  // const [email, setEmail] = useState(userDetails?.emailAddress)
  const [validationMsgs, setValidationMsgs] = useState<GeneralFormFields>({})
  const dispatch = useDispatch()

  const updateProfile = async (event: any) => {
    event.preventDefault()
    setLoading(true)
    const hasError = await validate()
    if (hasError) {
      setLoading(false)
      return
    }
    if (firstName && lastName && userDetails?.userID) {
      Users.update({
        userID: userDetails.userID,
        firstName,
        lastName,
        // emailAddress: email,
      })
        .then(() => {
          displaySuccessMessage(t('common:messages.changesSaved'))
          // AuthService.updateEmail(email)
          Users.getUserDetails().then((userData) => {
            if (userData) {
              dispatch(setUserDetails(userData))
            }
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const validate = async () => {
    const validationObj: GeneralFormFields = {}
    // if (!validateEmail(email ?? '')) {
    //   validationObj.email = t('common:validation.email')
    // } else {
    //   if (email) {
    //     const isExist = await AuthService.checkEmailExist(email)
    //     const user = AuthService.getCurrentUser()
    //     const isSameEmail = email === user?.email
    //     if (isExist && !isSameEmail) validationObj.email = t('common:validation.emailExist')
    //   }
    // }
    if (!firstName || !firstName?.trim().length) {
      validationObj.firstName = t('common:validation.required')
    }
    if (!lastName || !lastName?.trim().length) {
      validationObj.lastName = t('common:validation.required')
    }
    setValidationMsgs(validationObj)
    if (Object.keys(validationObj).length) {
      return true
    }
    return false
  }

  return (
    <div className='general-settings-page mb-4'>
      <Form onSubmit={updateProfile}>
        <Row className='mb-3'>
          <Col md={12} className='mb-3'>
            <DefaultText title={t('settings:nameHint')} />
          </Col>
          <Col md={6}>
            <Form.Group>
              <DefaultInput
                value={firstName}
                onChange={(event: any) => setFirstName(event.target.value)}
                icon={<CgProfile />}
                errorMsg={validationMsgs.firstName}
                type='text'
                placeholder={t('common:fields.firstName')}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <DefaultInput
                value={lastName}
                onChange={(event: any) => setLastName(event.target.value)}
                icon={<CgProfile />}
                errorMsg={validationMsgs.lastName}
                type='text'
                placeholder={t('common:fields.lastName')}
              />
            </Form.Group>
          </Col>
        </Row>
        {/* <Form.Group className='mb-3'>
          <DefaultInput
            value={email}
            onChange={(event: any) => setEmail(event.target.value)}
            icon={<MdEmail />}
            errorMsg={validationMsgs.email}
            type='text'
            placeholder={t('common:fields.email')}
          />
        </Form.Group> */}
        <Form.Group className='mb-3'>
          <DefaultButton
            loading={loading}
            type='submit'
            title={t('common:actions.save')}
          />
        </Form.Group>
      </Form>
    </div>
  )
}

export default General
