import { useTranslation } from 'react-i18next'
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import CustomAreaChart from 'src/core/components/charts/AreaChart/AreaChart'
import { JournalSummary } from 'src/core/store/features/tradeJournal/types'

import './styles.scss'

type TradeJournalNetChartProps = {
  data?: JournalSummary['journals']
}
const TradeJournalNetChart = ({ data }: TradeJournalNetChartProps) => {
  const navigate = useNavigate()
  const [t] = useTranslation('tradeJournal')
  const reversed = [...(data ?? [])].reverse()

  return (
    <div className='trade-journal-net-chart'>
      <div className='d-flex justify-content-between mb-4'>
        <p className='net-chart-title'>
          {t('dailyNetComulative')} <RiArrowDownSLine />
        </p>
        <p className='learn-more' onClick={() => navigate('/daily-stats')}>
          {t('More')} <RiArrowRightSLine size={16} />
        </p>
      </div>
      <CustomAreaChart
        data={reversed.map((journal) => ({ value: journal.grossPL }))}
        height={175 + 40}
        xAxisTickFormatter={(index) => {
          const journal = reversed[index]
          return journal ? format(new Date(journal.date), 'dd/MM') : ''
        }}
      />
    </div>
  )
}

export default TradeJournalNetChart
