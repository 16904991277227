import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { CustomTooltip } from './components/CustomTooltip'

interface CustomAreaChartProps {
  height: number
  data: any[]
  dataKey?: string
  type?: 'default' | 'success' | 'danger'
  hideXAxis?: boolean
  xAxisTickFormatter?: (index: number) => string
  isWatchListChart?: boolean
}

const CustomAreaChart = ({
  height,
  data,
  dataKey = 'value',
  type = 'default',
  hideXAxis = false,
  xAxisTickFormatter,
  isWatchListChart = false,
}: CustomAreaChartProps) => {
  const chartHeight = height - (hideXAxis ? 10 : 40)

  const strokeColor =
    type === 'default' ? '#FFB017' : type === 'success' ? '#33CA83' : '#F45B6C'

  return (
    <ResponsiveContainer width='100%' height={height} className='flex-shrink-1'>
      <AreaChart data={data}>
        <defs>
          <linearGradient
            id={'primaryGradient' + type}
            x1='215.291'
            y1='-210.377'
            x2='215.463'
            y2='156'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={strokeColor} />
            <stop offset='1' stopColor={strokeColor} stopOpacity='0.1' />
          </linearGradient>
        </defs>
        {!hideXAxis && (
          <XAxis
            axisLine={false}
            tickLine={false}
            tickMargin={15}
            tick={{
              stroke: '#F4F4F4',
              strokeWidth: 0.6,
              fontSize: '12px',
            }}
            tickFormatter={
              xAxisTickFormatter ??
              ((index) => {
                return `${String(index + 3).padStart(2, '0')}/09`
              })
            }
          />
        )}
        <YAxis
          dataKey={dataKey}
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          tick={{
            stroke: '#F4F4F4',
            fontSize: '12px',
            strokeWidth: 0.6,
          }}
          domain={
            isWatchListChart
              ? [
                  Math.min(...data.map((item) => item[dataKey])),
                  Math.max(...data.map((item) => item[dataKey])),
                ]
              : undefined
          }
          tickFormatter={(value) => {
            return `$${value}`
          }}
        />
        <CartesianGrid
          strokeDasharray='5 10'
          vertical={false}
          opacity={0.2}
          fill={isWatchListChart ? '#101010' : 'black'}
          horizontalPoints={[
            chartHeight / 4 + 5,
            chartHeight / 2 + 5,
            chartHeight * 0.75 + 5,
          ]}
        />
        <Area
          type='monotone'
          dataKey={dataKey}
          stroke={strokeColor}
          strokeWidth={2}
          fill={`url(#primaryGradient${type})`}
        />
        {isWatchListChart && window.location.pathname === '/watch-list' && (
          <Tooltip
            cursor
            content={(props) => (
              <CustomTooltip active={props.active} payload={props.payload} />
            )}
            wrapperStyle={{
              height: '30px',
            }}
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default CustomAreaChart
