import { Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFilters,
  setOpenFilterModal,
} from 'src/core/store/features/filters/filters'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

import FilterChips from './components/filterChips/FilterChips'
import { SentimentsCards } from './components/sentimentsCards/SentimentsCards'
import { FiltersModal, OrdersTable } from './components'
import { TFilterCondition } from './types'

const OrderFlowPage = () => {
  const dispatch = useDispatch()
  const filters = useSelector((state: RootState) => state.filters.filters)

  const openFilterModal = useSelector(
    (state: RootState) => state.filters.openModal,
  )

  const onHideModal = () => {
    dispatch(setOpenFilterModal({ openModal: false }))
  }

  const onApplyFilter = (
    filterConditions: {
      [condition: string]: TFilterCondition
    } | null,
  ) => {
    dispatch(setOpenFilterModal({ openModal: false }))
    dispatch(setFilters({ filters: filterConditions }))
  }

  const mainPage = document.getElementsByClassName('main-page')

  const pageHeight = mainPage
    ? mainPage[0]?.getBoundingClientRect().height ?? 650
    : 650

  return (
    <Container id='orders-flow-page' className='orderflow-page pt-4 pb-2'>
      <Row>
        <SentimentsCards />
      </Row>
      <Row className='px-3 mt-3'>
        <OrdersTable filters={filters} height={pageHeight * 0.65} />
        {filters && (
          <div className='mt-3'>
            <FilterChips filters={filters} />
          </div>
        )}
      </Row>
      <FiltersModal
        show={openFilterModal}
        onHide={onHideModal}
        onApplyFilter={onApplyFilter}
        initialConditions={filters}
      />
    </Container>
  )
}

export default OrderFlowPage
