import { useSelector } from 'react-redux'
import { Loading } from 'src/core/components'
import CustomCalendar from 'src/core/components/calendars/CustomCalendar/CustomCalendar'
import { useGetTradeJournalQuery } from 'src/core/store/features/tradeJournal/api'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

import DailyStatsCard from './components/dailyStatsCard/DailyStatsCard'

const DailyStats = () => {
  const tradeJournalQuery = useGetTradeJournalQuery()
  const tradeJournalPath =
    useSelector(
      (state: RootState) =>
        state.tradeJournal.selectedConnection?.tradeJournalPath,
    ) ?? ''
  return (
    <div className='daily-stats'>
      {tradeJournalQuery.isLoading ? (
        <Loading />
      ) : (
        <div className='d-flex'>
          <div className='flex-grow-1 me-3'>
            {tradeJournalQuery.data?.map((tradeJournal, index) => (
              <DailyStatsCard
                tradeJournalPath={tradeJournalPath}
                key={tradeJournal.id}
                className={
                  index + 1 !== tradeJournalQuery.data?.length ? 'mb-3' : ''
                }
                tradeJournal={tradeJournal}
              />
            ))}
          </div>
          <div>
            <CustomCalendar isShortVersion data={tradeJournalQuery.data} />
          </div>
        </div>
      )}
    </div>
  )
}

export default DailyStats
