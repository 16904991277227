import { useSelector } from 'react-redux'
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import TJCloudFunctions, {
  Brokerage,
} from 'src/core/services/firebase/cloudFunctions/tradeJournal/TJCloudFunctions'
import { TradeJorunalsService } from 'src/core/services/firebase/firestore/tradeJournals'
import { displayErrorMessage } from 'src/core/utils/alerts/ToastAlerts'

import { RootState } from '../../Store'
import { JournalSummary, TradeJournal, TradeTag, TradingDays } from './types'

export const tradeJournalApi = createApi({
  reducerPath: 'tradejournalApi',
  tagTypes: ['TradeJournal'],
  baseQuery: fakeBaseQuery<{ message: string }>(),
  endpoints: (builder) => ({
    getTradingDays: builder.query<TradingDays, { accountID: string }>({
      queryFn: (params) => TJCloudFunctions.getTradingDays(params),
    }),
    getBrokeragesConnected: builder.query<Brokerage[], void>({
      queryFn: () => TJCloudFunctions.brokeragesConnected(),
    }),
    deleteBrokerageAccount: builder.mutation<
      Promise<any>,
      { brokerage: string }
    >({
      queryFn: (params) => TJCloudFunctions.deleteBrokerageAccount(params),
    }),
    getTradeJournal: builder.query<TradeJournal[], string>({
      queryFn: (tradeJournalPath: string) =>
        TradeJorunalsService.getTradeJournals(tradeJournalPath),
      providesTags: ['TradeJournal'],
    }),
    getTradeJournalSummary: builder.query<
      JournalSummary,
      { startDate: string; endDate: string; brokerage: string }
    >({
      queryFn: (params) => TJCloudFunctions.getTradeJournalSummary(params),
    }),
    getTradeTags: builder.query<
      { strategy: TradeTag[]; mistake: TradeTag[] },
      void
    >({
      queryFn: () => TradeJorunalsService.getTradeTags(),
    }),

    modifyTradeJournalTransaction: builder.mutation<
      TradeJournal['transactions'][0],
      {
        tradeJournalPath: string
        transactionID: string
        comment: string
        profitTarget: string
        stopLoss: string
        tradeTagIDs: string[]
      }
    >({
      queryFn: (params) =>
        TJCloudFunctions.modifyTradeJournalTransaction(params),
      invalidatesTags: ['TradeJournal'],
    }),

    uploadCSV: builder.mutation<string, { file: File; brokerage: string }>({
      queryFn: (params) => TJCloudFunctions.uploadCSV(params),
    }),
  }),
})

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action) && action.type.includes('Api')) {
    displayErrorMessage(action.payload.message)
  }

  return next(action)
}

export const {
  useUploadCSVMutation,
  useGetBrokeragesConnectedQuery,
  useGetTradeTagsQuery,
  useModifyTradeJournalTransactionMutation,
  useDeleteBrokerageAccountMutation,
} = tradeJournalApi

export const useGetTradeJournalSummaryQuery = () => {
  const params = useSelector(
    (state: RootState) => ({
      startDate: state.tradeJournal.datePicker.startDate,
      endDate: state.tradeJournal.datePicker.endDate,
      brokerage: state.tradeJournal.selectedConnection?.brokerage || '',
    }),
    (prev, next) =>
      prev.startDate === next.startDate &&
      prev.endDate === next.endDate &&
      prev.brokerage === next.brokerage,
  )
  return tradeJournalApi.endpoints.getTradeJournalSummary.useQuery(params, {
    skip: !params.brokerage || !params.startDate || !params.endDate,
  })
}

export const useGetTradingDaysQuery = () => {
  const accountID =
    useSelector(
      (state: RootState) => state.tradeJournal.selectedConnection?.id,
    ) ?? ''

  return tradeJournalApi.useGetTradingDaysQuery(
    { accountID },
    {
      skip: !accountID,
    },
  )
}

export const useGetTradeJournalQuery = () => {
  const tradeJournalPath =
    useSelector(
      (state: RootState) =>
        state.tradeJournal.selectedConnection?.tradeJournalPath,
    ) ?? ''
  return tradeJournalApi.useGetTradeJournalQuery(tradeJournalPath, {
    skip: !tradeJournalPath,
  })
}
