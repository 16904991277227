import './styles.scss'

interface Props {
  title: string
  component?: any
  color?: 'default' | 'secondary' | 'danger'
  size?: 'sm' | 'md' | 'lg'
}

const DefaultText = ({
  title,
  component,
  color = 'default',
  size = 'sm',
}: Props) => {
  return (
    <p className={`default-text-component ${color} ${size}`}>
      {title}
      {component}
    </p>
  )
}

export default DefaultText
