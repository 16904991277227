// @ts-nocheck
import { useCallback, useEffect, useState } from 'react'
import { toArray } from 'react-emoji-render'
import { useTranslation } from 'react-i18next'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

import NotificationDetailsModal from '../notificationDetailsModal/NotificationDetailsModal'

const parseEmojis = (value: string) => {
  const emojisArray = toArray(value)

  // toArray outputs React elements for emojis and strings for other
  const newValue = emojisArray.reduce((previous, current) => {
    if (typeof current === 'string') {
      return previous + current
    }
    return previous + current.props.children
  }, '')

  return newValue
}

export const NotificationPopup = () => {
  const [t] = useTranslation('notifications')
  const [isVisible, setIsVisible] = useState(true)
  const [notificationDetails, setNotificationDetails] =
    useState<Notification | null>(null)
  const lastNotification = useSelector(
    (state: RootState) => state.notifications.newNotification,
  )

  useEffect(() => {
    if (lastNotification) {
      setIsVisible(true)
      setTimeout(() => {
        setIsVisible(false)
      }, 7000)
    }
  }, [lastNotification])

  const getClippedTitle = useCallback(
    (title: string) => {
      if (lastNotification?.notificationType !== 'tsTechnicalAnalysis') {
        title =
          title.split('__**Market Notes**__').length > 1
            ? title.split('__**Market Notes**__')[1]
            : title
      }
      const numberOfCharacters = lastNotification?.image ? 125 : 150
      if (title.length > numberOfCharacters) {
        return title.slice(0, numberOfCharacters) + '...'
      } else {
        return title
      }
    },
    [lastNotification],
  )

  const displayNotificationDetails = () => {
    setNotificationDetails(lastNotification)
  }

  const hideNotificationDetails = () => {
    setNotificationDetails(null)
  }

  if (!lastNotification) {
    return <></>
  }

  return (
    <>
      <div
        onClick={displayNotificationDetails}
        className={`notifications-popup d-flex ${
          isVisible ? 'slide-in' : 'slide-in-reverse'
        } ${lastNotification.image ? '' : 'no-image'}`}
      >
        {lastNotification.image && (
          <div className='me-3'>
            <img
              className='notification-image'
              src={lastNotification.image}
              alt=''
            />
          </div>
        )}
        <div>
          <div>
            <div className='float-end d-flex align-items-center'>
              <HiOutlineArrowRight className='arrow-right' />
            </div>
            <div className='new-container'>
              <p className='new'>{t('newNotifications')}</p>
            </div>
          </div>
          <p className='title'>{t('technicalAnalysis')}</p>
          <p className='desc'>
            {parseEmojis(getClippedTitle(lastNotification?.data))}
          </p>
        </div>
      </div>
      {notificationDetails && (
        <NotificationDetailsModal
          data={notificationDetails}
          hideNotificationDetails={hideNotificationDetails}
          type={lastNotification.notificationType}
        />
      )}
    </>
  )
}
