import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BsStarFill } from 'react-icons/bs'
import { FaMoneyCheckAlt } from 'react-icons/fa'
import { MdAccessTime, MdAccountBalance, MdPayment } from 'react-icons/md'
import { useSelector } from 'react-redux'
import {
  DefaultButton,
  DefaultText,
  LoadingSpinner,
  PageTitle,
  SectionTitle,
} from 'src/core/components'
import { PaymentCloudFunctions } from 'src/core/services/firebase/cloudFunctions'
import { RootState } from 'src/core/store/Store'
import {
  displayErrorMessage,
  displaySuccessMessage,
} from 'src/core/utils/alerts/ToastAlerts'

import './styles.scss'

const UpgradePackage = () => {
  const [t] = useTranslation()
  const [actionLoading, setActionLoading] = useState(false)
  const [proration, setProration] = useState<any>(null)
  const fullUserDetails = useSelector((state: RootState) => state.userDetails)
  const activePackage = fullUserDetails.activeSubscribtion
  const userDetails = fullUserDetails.data
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  const switchedPackagePriceId =
    activePackage?.items[0].price.id === process.env.REACT_APP_MONTHLY_PRICE_ID
      ? process.env.REACT_APP_YEARLY_PRICE_ID
      : process.env.REACT_APP_MONTHLY_PRICE_ID
  const isYearlyPackage =
    switchedPackagePriceId === process.env.REACT_APP_YEARLY_PRICE_ID

  useEffect(() => {
    const getProration = async () => {
      if (activePackage && switchedPackagePriceId && userDetails?.stripeId) {
        const result = await PaymentCloudFunctions.calculatePackageProrations(
          activePackage.id,
          switchedPackagePriceId,
          userDetails.stripeId,
        )
        if (result?.status) {
          setProration(result.data)
        }
      }
    }
    getProration()
  }, [])

  const switchPackage = async (event: any) => {
    event.preventDefault()
    setActionLoading(true)
    if (activePackage && switchedPackagePriceId) {
      const result = await PaymentCloudFunctions.switchPackage(
        activePackage.id,
        switchedPackagePriceId,
      )
      if (result?.status) {
        displaySuccessMessage(t('common:messages.changesSaved'))
      } else {
        setActionLoading(false)
        displayErrorMessage(t('common:messages.error'))
      }
    }
  }

  if (!activePackage || !proration) {
    return <LoadingSpinner />
  }

  return (
    <Card className='upgrade-package-card-component'>
      {isYearlyPackage && (
        <div className='popular'>
          <SectionTitle
            icon={<BsStarFill />}
            title={t('auth:packages.yearly.mostPopular')}
          />
        </div>
      )}
      <Card.Header className='mt-2 package-header'>
        {isYearlyPackage && (
          <>
            <SectionTitle title={t('auth:packages.yearly.name')} />
            <div className='d-inline-flex'>
              <span className='old-price'>
                <s>
                  <PageTitle title={t('auth:packages.monthly.price')} />
                </s>
              </span>
              <span className='offer-price'>
                <PageTitle title={t('auth:packages.yearly.price')} />
              </span>
            </div>
            <DefaultText title={t('auth:per') + ' ' + t('auth:period.year')} />
            <span className='offer-text'>
              <DefaultText title={t('auth:packages.yearly.offer')} />
            </span>
          </>
        )}
        {!isYearlyPackage && (
          <>
            <SectionTitle title={t('auth:packages.monthly.name')} />
            <PageTitle title={t('auth:packages.monthly.price')} />
            <DefaultText title={t('auth:per') + ' ' + t('auth:period.month')} />
          </>
        )}
      </Card.Header>
      <Card.Body>
        <div className='proration-details'>
          <div className='d-inline-flex align-items-center info'>
            <MdPayment className='me-2 icon' />
            <DefaultText
              title={t('settings:currentPackageSwitching')}
              color='secondary'
            />
          </div>
          <br />
          <div className='d-inline-flex align-items-center info'>
            <MdAccessTime className='me-2 icon' />
            <DefaultText
              title={t('settings:packagePeriod', {
                start: new Date(proration.period_start * 1000).toDateString(),
              })}
              color='secondary'
            />
          </div>
          <br />
          <div className='d-inline-flex align-items-center info'>
            <FaMoneyCheckAlt className='me-2 icon' />
            <DefaultText
              title={t('settings:totalAmountAfterProration', {
                amount: formatter.format(
                  Number(proration.total_excluding_tax) / 100,
                ),
              })}
              color='secondary'
            />
          </div>
          <br />
          {proration.starting_balance !== 0 && (
            <>
              <div className='d-inline-flex align-items-center info'>
                <MdAccountBalance className='me-2 icon' />
                <DefaultText
                  title={t('settings:currentBalanceAmount', {
                    amount: formatter.format(
                      Number(Math.abs(proration.starting_balance) / 100),
                    ),
                  })}
                  color='secondary'
                />
              </div>
              <br />
            </>
          )}
          {proration.ending_balance !== 0 && (
            <>
              <div className='d-inline-flex align-items-center info'>
                <MdAccountBalance className='me-2 icon' />
                <DefaultText
                  title={t('settings:addedToBalance', {
                    amount: formatter.format(
                      Number(Math.abs(proration.ending_balance) / 100),
                    ),
                  })}
                  color='secondary'
                />
              </div>
              <br />
            </>
          )}
        </div>
      </Card.Body>
      <Card.Footer className='pt-3'>
        <DefaultButton
          loading={actionLoading}
          onClick={switchPackage}
          title={
            isYearlyPackage
              ? t('settings:upgradePackage')
              : t('settings:switchPackage')
          }
        />
      </Card.Footer>
    </Card>
  )
}

export default UpgradePackage
