import { Col, Container, Row } from 'react-bootstrap'

import './styles.scss'

interface Props {
  message: string
}

const WarningBanner = ({ message }: Props) => {
  return (
    <Container className='warning-banner-component'>
      <Row>
        <Col md={12}>
          <p>{message}</p>
        </Col>
      </Row>
    </Container>
  )
}

export default WarningBanner
