import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import MainBG from 'src/assets/images/landing/MainBG.png'
import NewsDemo from 'src/assets/images/landing/NewsDemo.png'
import NotificationsDemo from 'src/assets/images/landing/NotificationsDemo.png'
import OrderFlowDemo from 'src/assets/images/landing/OrderFlowDemo.png'
import PhoneMainBG from 'src/assets/images/landing/PhoneMainBG.png'
import TradeJournalDemo from 'src/assets/images/landing/TradeJournalDemo.png'

import './styles.scss'

import { Footer } from './components/Footer/Footer'
import { HomeMainCard } from './components/HomeMainCard/HomeMainCard'
import { JournalingCard } from './components/JournalingCard/JournalingCard'
import { LandingTopBar } from './components/LandingTopBar/LandingTopBar'
import { PlatformCard } from './components/PlatformCard/PlatformCard'
import { RetailTraderCard } from './components/RetailTraderCard/RetailTraderCard'
import { StepCard } from './components/StepCard/StepCard'

const LandingPage = () => {
  const [t] = useTranslation('landing')
  const isTablet = useMediaQuery({ query: `(max-width: 991px)` })
  const isMobile = useMediaQuery({ query: `(max-width: 599px)` })

  return (
    <Container fluid className='landing-page'>
      <div className='w-100 banner'>
        <p className='banner-title'>
          Claim your Early Access 50% discount by clicking{' '}
          <Link to='/register' className='link'>
            here
          </Link>
        </p>
      </div>
      <div
        className='main-black-bg'
        style={{
          backgroundImage: `-webkit-linear-gradient(-85deg, #181411 60% 88%,  #fff  60%)`,
        }}
      >
        <div
          className='main-bg'
          style={{
            backgroundImage: `url('${isMobile ? PhoneMainBG : MainBG}')`,
            backgroundSize: isMobile ? 'contain' : 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: isTablet ? '75% 0%' : 'unset',
          }}
        >
          <LandingTopBar />
          <HomeMainCard />
        </div>
      </div>
      <RetailTraderCard />
      <StepCard
        isBackground
        row1={{
          step: t('one'),
          title: t('intelligentTracking'),
          desc: t('intelligentTrackingDesc'),
          img: OrderFlowDemo,
        }}
        row2={{
          step: t('two'),
          title: t('comprehensiveTitle'),
          desc: t('comprehensiveTitleDesc'),
          img: TradeJournalDemo,
        }}
      />
      <StepCard
        row1={{
          step: t('three'),
          title: t('gradeNewsFeed'),
          desc: t('gradeNewsFeedDesc'),
          img: NewsDemo,
        }}
        row2={{
          step: t('four'),
          title: t('stockAnalysis'),
          desc: t('stockAnalysisDesc'),
          img: NotificationsDemo,
        }}
      />
      <JournalingCard />
      <PlatformCard />
      <Footer />
    </Container>
  )
}

export default LandingPage
