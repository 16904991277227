import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import './styles.scss'

export interface StepRowProps {
  isSecondCard?: boolean
  step: string
  title: string
  desc: string
  img: string
}

export const StepRow = ({
  isSecondCard,
  step,
  title,
  desc,
  img,
}: StepRowProps) => {
  const [t] = useTranslation('landing')
  const isTablet = useMediaQuery({ query: `(max-width: 991px)` })
  const isPhone = useMediaQuery({ query: `(max-width: 599px)` })
  const titleSpaces = title.split('&nbsp;')
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${
        isSecondCard ? 'second-card flex-row-reverse' : ''
      }`}
    >
      <div className='w-100'>
        {isTablet && (
          <div className='w-100 d-flex align-items-center justify-content-center'>
            <img
              className={`${isPhone ? 'w-100' : 'w-75'} m-5 mt-0`}
              src={img}
              alt=''
            />
          </div>
        )}
        <p className='step-number'>{step}</p>
        {titleSpaces.length > 0 ? (
          <p className='step-title'>
            {titleSpaces[0]}&nbsp;{titleSpaces[1]}
          </p>
        ) : (
          <p className='step-title'>{title}</p>
        )}
        <p className='step-desc'>{desc}</p>
        <Link to='/register'>
          <Button className='early-access-button' variant='primary'>
            {t('getStarted')}
            <HiOutlineArrowRight className='arrow-right ms-2' />
          </Button>
        </Link>
      </div>
      {!isTablet && (
        <div className='w-100 p-3'>
          <img className='w-100' src={img} alt='' />
        </div>
      )}
    </div>
  )
}
