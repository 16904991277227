// @ts-nocheck
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage'
import { FireStoreDB, reportError } from 'src/core/services'

import AuthService from '../auth/AuthService'

export interface UserData {
  firstName?: string
  lastName?: string
  emailAddress?: string
  modifiedOn?: string
  createDate?: string
  isActive?: boolean
  isOnline?: boolean
  userID: string
  opraID?: string
  photoURL?: string
  userRole?: 'CUSTOMER' | 'SUPERADMIN'
  stripeId?: string
  stripeLink?: string
  settings?: Settings
  dailyGoal?: number
}

interface Settings {
  canViewDashboard?: boolean
  hasFreeTrial?: boolean
  opraFormURL?: string
  isOpraAgreementSigned?: boolean
}

interface CheckoutSession {
  price: string
}

export interface PackageSubscribtion {
  id: string
  cancel: string
  cancel_at_period_end: boolean
  canceled_at: string
  created: string
  current_period_end: Timestamp
  current_period_start: string
  ended_at: string
  items: Array<{
    plan: {
      amount: string
      currency: string
      interval: string
    }
    price: {
      id: string
      currency: string
      product: {
        name: string
        description: string
      }
    }
  }>
  metadata: any
  quantity: number
  status:
    | 'active'
    | 'canceled'
    | 'unpaid'
    | 'ended'
    | 'incomplete'
    | 'incomplete_expired'
    | 'past_due'
    | 'trialing'
    | 'all'
}

class Users {
  COLLECTION_NAME = 'users'
  CHECKOUT_COLLECTION_NAME = 'checkout_sessions'
  SUBSCRIPTION_COLLECTION_NAME = 'subscriptions'

  async add(data: UserData) {
    try {
      if (!data.userID) return false
      await setDoc(doc(FireStoreDB, this.COLLECTION_NAME, data.userID), {
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.emailAddress,
        userID: data.userID,
        opraID: data.userID,
        userRole: 'CUSTOMER',
        photoURL: '',
        isActive: true,
        isOnline: true,
        modifiedOn: serverTimestamp(),
        createDate: serverTimestamp(),
        stripeId: '',
        stripeLink: '',
        settings: {
          canViewDashboard: true,
          hasFreeTrial: false,
        },
      })

      // ADD DEFAULT WATCHLIST TO WATCHLIST
      getDocs(query(collection(FireStoreDB, 'defaultWatchList')))
        .then(({ docs }) => {
          for (const singleEntry of docs) {
            setDoc(
              doc(
                FireStoreDB,
                `users/${data.userID}/watchlist/${
                  singleEntry.data().ticker.id
                }`,
              ),
              singleEntry.data(),
            )
              .then(() => {
                console.log('created')
              })
              .catch((err) => {
                console.log(err)
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })

      return true
    } catch (e) {
      reportError(e)
      return false
    }
  }

  async update(data: UserData) {
    try {
      if (!data.userID) return false
      await setDoc(
        doc(FireStoreDB, this.COLLECTION_NAME, data.userID),
        { ...data, modifiedOn: serverTimestamp() },
        { merge: true },
      )
      return true
    } catch (e) {
      reportError(e)
      return false
    }
  }

  uploadOPRAAgreement = async (file: string) => {
    const user = await this.getUserDetails()
    if (!user) {
      return null
    }
    const storage = getStorage()
    const storageRef = ref(storage, `OPRA_Agreements/${user.userID}.png`)

    await uploadString(storageRef, file, 'data_url')
    const url = await getDownloadURL(storageRef)
    // TODO: Don't fetch current settings object to update user
    await this.update({
      userID: user.userID,
      settings: {
        ...user.settings,
        opraFormURL: url,
        isOpraAgreementSigned: true,
      },
    })
    return url
  }

  async addCheckoutSession(session: CheckoutSession) {
    try {
      const user = AuthService.getCurrentUser()
      if (!user) return null
      const docRef = await addDoc(
        collection(
          FireStoreDB,
          this.COLLECTION_NAME + '/' + user.uid,
          this.CHECKOUT_COLLECTION_NAME,
        ),
        {
          price: session.price,
          // eslint-disable-next-line camelcase
          success_url: window.location.origin + '/dashboard',
          // eslint-disable-next-line camelcase
          cancel_url: window.location.origin,
          // eslint-disable-next-line camelcase
          allow_promotion_codes: true,
        },
      )
      return docRef
    } catch (e) {
      reportError(e)
      return null
    }
  }

  async checkSubscription(): Promise<boolean> {
    try {
      const user = AuthService.getCurrentUser()
      const collectionPath =
        this.COLLECTION_NAME +
        '/' +
        user?.uid +
        '/' +
        this.SUBSCRIPTION_COLLECTION_NAME
      const q = query(
        collection(FireStoreDB, collectionPath),
        where('status', 'in', ['active', 'trialing']),
      )
      const querySnapshot = await getDocs(q)
      return !querySnapshot.empty
    } catch (e) {
      return false
    }
  }

  async getActiveSubscribtion(): Promise<PackageSubscribtion | null> {
    try {
      const user = AuthService.getCurrentUser()
      const collectionPath =
        this.COLLECTION_NAME +
        '/' +
        user?.uid +
        '/' +
        this.SUBSCRIPTION_COLLECTION_NAME
      const q = query(
        collection(FireStoreDB, collectionPath),
        where('status', 'in', ['active', 'trialing']),
      )
      const querySnapshot = await getDocs(q)
      const subscribtions: Array<PackageSubscribtion> = []
      querySnapshot.forEach((subscribtion) => {
        const activePkg = subscribtion.data() as PackageSubscribtion
        activePkg.id = subscribtion.id
        // TODO move format currency|money|time to utils
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        })
        activePkg.items[0].plan.amount = formatter.format(
          Number(activePkg.items[0].plan.amount) / 100,
        )
        subscribtions.push(activePkg)
      })
      return subscribtions[0]
    } catch (e) {
      return null
    }
  }

  async getUserDetails(): Promise<UserData | null | undefined> {
    try {
      const user = AuthService.getCurrentUser()
      if (!user) {
        return null
      }
      const docRef = doc(FireStoreDB, this.COLLECTION_NAME, user.uid)
      const docSnap = await getDoc(docRef)
      return docSnap.data() as UserData
    } catch (e) {
      reportError(e)
      return null
    }
  }

  getSubscribtionDocRef(subscribtionId: string) {
    const user = AuthService.getCurrentUser()
    const docPath =
      this.COLLECTION_NAME +
      '/' +
      user?.uid +
      '/' +
      this.SUBSCRIPTION_COLLECTION_NAME +
      '/' +
      subscribtionId
    const docRef = doc(FireStoreDB, docPath)
    return docRef
  }
}

export default new Users()
