import { useTranslation } from 'react-i18next'
import { DefaultButton } from 'src/core/components'
import DefaultModal from 'src/core/components/modals/DefaultModal/DefaultModal'
import { useGetTradeTagsQuery } from 'src/core/store/features/tradeJournal/api'

import './styles.scss'

import { TradeTag } from '../tradeTag/TradeTag'

type TagsSelectionModalProps = {
  visible: boolean
  onHide: () => void
  selectedTags: string[]
  onTagClick: (tagID: string) => void
}
export function TagsSelectionModal({
  visible,
  onHide,
  onTagClick,
  selectedTags,
}: TagsSelectionModalProps) {
  const [t] = useTranslation('dailyStats')
  const tags = useGetTradeTagsQuery()

  return (
    <DefaultModal visible={visible} onHide={onHide}>
      <div className='d-flex flex-column'>
        <h2>{t('tagsModal.strategy')}</h2>
        <div className='d-flex flex-wrap trade-tags-container'>
          {tags.data?.strategy.map((tag) => (
            <TradeTag
              tag={tag}
              key={tag.tagID}
              active={selectedTags.includes(tag.tagID)}
              onClick={() => onTagClick(tag.tagID)}
            />
          ))}
        </div>
        <h2>{t('tagsModal.mistake')}</h2>
        <div className='d-flex flex-wrap trade-tags-container'>
          {tags.data?.mistake.map((tag) => (
            <TradeTag
              tag={tag}
              key={tag.tagID}
              active={selectedTags.includes(tag.tagID)}
              onClick={() => onTagClick(tag.tagID)}
            />
          ))}
        </div>
      </div>
      <DefaultButton onClick={onHide} title={t('tagsModal.close')} />
    </DefaultModal>
  )
}
