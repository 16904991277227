import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flatten, uniqBy } from 'lodash'
import { WatchListService } from 'src/core/services'
import {
  WATCH_LIST_PER_SCROLL,
  WATCH_LIST_SUBSCRIPTION_COUNT,
} from 'src/modules/watchList/types'

import {
  FetchWatchlist,
  setChartData,
  SetSubscribedWatchListData,
  SetSubscribedWatchlistError,
  WatchlistState,
} from './types'

const initialState: WatchlistState = {
  error: null,
  data: [],
  chartData: [],
  loading: true,
  isFetchedAll: false,
  isFetching: false,
}

export const fetchWatchlist = createAsyncThunk(
  'watchlist/fetchWatchlist',
  async ({ lastDocumentId }: FetchWatchlist) => {
    const response = await WatchListService.getWatchList(lastDocumentId)
    return { data: response }
  },
)

export const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {
    setChartDataAction: (state, action: PayloadAction<setChartData>) => {
      const { chartData } = action.payload
      state.chartData = chartData
    },
    setSubscribedWatchlistData: (
      state,
      action: PayloadAction<SetSubscribedWatchListData>,
    ) => {
      const { data } = action.payload

      if (data.length < WATCH_LIST_SUBSCRIPTION_COUNT) {
        state.isFetchedAll = true
      }

      state.data = uniqBy(flatten([data, state.data]), 'id')

      state.loading = false
    },
    setSubscribedWatchlistError: (
      state,
      action: PayloadAction<SetSubscribedWatchlistError>,
    ) => {
      const { error } = action.payload
      state.error = error
      state.loading = false
    },
    resetWatchlist: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWatchlist.pending, (state) => {
      state.isFetching = true
    })
    builder.addCase(fetchWatchlist.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data.length < WATCH_LIST_PER_SCROLL) {
        state.isFetchedAll = true
      }
      const newWatchlist = uniqBy(flatten([state.data, data]), 'id') // append the NEWLY ADDED data to the start of the table
      state.data = newWatchlist
      state.loading = false
      state.isFetching = false
    })
    builder.addCase(fetchWatchlist.rejected, (state, action) => {
      state.error = action.error.message
      state.loading = false
      state.isFetching = false
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  setSubscribedWatchlistData,
  setSubscribedWatchlistError,
  resetWatchlist,
  setChartDataAction,
} = watchlistSlice.actions

export default watchlistSlice.reducer
