import { ReactNode } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import './styles.scss'

import { DefaultButton, DefaultText } from '../..'

interface Props {
  message: string
  actionTitle?: string
  onClick?: () => void
  icon?: ReactNode
  loading?: boolean
}

const InfoBanner = ({
  message,
  actionTitle,
  onClick,
  icon,
  loading = false,
}: Props) => {
  return (
    <Container className='info-banner-component'>
      <Row>
        <Col md={actionTitle ? 9 : 12}>
          <div className='d-inline-flex message'>
            <span className='icon'>{icon}</span>
            <DefaultText title={message} />
          </div>
        </Col>
        {actionTitle && (
          <Col md={3} className='p-0'>
            <DefaultButton
              isSmall
              loading={loading}
              title={actionTitle}
              onClick={onClick}
            />
          </Col>
        )}
      </Row>
    </Container>
  )
}

export default InfoBanner
