import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MdEmail, MdLock } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AuthHeader,
  DefaultButton,
  DefaultInput,
  DefaultText,
  PageTitle,
  SecretInput,
  TextLink,
} from 'src/core/components'
import { AuthService } from 'src/core/services'
import { displayErrorMessage } from 'src/core/utils/alerts/ToastAlerts'

import './styles.scss'

const LoginPage = () => {
  const [t] = useTranslation('common')
  const [tAuth] = useTranslation('auth')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const from = location.state?.from?.pathname || '/dashboard'

  const login = (event: any) => {
    event.preventDefault()
    setLoading(true)
    AuthService.signInEmail(email, password)
      .then(() => {
        navigate(from, { replace: true })
      })
      .catch(() => {
        displayErrorMessage(t('validation.auth'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className='login-container'>
      <AuthHeader />
      <Form className='login-page' onSubmit={login}>
        <PageTitle title={tAuth('login')} />
        <Form.Group className='mb-3 mt-5'>
          <DefaultInput
            icon={<MdEmail />}
            onChange={(event: any) => setEmail(event.target.value)}
            type='email'
            placeholder={t('fields.email')}
          />
        </Form.Group>
        <Form.Group className='mb-3'>
          <SecretInput
            icon={<MdLock />}
            onChange={(event: any) => setPassword(event.target.value)}
            placeholder={t('fields.password')}
          />
        </Form.Group>
        <div className='mb-4 forget-text'>
          <TextLink title={tAuth('forgetPassword')} link='/forget-password' />
        </div>
        <Form.Group className='mb-4'>
          <DefaultButton
            loading={loading}
            type='submit'
            title={tAuth('login')}
          />
        </Form.Group>
        <div className='mb-4 d-inline-flex'>
          <div className='pe-2'>
            <DefaultText title={tAuth('newUser')} />
          </div>
          <TextLink title={tAuth('signUp')} link='/register' />
        </div>
      </Form>
    </div>
  )
}

export default LoginPage
