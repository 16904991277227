import { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import cls from 'classnames'
import { find } from 'lodash'

import './styles.scss'

export interface TradingDay {
  id: string
  date: string
  grossPL: number
  losers: number
  netPL: number
  tradeCount: number
  winRate: number
  winners: number
}

interface CustomCalendarProps {
  className?: string
  isShortVersion?: boolean
  data?: TradingDay[]
}

const CustomCalendar = ({
  className,
  isShortVersion,
  data,
  ...props
}: CustomCalendarProps) => {
  const [activeStartDate, setActiveStartDate] = useState<Date>(new Date())

  useEffect(() => {
    if (data?.length) {
      setActiveStartDate(new Date(data[0].date))
    }
  }, [data])
  return (
    <div className='custom-calendar h-100'>
      <Calendar
        selectRange
        view='month'
        maxDate={new Date()}
        minDate={new Date('2000-01-01')}
        activeStartDate={activeStartDate}
        onActiveStartDateChange={({ activeStartDate }) =>
          setActiveStartDate(activeStartDate as Date)
        }
        className={cls('calendar', className)}
        calendarType='ISO 8601'
        locale='en-US'
        formatShortWeekday={(locale, date) =>
          date.toLocaleString(locale, { weekday: 'short' })
        }
        tileDisabled={({ view }) => view === 'month'}
        formatDay={() => ''}
        tileContent={({ activeStartDate, date, view }) => {
          const month = new Date(activeStartDate).getMonth()
          const tileYear = new Date(date).getFullYear()
          const tileMonth = new Date(date).getMonth()
          const tileDay = new Date(date).getDate()
          const id = `${String(tileYear).padStart(2, '0')}-${String(
            tileMonth + 1,
          ).padStart(2, '0')}-${String(tileDay).padStart(2, '0')}`
          const tradingDay = find(data, { id })
          const currentDate = new Date()
          if (view === 'month') {
            return (
              <div
                className={cls(
                  'month',
                  {
                    'short-version': isShortVersion,
                  },
                  {
                    'not-current-month': tileMonth !== month,
                  },
                  {
                    success: tradingDay && tradingDay.grossPL > 0,
                  },
                  {
                    danger: tradingDay && tradingDay.grossPL < 0,
                  },
                  {
                    primary:
                      tileDay === currentDate.getDate() &&
                      tileMonth === currentDate.getMonth(),
                  },
                )}
              >
                <p
                  className={cls('mb-text day', {
                    primary:
                      tileDay === currentDate.getDate() &&
                      tileMonth === currentDate.getMonth(),
                  })}
                >
                  {new Date(date).getDate().toString()}
                </p>
                {!isShortVersion && (
                  <>
                    <p className='mb-text'>
                      {tradingDay ? (
                        `$${Math.round(tradingDay.grossPL * 100) / 100}`
                      ) : (
                        <>&nbsp;</>
                      )}
                    </p>
                    <p className='mb-0'>
                      {tradingDay ? (
                        `${tradingDay.tradeCount} ${
                          tradingDay.tradeCount > 1 ? ' Trades' : 'Trade'
                        }`
                      ) : (
                        <>&nbsp;</>
                      )}
                    </p>
                  </>
                )}
              </div>
            )
          }
          return <></>
        }}
        tileClassName={`tile ${isShortVersion ? 'short-version' : ''}`}
        showFixedNumberOfWeeks
        {...props}
      />
    </div>
  )
}

export default CustomCalendar
