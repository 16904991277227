import React from 'react'
import { HiX } from 'react-icons/hi'

import './styles.scss'

type ClearFilterButtonProps = {
  onClick: () => void
  children: React.ReactNode
}

const ClearFilterButton = ({ onClick, children }: ClearFilterButtonProps) => {
  return (
    <button className='clear-filter_btn' onClick={onClick}>
      <span>{children}</span>
      <HiX />
    </button>
  )
}

export default ClearFilterButton
