import { useDispatch, useSelector } from 'react-redux'
import SortDownArrow from 'src/assets/images/order-flow/sort-down-arrow.svg'
import SortUpArrow from 'src/assets/images/order-flow/sort-up-arrow.svg'
import { setSortBy } from 'src/core/store/features/filters/filters'
import { RootState } from 'src/core/store/Store'
import { TableColumn } from 'src/modules/orderFlow/types'

type OrdersTableHeaderProps = {
  columns: TableColumn[]
}

export const OrdersTableHeader = ({ columns }: OrdersTableHeaderProps) => {
  const dispatch = useDispatch()
  const sortByFilters = useSelector((state: RootState) => state.filters.sortBy)

  const onSort = (column: TableColumn) => {
    if (column.sortable) {
      const sortType = sortByFilters
        ? sortByFilters[column.key] === 'desc'
          ? column.key === 'time'
            ? 'desc'
            : 'asc'
          : 'desc'
        : 'desc'
      dispatch(setSortBy({ sortBy: { [column.key]: sortType } }))
    }
  }

  return (
    <thead id='orders-table-header'>
      <tr>
        {columns.map((_col) => (
          <th key={_col.title} onClick={() => onSort(_col)}>
            <div className='d-flex align-items-center justify-content-center'>
              {_col.title}
              <span className='sort'>
                {_col.sortable && (
                  <>
                    <img src={SortUpArrow} alt='Sort Arrow Up Icon' />
                    <img src={SortDownArrow} alt='Sort Arrow Down Icon' />
                  </>
                )}
              </span>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  )
}
