import { useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

import './styles.scss'

interface Props {
  icon?: any
  errorMsg?: string
  onChange?: any
  value?: string
  placeholder?: string
}

const SecretInput = ({
  icon,
  errorMsg,
  onChange,
  value,
  placeholder,
}: Props) => {
  const [inputType, setInputType] = useState('password')

  const switchSecretInputType = () => {
    const targetInputType = inputType === 'password' ? 'text' : 'password'
    setInputType(targetInputType)
  }

  return (
    <div className='secret-input-group-component-container'>
      <InputGroup className='secret-input-group-component'>
        {icon ? (
          <InputGroup.Text className={'icon ' + (errorMsg ? 'error' : '')}>
            {icon}
          </InputGroup.Text>
        ) : (
          <></>
        )}
        <Form.Control
          className={'secret-input ' + (errorMsg ? 'error' : '')}
          type={inputType}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
        />
        <Button
          className='switch-secret-input '
          onClick={switchSecretInputType}
        >
          {inputType === 'password' ? <FaEyeSlash /> : <FaEye />}
        </Button>
      </InputGroup>
      {errorMsg && <p className='error'>{errorMsg}</p>}
    </div>
  )
}

export default SecretInput
