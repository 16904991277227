import React, { useEffect, useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FixedList } from 'react-recycled-list'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingSpinner } from 'src/core/components'
import { fetchTopMovers } from 'src/core/store/features/topMovers/topMovers'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

import { TopMover } from '../../types'

type RenderRow = {
  firstRenderedRowIndex: number
  firstRenderedDataIndex: number
  lastRenderedRowIndex: number
  lastRenderedDataIndex: number
  lastRowIndex: number
}

type TopMoversTableProps = {
  height: number
  isSmall?: boolean
  type: 'gainers' | 'losers'
}

export const TopMoversTable = ({
  height,
  isSmall,
  type,
}: TopMoversTableProps) => {
  const [t] = useTranslation()

  const dispatch: any = useDispatch()
  const {
    data: topMovers,
    loading: topMoversLoading,
    isFetchedAll: isFetchedAllTopMovers,
    isFetching: isFetchingTopMovers,
    error,
  } = useSelector((state: RootState) => state.topMovers[type])

  const [formattedTopMovers, setFormattedTopMovers] = useState<
    (TopMover | undefined)[]
  >([])

  // const [topMoverDetails, setTopMoverDetails] = useState<TopMover | null>(null)

  useEffect(() => {
    if (!topMovers || topMovers.length === 0) {
      return
    }
    if (!isFetchedAllTopMovers) {
      return setFormattedTopMovers([...topMovers, undefined])
    } else {
      setFormattedTopMovers(topMovers)
    }
  }, [topMovers])

  /**
   * Method used to fetch more top movers while scrolling
   */
  const getTopMovers = async () => {
    const lastTopMovers =
      topMovers && topMovers.length > 0
        ? topMovers[topMovers.length - 1]
          ? topMovers[topMovers.length - 1]
          : topMovers[topMovers.length - 2]
        : undefined

    dispatch(fetchTopMovers({ type, lastDocumentId: lastTopMovers?.id }))
  }

  /**
   * Method that is triggered whenever the last row in the table is rendered
   */
  const onRenderedRowChange = (renderInfo: RenderRow) => {
    const { lastRenderedRowIndex, lastRowIndex } = renderInfo
    // If the last row is rendered (NOT visible yet!) and we are not already loading data, we fetch new data
    // If you want to fetch data when the last row is visible then use onVisibleRowChange
    if (
      !isFetchingTopMovers &&
      !topMoversLoading &&
      !isFetchedAllTopMovers &&
      lastRowIndex > 0 &&
      lastRenderedRowIndex === lastRowIndex
    ) {
      getTopMovers()
    }
  }

  // const displayTopMoverDetails = (details: TopMover) => {
  //   setTopMoverDetails(details)
  // }

  // const hideTopMoverDetails = () => {
  //   setTopMoverDetails(null)
  // }

  return topMoversLoading ? (
    <div
      className={
        isSmall
          ? 'p-3'
          : 'p-3 d-flex d-flex align-items-center justify-content-center'
      }
    >
      <LoadingSpinner />
    </div>
  ) : error ? (
    <Container>
      <Row className='mt-4'>
        <Col sm={12}>
          <Alert variant='danger'>{t('common:loadFailed')}</Alert>
        </Col>
      </Row>
    </Container>
  ) : (
    <>
      <FixedList
        height={height}
        rowComponent={(props) => {
          return (
            <TopMoverRow
              {...props}
              // displayTopMoverDetails={displayTopMoverDetails}
              isSmall={isSmall}
              type={type}
            />
          )
        }}
        data={formattedTopMovers}
        rowHeight={isSmall ? 80 : 100}
        onRenderedRowChange={onRenderedRowChange}
      />
      {/* {topMoverDetails && (
        <TopMoverDetailsModal
          data={topMoverDetails}
          hideTopMoverDetails={hideTopMoverDetails}
        />
      )} */}
    </>
  )
}

type TopMoverRowProps = {
  data: TopMover[]
  dataIndex: number
  top: number
  height: number
  // displayTopMoverDetails: Function
  isSmall?: boolean
  type: 'gainers' | 'losers'
}

const moneyFormatter = new Intl.NumberFormat('en', {
  notation: 'compact',
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'USD',
})

// eslint-disable-next-line
const TopMoverRow = React.memo((props: TopMoverRowProps) => {
  const [t] = useTranslation('watchlist')

  // the data here is the same data that is passed into the FixedList
  const {
    data,
    dataIndex,
    top,
    height,
    // displayTopMoverDetails,
    isSmall,
    type,
  } = props

  const topMover = data[dataIndex]

  let premium

  if (topMover !== undefined) {
    premium = moneyFormatter.format(topMover.premium)
  }

  // Important!, make sure you inline-style your component with the the provided top, height. Also make sure to set your container element to position absolute
  return (
    <div
      className={`${isSmall ? 'small' : ''} ${
        type === 'gainers' ? 'success' : 'danger'
      }`}
    >
      <div
        style={{ top, height, position: 'absolute' }}
        className={`react-recycled-row top-movers-row ${
          isSmall ? 'px-4' : ''
        } ${isSmall && dataIndex === 0 ? 'pt-2' : ''}`}
        // onClick={() => displayNewsDetails(news)}
      >
        {topMover === undefined ? (
          <div className='h-100 d-flex align-items-center justify-content-center'>
            <LoadingSpinner />
          </div>
        ) : (
          <Row className='m-0 p-0 h-100 d-flex align-items-center'>
            <Col className='m-0 p-0'>
              <p className='tag mb-2'>{t('symbol')}</p>
              <p className='title'>{topMover.id}</p>
            </Col>
            <Col className='m-0 p-0'>
              <p className='tag mb-2'>{t('orders')}</p>
              <p className='title'>{topMover.orders}</p>
            </Col>
            <Col className='m-0 p-0'>
              <p className='tag mb-2'>{t('premium')}</p>
              <p
                className={`title ${type === 'gainers' ? 'success' : 'danger'}`}
              >
                {premium}
              </p>
            </Col>
            <Col className='m-0 p-0 h-50'>
              <div
                className='graph'
                style={{
                  width: `${Math.max(100 - (topMover.sortOrder + 1) * 10, 0)}%`,
                }}
              >
                <div className='line' />
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
})
