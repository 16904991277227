import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { find } from 'lodash'
import { LoadingSpinner } from 'src/core/components'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

import { NewsTable } from '../news/components/newsTable/NewsTable'
import { WatchListAbout } from './components/watchListAbout/WatchListAbout'
import { WatchListChart } from './components/watchListChart/WatchListChart'
import { WatchListTable } from './components/watchListTable/WatchListTable'
import { WatchList } from './types'

const WatchListPage = () => {
  const [t] = useTranslation('')

  // get watchList from store
  const {
    data: watchList,
    loading: watchListLoading,
    error,
  } = useSelector((state: RootState) => state.watchlist)

  const [watchListDetails, setWatchListDetails] = useState<string | null>(
    watchList[0]?.id || null,
  )

  useEffect(() => {
    if (!watchListDetails) {
      setWatchListDetails(watchList[0]?.id || null)
    }
  }, [watchList])

  const currentWatchList = useMemo(
    () => find(watchList, { id: watchListDetails }) as WatchList,
    [watchListDetails],
  )

  // get height of watchList page
  const ref = React.useRef<HTMLDivElement>(null)
  const [height, setHeight] = React.useState(0)
  const [isHeightLoading, setIsHeightLoading] = React.useState(true)
  React.useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.getBoundingClientRect().height)
    }
    setIsHeightLoading(false)
  }, [ref.current?.getBoundingClientRect().height])

  return watchListLoading || isHeightLoading ? (
    <div className='p-3 d-flex d-flex align-items-center justify-content-center'>
      <LoadingSpinner />
    </div>
  ) : error ? (
    <Container>
      <Row className='mt-4'>
        <Col sm={12}>
          <Alert variant='danger'>{t('common:loadFailed')}</Alert>
        </Col>
      </Row>
    </Container>
  ) : (
    <div id='watchlist-page' className='container-dev h-100'>
      <Container ref={ref} fluid className='h-100 p-0'>
        <Row className='main-row h-100 px-5 m-0'>
          {/* Left Column */}
          <Col className='h-100 p-0'>
            {/* Top Left */}
            <Row
              className='first-row-first-column'
              style={{
                height: height > 0 ? height / 2 + 100 : 500,
              }}
            >
              <WatchListChart
                height={height > 0 ? height / 2 - 32 : 0}
                watchListDetails={watchListDetails}
              />
            </Row>
            {/* Bottom Left */}
            <Row
              className='w-100 second-row-first-column'
              style={{
                height: height > 0 ? height / 2 + 220 : 500,
              }}
            >
              <WatchListAbout data={currentWatchList} />
            </Row>
          </Col>
          {/* Right Column */}
          <Col className='h-100 p-0'>
            {/* Top Right */}
            <Row
              className='w-100 first-row-second-column'
              style={{
                height: height > 0 ? height / 2 + 100 : 500,
              }}
            >
              <WatchListTable
                height={height > 0 ? height / 2 + 80 : 480}
                setWatchListDetailsPage={setWatchListDetails}
              />
            </Row>
            {/* Bottom Right */}
            <Row
              className='second-row-second-column'
              style={{
                height: height > 0 ? height / 2 + 220 : 500,
              }}
            >
              <NewsTable
                height={height > 0 ? height / 2 + 190 : 470}
                isWatchListAndNewsPage
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WatchListPage
