import React, { useContext } from 'react'
import { Modal, ProgressBar, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import cls from 'classnames'
import { SectionTitle } from 'src/core/components'
import Users from 'src/core/services/firebase/firestore/Users'
import { setUserDetails } from 'src/core/store/features/user/userDetails'
import { RootState } from 'src/core/store/Store'
import { displaySuccessMessage } from 'src/core/utils/alerts/ToastAlerts'
import { ThemeContext } from 'src/core/utils/theme/ThemeContext'

import './styles.scss'

import { AddIcon, ArrowIcon, DarkCloseIcon, DollarIcon } from './icons'

interface AddGoalButtonProps {
  netPL: string | null | undefined
}

export const AddGoalButton = ({ netPL }: AddGoalButtonProps) => {
  const netPLNumber = netPL ? parseFloat(netPL) : 0
  const { theme } = useContext(ThemeContext)
  const [t] = useTranslation('tradeJournal')
  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState(false)

  const [visible, setVisible] = React.useState(false)
  const [typedGoal, setTypedGoal] = React.useState(0)

  const userData = useSelector((state: RootState) => state.userDetails.data)
  const savedGoal = userData?.dailyGoal || 0

  const setSavedGoal = async (goal: number) => {
    if (goal !== null && goal !== undefined && userData?.userID) {
      setLoading(true)
      Users.update({
        dailyGoal: goal,
        userID: userData.userID,
      })
        .then(() => {
          displaySuccessMessage(t('successMessage'))
          Users.getUserDetails().then((userData) => {
            if (userData) {
              dispatch(setUserDetails(userData))
            }
          })
        })
        .finally(() => {
          setVisible(false)
          setLoading(false)
        })
    }
  }

  const handleClose = () => {
    setVisible(false)
    setTypedGoal(savedGoal)
  }

  return (
    <div className='add-goal'>
      {savedGoal === 0 ? (
        <button className='add-goal-btn' onClick={() => setVisible(true)}>
          <span className='add-goal-btn-text'>{t('addDailyGoal')}</span>
          <AddIcon />
        </button>
      ) : (
        <button className='add-goal-btn' onClick={() => setVisible(true)}>
          <span className='amount'>${netPLNumber}</span>
          <ProgressBar now={netPLNumber} max={savedGoal} className='progress' />
          <span className='amount'>${savedGoal}</span>
          <ArrowIcon />
        </button>
      )}

      <Modal
        className={cls(`theme-${theme}`)}
        show={visible}
        size='lg'
        onHide={() => {
          handleClose()
        }}
        centered
        style={{
          maxWidth: '425px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
        backdrop='static'
        backdropClassName='modal-wrapper'
      >
        <Modal.Body>
          <Row className='header'>
            <div className='d-flex align-items-start justify-content-between'>
              <div>
                <SectionTitle
                  size='lg'
                  title={t('dailyGoal')}
                  variant='default'
                />
              </div>
              <DarkCloseIcon
                onClick={() => {
                  handleClose()
                }}
              />
            </div>
          </Row>
          <hr className='default' />
          <div className='content'>
            <span className='add-goal-modal-body-text'>
              {t('addDailyGoalDescriptionModal')}
            </span>
            <div className='add-goal-input-container'>
              <DollarIcon />
              <input
                defaultValue={savedGoal}
                onChange={(e) => setTypedGoal(Number(e.target.value))}
                className='add-goal-input'
                type='number'
                min={0}
                pattern='[0-9]*'
                inputMode='numeric'
                onKeyPress={(e) => {
                  if (e.key === '-') {
                    e.preventDefault()
                  }
                }}
              />
            </div>
            <button
              onClick={() => {
                setSavedGoal(typedGoal)
              }}
              className='add-save-goal-btn'
              disabled={loading || typedGoal === savedGoal}
            >
              {loading ? (
                <Spinner
                  animation='border'
                  variant='dark'
                  size='sm'
                  role='status'
                />
              ) : savedGoal === 0 ? (
                t('addGoal')
              ) : (
                t('saveGoal')
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
