import React from 'react'

import './styles.scss'

import { OverviewAreaChart } from './components/overviewAreaChart/OverviewAreaChart'
import { OverviewBarChart } from './components/overviewBarChart/OverviewBarChart'
import { StatsCard } from './components/statsCard/StatsCard'

export const Overview = () => {
  return (
    <>
      {/* Stats Card */}
      <StatsCard />
      {/* Charts */}
      <div className='charts-container'>
        {/* Area Chart */}
        <OverviewAreaChart />
        {/* Bar Chart */}
        <OverviewBarChart />
      </div>
    </>
  )
}
