import { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CgProfile } from 'react-icons/cg'
import { MdEmail, MdLock } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { onSnapshot } from 'firebase/firestore'
import {
  CheckBox,
  DefaultButton,
  DefaultInput,
  DefaultText,
  PageTitle,
  SecretInput,
  TextLink,
} from 'src/core/components'
import { AuthService, PaymentService } from 'src/core/services'
import Users from 'src/core/services/firebase/firestore/Users'
import { setIsSubscribing } from 'src/core/store/features/registration/registration'
import { displayErrorMessage } from 'src/core/utils/alerts/ToastAlerts'
import {
  validateEmail,
  validatePassword,
} from 'src/core/utils/validation/ValidationHelper'

import './styles.scss'

interface ValidationMsgs {
  email?: string
  firstName?: string
  lastName?: string
  password?: string
  confirmPassword?: string
  isAcceptedTerms?: string
}

interface InformationProps {
  selectedPlan: string | undefined
}

const Information = ({ selectedPlan }: InformationProps) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState<string | null>(null)
  const [lastName, setLastName] = useState<string | null>(null)
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState<string | null>(null)
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null)
  const [isAcceptedTerms, setIsAcceptedTerms] = useState<Boolean | null>(false)
  const [validationMsgs, setValidationMsgs] = useState<ValidationMsgs>({})

  const register = async (event: any) => {
    event.preventDefault()
    setLoading(true)
    const hasError = await validate()
    if (hasError) {
      setLoading(false)
      return
    }
    if (email && password && firstName && lastName) {
      dispatch(setIsSubscribing(true))
      AuthService.register(email, password, firstName, lastName).then(() => {
        subscribePackage()
      })
    }
  }

  const subscribePackage = () => {
    if (!selectedPlan) {
      return
    }
    Users.addCheckoutSession({ price: selectedPlan })
      .then((docRef) => {
        if (docRef) {
          onSnapshot(docRef, async (snap: any) => {
            const { error, sessionId } = snap.data()
            if (error) {
              displayErrorMessage(t('common:messages.error'))
              setLoading(false)
            }
            if (sessionId) {
              PaymentService.module.redirectToCheckout({ sessionId })
              dispatch(setIsSubscribing(false))
              setLoading(false)
            }
          })
        }
      })
      .catch(() => {
        dispatch(setIsSubscribing(false))
        displayErrorMessage(t('common:messages.error'))
        setLoading(false)
      })
  }

  const validate = async () => {
    const validationObj: ValidationMsgs = {}
    if (!validateEmail(email)) {
      validationObj.email = t('common:validation.email')
    } else {
      if (email) {
        const isExist = await AuthService.checkEmailExist(email)
        if (isExist) validationObj.email = t('common:validation.emailExist')
      }
    }
    if (!firstName || !firstName?.trim().length) {
      validationObj.firstName = t('common:validation.required')
    }
    if (!lastName || !lastName?.trim().length) {
      validationObj.lastName = t('common:validation.required')
    }
    if (!password || !password?.trim().length) {
      validationObj.password = t('common:validation.required')
    } else {
      const validationPasswordMsg = validatePassword(password)
      if (validationPasswordMsg) validationObj.password = validationPasswordMsg
    }
    if (!confirmPassword || !confirmPassword?.trim().length) {
      validationObj.confirmPassword = t('common:validation.required')
    } else {
      if (password !== confirmPassword) {
        validationObj.confirmPassword = t('common:validation.notMatched')
      }
    }
    if (!isAcceptedTerms) {
      validationObj.isAcceptedTerms = t('common:validation.acceptTerms')
    }
    setValidationMsgs(validationObj)
    if (Object.keys(validationObj).length) {
      return true
    }
    return false
  }

  return (
    <div className='information-page'>
      <PageTitle title={t('auth:completeInfo')} />
      <Form className='register-page mb-4' onSubmit={register}>
        <Row className='name-row mb-3 mt-5'>
          <Col md={6}>
            <Form.Group>
              <DefaultInput
                onChange={(event: any) => setFirstName(event.target.value)}
                icon={<CgProfile />}
                errorMsg={validationMsgs.firstName}
                type='text'
                placeholder={t('common:fields.firstName')}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <DefaultInput
                onChange={(event: any) => setLastName(event.target.value)}
                icon={<CgProfile />}
                errorMsg={validationMsgs.lastName}
                type='text'
                placeholder={t('common:fields.lastName')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className='mb-3'>
          <DefaultInput
            onChange={(event: any) => setEmail(event.target.value)}
            icon={<MdEmail />}
            errorMsg={validationMsgs.email}
            type='text'
            placeholder={t('common:fields.email')}
          />
        </Form.Group>
        <Form.Group className='mb-3'>
          <SecretInput
            onChange={(event: any) => setPassword(event.target.value)}
            icon={<MdLock />}
            errorMsg={validationMsgs.password}
            placeholder={t('common:fields.password')}
          />
        </Form.Group>
        <Form.Group className='mb-4'>
          <SecretInput
            onChange={(event: any) => setConfirmPassword(event.target.value)}
            icon={<MdLock />}
            errorMsg={validationMsgs.confirmPassword}
            placeholder={t('common:fields.confirmPassword')}
          />
        </Form.Group>
        <Form.Group className='mb-4'>
          <CheckBox
            name='terms'
            checked={!!isAcceptedTerms}
            onChange={(e: any) => setIsAcceptedTerms(e.target.checked)}
            errorMsg={validationMsgs.isAcceptedTerms}
          >
            I agree to the{' '}
            <Link className='link' to='/terms-of-service' target='_blank'>
              <span className='primary-link'>
                {t('landing:termsAndConditions')}
              </span>{' '}
            </Link>
            and{' '}
            <Link className='link' to='/privacy-policy' target='_blank'>
              <span className='primary-link'>{t('landing:privacyPolicy')}</span>
            </Link>{' '}
            of Boom Stocks
          </CheckBox>
        </Form.Group>
        <Form.Group className='mb-4'>
          <DefaultButton
            loading={loading}
            type='submit'
            title={t('auth:register')}
          />
        </Form.Group>
        <div className='mb-4 d-inline-flex'>
          <div className='pe-2'>
            <DefaultText title={t('auth:alreadyRegister')} />
          </div>
          <TextLink title={t('auth:login')} link='/login' />
        </div>
      </Form>
    </div>
  )
}

export default Information
