import { ComponentProps } from 'react'
import classNames from 'classnames'
import { TradeTag as ITradeTag } from 'src/core/store/features/tradeJournal/types'

import './styles.scss'

export function TradeTag({
  tag,
  ...props
}: ComponentProps<'button'> & { tag: ITradeTag; active: boolean }) {
  return (
    <button
      className={classNames(
        'px-3 py-1 trade-tag',
        tag.type === 'Mistake' ? 'mistake' : 'strategy',
        props.active && 'active',
      )}
      {...props}
    >
      {tag.name}
    </button>
  )
}
