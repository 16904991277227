export const TermsOfService = () => {
  return (
    <>
      <h1>Disclaimer</h1>
      <h2>
        The U.S. Securities and Commission (SEC) requires the following legal
        notices:
      </h2>
      <p>
        All material presented on Boom Stocks is not to be regarded as
        investment advice, but for general information purposes only. Trading
        does involve risk, so caution must always be utilized. We cannot
        quarantee profits or freedom from loss. You assume the entire cost and
        risk of any trading you choose to undertake. You are solely responsible
        for making your own investment decisions. BoomStocks, its
        representatives, its principals, its moderators, and its members, are
        NOT Exchange Commission or with any state securities regulatory
        authority. BoomStocks does not manage client assets in any way. All
        information/ideas provided on BoomStocks products and services are for
        educational purposes only. We recommend consulting with a registered
        investment advisor, broker-dealer, and/or financial advisor. BoomStocks
        requires that you fill out our electronic nonprofessional subscriber
        agreement form to receive data through our service. You will be
        redirected to this agreement after signing up. Should you sign up to our
        service you are fully aware that you will be required to fill our this
        agreement, and should you provide inaccurate information we may revoke
        or limit access any BoomStocks service. This form can be seen in section
        28 of our terms of service.
      </p>
    </>
  )
}
