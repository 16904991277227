import { collection, getDocs, query } from 'firebase/firestore'

import { FireStoreDB } from '../Firebase'

class Tickers {
  COLLECTION_NAME = 'tickers'

  /**
   * GET All Tickers
   */
  async getTickers() {
    const docRef = query(
      collection(FireStoreDB, this.COLLECTION_NAME),
      // orderBy('id', 'asc'),
    )
    const docs = await getDocs(docRef)
    return docs.docs.map((_doc: any) => ({ id: _doc.id, ..._doc.data() }))
  }
}

export default new Tickers()
