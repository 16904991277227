import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdCancel, MdPayment, MdSync } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { onSnapshot } from 'firebase/firestore'
import AppLogo from 'src/assets/images/logo.svg'
import {
  DefaultButton,
  DefaultText,
  InfoText,
  LoadingSpinner,
  SectionTitle,
} from 'src/core/components'
import { PaymentCloudFunctions } from 'src/core/services/firebase/cloudFunctions'
import Users, {
  PackageSubscribtion,
} from 'src/core/services/firebase/firestore/Users'
import { setActiveSubscribtion } from 'src/core/store/features/user/userDetails'
import {
  displayErrorMessage,
  displaySuccessMessage,
} from 'src/core/utils/alerts/ToastAlerts'

import './styles.scss'

import UpgradePackage from './components/upgradePackage/UpgradePackage'

const Billing = () => {
  const [t] = useTranslation()
  const [actionLoading, setActionLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [activePackage, setActivePackage] =
    useState<PackageSubscribtion | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    let unsubscribe: any = null
    setLoading(true)
    getActivePackage()
      .then((activePkg) => {
        setActivePackage(activePkg)
        if (activePkg) {
          const subscribeDocRef = Users.getSubscribtionDocRef(activePkg.id)
          unsubscribe = onSnapshot(subscribeDocRef, async (snap: any) => {
            if (snap.exists()) {
              setLoading(true)
              getActivePackage()
                .then((activePkg) => {
                  setActivePackage(activePkg)
                  if (activePkg) {
                    dispatch(setActiveSubscribtion(activePkg))
                  }
                })
                .finally(() => {
                  setLoading(false)
                })
            }
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    return () => {
      unsubscribe && unsubscribe()
    }
  }, [])

  const getActivePackage = async () => {
    const activeSubscribtion = await Users.getActiveSubscribtion()
    return activeSubscribtion
  }

  const cancelSubscription = async (event: any) => {
    event.preventDefault()
    setActionLoading(true)
    if (activePackage) {
      const result = await PaymentCloudFunctions.unsubscribePackage(
        activePackage.id,
      )
      if (result?.status) {
        displaySuccessMessage(t('common:messages.changesSaved'))
      } else {
        displayErrorMessage(t('common:messages.error'))
      }
    }
    setActionLoading(false)
  }

  const reActivate = async (event: any) => {
    event.preventDefault()
    setActionLoading(true)
    if (activePackage) {
      const result = await PaymentCloudFunctions.reActivatePackage(
        activePackage.id,
      )
      if (result?.status) {
        displaySuccessMessage(t('common:messages.changesSaved'))
      } else {
        displayErrorMessage(t('common:messages.error'))
      }
    }
    setActionLoading(false)
  }

  if (loading || !activePackage) {
    return <LoadingSpinner />
  }

  return (
    <div className='billing-settings-page mb-4'>
      {!activePackage.cancel_at_period_end && (
        <div className='mb-3'>
          <DefaultText title={t('settings:cancelHint')} />
        </div>
      )}
      <div className='package-card mb-3'>
        <div className='logo-container'>
          <img className='logo mb-3' src={AppLogo} alt='App Logo' />
        </div>
        <SectionTitle title={activePackage.items[0].price.product.name} />
        <InfoText title={activePackage.items[0].price.product.description} />
        <hr className='seperator' />
        <div className='d-inline-flex align-items-center info'>
          <MdPayment className='me-2' />
          <DefaultText
            title={
              activePackage.items[0].plan.amount +
              t('settings:forThe') +
              activePackage.items[0].plan.interval
            }
            color='secondary'
          />
        </div>
        <br />
        <div className='d-inline-flex align-items-center info'>
          {activePackage.cancel_at_period_end ? (
            <MdCancel className='me-2 danger' />
          ) : (
            <MdSync className='me-2' />
          )}
          <DefaultText
            title={
              t('settings:nextRenew') +
              ' ' +
              activePackage.current_period_end.toDate().toDateString()
            }
            color='secondary'
          />
          {activePackage.cancel_at_period_end && (
            <DefaultText
              title={'(' + t('settings:cancelled') + ')'}
              color='danger'
            />
          )}
        </div>
        <hr className='seperator' />
        {activePackage.cancel_at_period_end && (
          <div className='text-center'>
            <DefaultButton
              loading={actionLoading}
              onClick={reActivate}
              title={t('common:actions.reActivate')}
            />
            <div className='mt-2' />
            <InfoText title={t('settings:activateAgain')} />
          </div>
        )}
        {!activePackage.cancel_at_period_end && (
          <div className='text-center'>
            <DefaultButton
              variant='danger'
              loading={actionLoading}
              onClick={cancelSubscription}
              title={t('common:actions.unSubscribe')}
            />
            <div className='mt-2' />
            <InfoText
              title={t('settings:cancelValidTill', {
                date: activePackage.current_period_end.toDate().toDateString(),
              })}
            />
          </div>
        )}
      </div>
      <UpgradePackage />
    </div>
  )
}

export default Billing
