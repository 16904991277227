import { toast } from 'react-toastify'

export const displaySuccessMessage = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    theme: 'colored',
  })
}

export const displayErrorMessage = (message: string) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    theme: 'colored',
  })
}

export const displayInfoMessage = (message: string) => {
  toast.info(message, {
    position: toast.POSITION.TOP_RIGHT,
    theme: 'colored',
  })
}
