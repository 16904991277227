import { useState } from 'react'
import { Nav } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaSignOutAlt, FaUserCircle } from 'react-icons/fa'
import { GoVerified } from 'react-icons/go'
import { HiCreditCard } from 'react-icons/hi'
import { useMediaQuery } from 'react-responsive'
import { Link, useLocation } from 'react-router-dom'
import cls from 'classnames'
import AppLogo from 'src/assets/images/logo.svg'
import { TextLink } from 'src/core/components'
import LogoutModal from 'src/core/components/modals/LogoutModal/LogoutModal'
import { AuthService } from 'src/core/services'

import './styles.scss'

const AuthHeader = () => {
  const [showModal, setShowModal] = useState(false)
  const [t] = useTranslation()
  const loggedUser = AuthService.getCurrentUser()
  const isMobile = useMediaQuery({ query: `(max-width: 599px)` })
  const isTablet = useMediaQuery({ query: `(max-width: 991px)` })
  const location = useLocation()

  const showRegTabs = location.pathname === '/register'

  const flexGrowClass = isMobile ? 'flex-grow-1' : ''

  return (
    <div
      className={`auth-header-component ${
        showRegTabs ? 'no-bottom-padding' : ''
      }`}
    >
      <div className='d-flex justify-content-between'>
        <div className='left'>
          <Link to='/'>
            <img
              height={35}
              className='app-logo'
              src={AppLogo}
              alt='App Logo'
            />
          </Link>
        </div>
        {showRegTabs && (
          <div className={cls(flexGrowClass, { 'ms-3 me-2': isMobile })}>
            <div className='register-steps-tabs-container'>
              <Nav variant='pills' className='tabs'>
                <Nav.Item className={flexGrowClass}>
                  <Nav.Link eventKey='package'>
                    <GoVerified className='tab-icon' />{' '}
                    {!isMobile && (
                      <>
                        {isTablet
                          ? t('auth:subscriptionSelection.short')
                          : t('auth:subscriptionSelection.long')}
                      </>
                    )}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={flexGrowClass}>
                  <Nav.Link eventKey='information' disabled>
                    <FaUserCircle className='tab-icon' />{' '}
                    {!isMobile && (
                      <>
                        {isTablet
                          ? t('auth:accountInfo.short')
                          : t('auth:accountInfo.long')}
                      </>
                    )}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={flexGrowClass}>
                  <Nav.Link eventKey='payment' disabled>
                    <HiCreditCard className='tab-icon' />{' '}
                    {!isMobile && <>{t('auth:payment')}</>}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
        )}
        <div className='right d-inline-flex'>
          {/* <ThemeModeButton /> */}
          {loggedUser && (
            <div className='align-self-center'>
              {isMobile ? (
                <FaSignOutAlt className='logout-icon' size={24} />
              ) : (
                <TextLink
                  title={t('common:logout')}
                  onClick={setShowModal(true)}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <LogoutModal
        visible={showModal}
        onHide={() => {
          setShowModal(false)
        }}
      />
    </div>
  )
}

export default AuthHeader
