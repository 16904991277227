import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle } from 'react-icons/ai'
import { PageTitle } from 'src/core/components'

import './styles.scss'

const SuccessPage = () => {
  const [t] = useTranslation()

  return (
    <div className='success-page'>
      <AiFillCheckCircle className='icon' />
      <PageTitle title={t('common:success')} />
    </div>
  )
}

export default SuccessPage
