import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import AnalyticsLogo from 'src/assets/images/landing/analytics-icon.png'
import JournalingLogo from 'src/assets/images/landing/journaling-icon.png'
import ReportingLogo from 'src/assets/images/landing/reporting-icon.png'
import TabletDemo from 'src/assets/images/landing/TabletDemo.png'
import ThirdBG from 'src/assets/images/landing/ThirdBG.png'

import './styles.scss'

import { JournalingRow } from './JournalingRow/JournalingRow'

export const JournalingCard = () => {
  const isTablet = useMediaQuery({ query: `(max-width: 991px)` })
  const [t] = useTranslation('landing')

  return (
    <div
      className='journaling-card w-100'
      style={{
        backgroundImage: `url('${ThirdBG}')`,
        backgroundSize: 'cover',
        backgroundPosition: isTablet ? '70% 0%' : 'unset',
      }}
    >
      <p className='journaling-title'>{t('advancedJournaling')}</p>
      <div className='d-flex align-items-center justify-content-center'>
        {!isTablet && (
          <div className='w-100 me-5'>
            <img className='w-100' src={TabletDemo} alt='' />
          </div>
        )}
        <div className='w-100'>
          {isTablet && (
            <img className='w-100 journaling-img' src={TabletDemo} alt='' />
          )}
          <JournalingRow
            logo={JournalingLogo}
            title={t('journaling')}
            desc={t('journalingDesc')}
          />
          <JournalingRow
            logo={AnalyticsLogo}
            title={t('analytics')}
            desc={t('analyticsDesc')}
          />
          <JournalingRow
            noMargin
            logo={ReportingLogo}
            title={t('reporting')}
            desc={t('reportingDesc')}
          />
        </div>
      </div>
    </div>
  )
}
