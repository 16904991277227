import './styles.scss'

interface CustomTooltipProps {
  active: boolean | undefined
  payload: any[] | undefined
}

export const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className='px-2 py-0 custom-tooltip'>
        <p className='value-text'>{`Value: $${payload[0].value}`}</p>
        <p className='time-text'>{`Time: ${payload[0].payload.time}`}</p>
      </div>
    )
  }

  return null
}
