import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MdEmail } from 'react-icons/md'
import {
  AuthHeader,
  DefaultButton,
  DefaultInput,
  DefaultText,
  PageTitle,
  TextLink,
} from 'src/core/components'
import { AuthService } from 'src/core/services'
import {
  displayErrorMessage,
  displaySuccessMessage,
} from 'src/core/utils/alerts/ToastAlerts'

import './styles.scss'

const ForgetPassword = () => {
  const [t] = useTranslation('common')
  const [tAuth] = useTranslation('auth')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const forgetPassword = (event: any) => {
    event.preventDefault()
    setLoading(true)
    AuthService.sendForgetPasswordEmail(email)
      .then(() => {
        displaySuccessMessage(t('messages.emailSent'))
      })
      .catch(() => {
        displayErrorMessage(t('validation.email'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className='forget-password-container'>
      <AuthHeader />
      <Form className='forget-password-page' onSubmit={forgetPassword}>
        <div className='mb-5'>
          <PageTitle title={tAuth('passwordRecovery')} />
          <DefaultText title={tAuth('enterAppEmail')} />
        </div>
        <Form.Group className='mb-5'>
          <DefaultInput
            icon={<MdEmail />}
            onChange={(event: any) => setEmail(event.target.value)}
            type='email'
            placeholder={t('fields.email')}
          />
        </Form.Group>
        <Form.Group>
          <DefaultButton
            loading={loading}
            type='submit'
            title={tAuth('sendEmail')}
          />
        </Form.Group>
        <div className='mt-3'>
          <TextLink title={tAuth('backLogin')} link='/login' />
        </div>
      </Form>
    </div>
  )
}

export default ForgetPassword
