// @ts-nocheck
import { useCallback, useEffect, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import { BsFillCalendarEventFill } from 'react-icons/bs'
import cls from 'classnames'
import { format } from 'date-fns'
import { find } from 'lodash'
import { TradingDays } from 'src/core/store/features/tradeJournal/types'

import './styles.scss'

interface CustomDatePickerRangeProps {
  isShortVersion?: boolean
  value: Date[]
  tradingDays?: TradingDays
  onChange: (dates: Date[]) => void
}

const CustomDatePickerRange = ({
  isShortVersion,
  value,
  tradingDays,
  onChange,
}: CustomDatePickerRangeProps) => {
  const [activeStartDate, setActiveStartDate] = useState<Date>(new Date())

  const [calendarVisible, setCalendarVisible] = useState(false)
  const calendarRef = useRef()
  const inputRef = useRef()

  const onClickOutside = useCallback(
    (event: Event) => {
      if (!calendarRef.current) return
      const eventTarget = event.target as HTMLElement
      // do nothing on clicking ref's element or descendent elements
      if (
        calendarRef.current.contains(eventTarget) ||
        inputRef.current.contains(eventTarget)
      )
        return
      // hide menu otherwise
      setCalendarVisible(false)
    },
    [calendarVisible],
  )

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside)
    return () => document.removeEventListener('mousedown', onClickOutside)
  }, [onClickOutside])

  const isDateAvailable = value.length > 0
  useEffect(() => {
    if (tradingDays?.length) {
      setActiveStartDate(new Date(tradingDays[0].date))
    }
  }, [tradingDays])

  return (
    <div className='custom-date-picker-range'>
      <div
        ref={inputRef}
        className={cls(
          'date-picker-range-input d-flex justify-content-between',
          {
            'short-version': isShortVersion,
          },
        )}
        onClick={() => {
          setCalendarVisible((v) => !v)
        }}
      >
        <p
          className={cls('mb-0 input-text', {
            'placeholder-nodata': !isDateAvailable,
          })}
        >
          {isDateAvailable
            ? `${format(value[0], 'MM/dd/yy')} - ${format(
                value[1],
                'MM/dd/yy',
              )}`
            : 'Please select a date'}
        </p>
        <BsFillCalendarEventFill size={24} />
      </div>
      {calendarVisible && (
        <div className='popup-block'>
          <div
            className='w-fit-content'
            ref={calendarRef}
            style={{
              position: 'absolute',
              top: inputRef.current.getBoundingClientRect().bottom + 10,
              right:
                window.innerWidth -
                inputRef.current.getBoundingClientRect().right,
            }}
          >
            <Calendar
              ref={calendarRef}
              selectRange
              activeStartDate={activeStartDate}
              onActiveStartDateChange={({ activeStartDate }) => {
                setActiveStartDate(activeStartDate as Date)
              }}
              showDoubleView
              view='month'
              returnValue='range'
              maxDate={new Date()}
              minDate={new Date('2000-01-01')}
              onChange={onChange}
              className='calendar'
              formatShortWeekday={(locale, date) =>
                [0, 6].includes(date.getDay())
                  ? ''
                  : date.toLocaleDateString(locale, { weekday: 'short' })
              }
              tileDisabled={({ date }) =>
                date.getDay() === 0 || date.getDay() === 6
              }
              calendarType='ISO 8601'
              formatDay={() => ''}
              tileContent={({
                activeStartDate,

                date,
                view,
              }) => {
                const month = new Date(activeStartDate).getMonth()
                const tileMonth = new Date(date).getMonth()
                const tileDay = new Date(date).getDate()
                const tileYear = new Date(date).getFullYear()

                const id = `${String(tileYear).padStart(2, '0')}-${String(
                  tileMonth + 1,
                ).padStart(2, '0')}-${String(tileDay).padStart(2, '0')}`
                const tradingDay = find(tradingDays, { id })
                const currentDate = new Date()
                if (view === 'month') {
                  return (
                    <div
                      className={cls(
                        'month',
                        {
                          'short-version': isShortVersion,
                        },
                        {
                          'not-current-month': tileMonth !== month,
                        },
                        {
                          success: tradingDay && tradingDay.grossPL > 0,
                        },
                        {
                          danger: tradingDay && tradingDay.grossPL < 0,
                        },
                        {
                          primary:
                            tileDay === currentDate.getDate() &&
                            tileMonth === currentDate.getMonth(),
                        },
                      )}
                    >
                      <p
                        className={cls('mb-0 day', {
                          primary:
                            tileDay === currentDate.getDate() &&
                            tileMonth === currentDate.getMonth(),
                        })}
                      >
                        {new Date(date).getDate().toString()}
                      </p>
                    </div>
                  )
                }
                return <></>
              }}
              tileClassName='tile'
              showFixedNumberOfWeeks
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomDatePickerRange
