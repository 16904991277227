import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import './styles.scss'

import { TOption } from '../../types'

type ValueSelectorProps = {
  options: TOption[]
  placeholder?: string
  label?: string
  value?: TOption | null
  onChange?: (options: TOption | null) => void
}

export const ValueSelector = ({
  options,
  label,
  value = null,
  onChange,
  placeholder,
}: ValueSelectorProps) => {
  const [t] = useTranslation()
  const [selectedOption, setSelectedOption] = useState<TOption | null>(value)

  const onChangeHanlder = (selectedOption: any) => {
    setSelectedOption(selectedOption)
  }

  useEffect(() => {
    onChange?.(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    if (!value) setSelectedOption(null)
  }, [value])

  return (
    <div className='value-selector-component'>
      {label && <div>{label}</div>}
      <Select
        classNamePrefix='multi-select'
        placeholder={placeholder || t('orderFlow:filters.selectValue')}
        onChange={onChangeHanlder}
        value={selectedOption}
        options={options}
        openMenuOnFocus
        isClearable
      />
    </div>
  )
}
