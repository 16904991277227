import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore'
import {
  NOTIFICATIONS_PER_SCROLL,
  NOTIFICATIONS_SUBSCRIPTION_COUNT,
} from 'src/modules/notifications/types'

import { reportError } from '../../errorReporter'
import { FireStoreDB } from '../Firebase'

class NotificationsService {
  /**
   * subscribeToNotifications
   * Subscribe to Notifications sorted by the date
   */
  async subscribeToNotifications(
    type: string,
    onValue: (data: any, error?: any) => void,
  ) {
    try {
      const COLLECTION_NAME =
        type === 'tsTechnicalAnalysis' ? 'tsTechnicalAnalysis' : 'tsNewsLetters'
      const docRef = query(
        collection(FireStoreDB, COLLECTION_NAME),
        orderBy('date', 'desc'),
        limit(NOTIFICATIONS_SUBSCRIPTION_COUNT),
      )
      const unsubscribe = onSnapshot(
        docRef,
        (docSnap) => {
          onValue(docSnap.docs.map((_doc) => ({ id: _doc.id, ..._doc.data() })))
        },
        (error) => {
          console.log(error)
        },
      )
      return unsubscribe
    } catch (e) {
      reportError(e)
      onValue(null, e as Error)
      return null
    }
  }

  /**
   * GET Notifications with pagination (scrolling edition)
   * @param type
   * @param onValue
   * @param lastDocumentId
   */
  async getNotifications(type: string, lastDocumentId?: string) {
    const COLLECTION_NAME =
      type === 'tsTechnicalAnalysis' ? 'tsTechnicalAnalysis' : 'tsNewsLetters'
    let docRef = query(
      collection(FireStoreDB, COLLECTION_NAME),
      limit(NOTIFICATIONS_PER_SCROLL),
      orderBy('date', 'desc'),
    )
    if (lastDocumentId) {
      const lastDocRef = doc(FireStoreDB, COLLECTION_NAME, lastDocumentId)
      const lastDocumentSnap = await getDoc(lastDocRef)

      docRef = query(
        collection(FireStoreDB, COLLECTION_NAME),
        orderBy('date', 'desc'),
        startAfter(lastDocumentSnap),
        limit(NOTIFICATIONS_PER_SCROLL),
      )
    }

    const docs = await getDocs(docRef)
    return docs.docs.map((_doc: any) => ({ id: _doc.id, ..._doc.data() }))
  }
}

export default new NotificationsService()
