import cls from 'classnames'

import './styles.scss'

interface Props {
  title: string
  size?: 'sm' | 'md' | 'lg'
}

const InfoText = ({ title, size = 'sm' }: Props) => {
  return <p className={cls('info-text-component', size)}>{title}</p>
}

export default InfoText
