import React from 'react'
import { useSelector } from 'react-redux'
import CustomAreaChart from 'src/core/components/charts/AreaChart/AreaChart'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

export const OverviewAreaChart = () => {
  const dates = useSelector((state: RootState) => state.reports.datePicker)
  const startDateFormatted = new Date(dates.startDate).toLocaleDateString(
    'en-US',
    {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    },
  )
  const endDateFormatted = new Date(dates.endDate).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })

  const journals = useSelector(
    (state: RootState) => state.reports.states.journals,
  )

  const areaChartData = journals
    .map((journal) => {
      return {
        date: new Date(journal.date).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        value: journal.grossPL,
      }
    })
    .reverse()

  return (
    <div className='area-chart-container'>
      <div className='area-chart-title'>Daily Gross Cumulative P&L</div>
      <div className='area-chart-subtitle'>
        (From {startDateFormatted} to {endDateFormatted})
      </div>
      <div className='area-chart'>
        <CustomAreaChart
          data={areaChartData}
          height={300}
          xAxisTickFormatter={(index) => {
            const data = areaChartData[index].date
            return data as string
          }}
          type='success'
          isWatchListChart
        />
      </div>
    </div>
  )
}
