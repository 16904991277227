import './styles.scss'

interface Props {
  title: string
  icon?: any
  size?: 'lg' | 'md' | 'sm'
  variant?: 'default' | 'secondary'
}

const SectionTitle = ({
  title,
  icon,
  size = 'md',
  variant = 'default',
}: Props) => {
  return (
    <p className={`section-title-text-component ${size} ${variant}`}>
      {icon && <span className='icon'>{icon}</span>}
      {title}
    </p>
  )
}

export default SectionTitle
