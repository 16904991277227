import { useEffect, useState } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { DefaultText } from 'src/core/components'

import './styles.scss'

import { NotificationsList } from './components/notificationsList/notificationsList'

const NotificationsPage = () => {
  const [pageHeight, setPageHeight] = useState<number | undefined>(undefined)

  useEffect(() => {
    setTimeout(() => {
      setPageHeight(
        document.getElementById('notifications-page')?.getBoundingClientRect()
          .height,
      )
    }, 100)
  }, [])

  return (
    <div id='notifications-page' className='notifications-page h-100'>
      <Container className='d-flex justify-content-center align-items-center h-100'>
        <Row className='w-100 h-100 d-flex align-items-center h-100'>
          <Col
            xs
            md={{ offset: 2, span: 8 }}
            lg={{ offset: 3, span: 6 }}
            className='h-100'
          >
            <Tab.Container defaultActiveKey='tsTechnicalAnalysis'>
              <Nav variant='pills' className='notifications-tabs nav-fill my-4'>
                <Nav.Item>
                  <Nav.Link eventKey='tsTechnicalAnalysis'>
                    <DefaultText title='All' />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='tsNewsLetters'>
                    <DefaultText title='Daily News Letters' />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className='mt-3'>
                <Tab.Pane eventKey='tsTechnicalAnalysis'>
                  {pageHeight && (
                    <NotificationsList
                      type='tsTechnicalAnalysis'
                      height={pageHeight * 0.75}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey='tsNewsLetters'>
                  {pageHeight && (
                    <NotificationsList
                      type='tsNewsLetters'
                      height={pageHeight * 0.75}
                    />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NotificationsPage
