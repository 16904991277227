import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import InstagramLogo from 'src/assets/images/landing/instagram-icon.png'
import TwitterLogo from 'src/assets/images/landing/twitter-icon.png'
import AppLogo from 'src/assets/images/logo.svg'

import './styles.scss'

const InstagramLink = 'https://instagram.com/boomstocks.ai?igshid=Yzg5MTU1MDY'
const TwitterLink = ''

export const Footer = () => {
  const [t] = useTranslation('landing')
  const isMobile = useMediaQuery({ query: `(max-width: 599px)` })

  return (
    <div className='footer'>
      <div
        className={`footer-top d-flex align-items-center justify-content-between ${
          isMobile ? 'flex-column' : ''
        }`}
      >
        <div className={`d-flex align-items-center ${isMobile ? 'w-100' : ''}`}>
          <img className='app-logo' src={AppLogo} alt='App Logo' />
          <p className='nav-item me-4'>{t('titleName')}</p>
          <p className='nav-item me-4'>{t('help')}</p>
          <Link to='/privacy-policy' className='link' target='_blank'>
            <p className='nav-item'>{t('privacyPolicy')}</p>
          </Link>
        </div>
        <div
          className={`${
            isMobile ? 'd-flex justify-content-between w-100 mt-4' : ''
          }`}
        >
          <Link to='/register'>
            <Button className='early-access' variant='primary'>
              {t('getStarted')}
            </Button>
          </Link>
          <Link to='/login'>
            <Button className='login-button' variant='primary'>
              {t('login')}
            </Button>
          </Link>
        </div>
      </div>
      <div className={`footer-bottom d-flex ${isMobile ? 'flex-column' : ''}`}>
        <div className='w-100 me-5'>
          <p className='desc mb-0'>{t('footerDesc1')}</p>
        </div>
        <div className={`w-100 ${isMobile ? 'mt-4' : ''}`}>
          <p className='desc'>{t('footerDesc2')}</p>
          <div className='social-logos'>
            {/* <img className='social-logo' src={FacebookLogo} alt='' /> */}
            <Link to={TwitterLink} target='_blank' rel='noreferrer'>
              <img className='social-logo' src={TwitterLogo} alt='' />
            </Link>
            <Link to={InstagramLink} target='_blank' rel='noreferrer'>
              <img className='social-logo' src={InstagramLogo} alt='' />
            </Link>
            {/* <img className='social-logo me-0' src={YoutubeLogo} alt='' /> */}
          </div>
          <p className='desc mb-0'>{t('rightsReserved')}</p>
        </div>
      </div>
    </div>
  )
}
