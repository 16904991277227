import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from 'src/core/components'
import TJCloudFunctions from 'src/core/services/firebase/cloudFunctions/tradeJournal/TJCloudFunctions'
import {
  setReportsLoading,
  setReportsStates,
  setTJSummaryParams,
} from 'src/core/store/features/reports/reports'
import { useGetTradingDaysQuery } from 'src/core/store/features/tradeJournal/api'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

import { Overview } from './components/overview/Overview'

export const Reports = () => {
  const isLoading = useSelector((state: RootState) => state.reports.loading)

  const stats = useSelector((state: RootState) => state.reports.states)

  const dispatch = useDispatch()
  const selectedAccount = useSelector(
    (state: RootState) => state.tradeJournal.selectedConnection,
  )

  const tradingDaysQuery = useGetTradingDaysQuery()

  useEffect(() => {
    async function fetchData() {
      if (!tradingDaysQuery.data || !selectedAccount?.brokerage) {
        return
      }
      const endDate = tradingDaysQuery.data?.[0]?.date

      const filteredTradingDays = tradingDaysQuery.data.filter(
        (day) =>
          new Date(day.date).getMonth() === new Date(endDate).getMonth() &&
          new Date(day.date).getFullYear() === new Date(endDate).getFullYear(),
      )

      const startDate = filteredTradingDays[filteredTradingDays.length - 1].date

      dispatch(setReportsLoading(true))
      const response = await TJCloudFunctions.getTradeJournalSummaryForReports({
        startDate,
        endDate,
        brokerage: selectedAccount?.brokerage,
      })

      if (!response.error) {
        dispatch(
          setTJSummaryParams({
            startDate,
            endDate,
          }),
        )
        dispatch(setReportsStates(response.data))
      }
      dispatch(setReportsLoading(false))
    }

    fetchData()
  }, [selectedAccount, tradingDaysQuery.data])

  if (isLoading || tradingDaysQuery.isLoading) {
    return <Loading />
  }

  if (Object.keys(stats).length === 0) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        There is no trading data for this account!
      </div>
    )
  }

  return (
    <div className='d-flex flex-column root-container '>
      <div className='tab-content-container'>
        <Overview />
      </div>
    </div>
  )
}
