// @ts-nocheck
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowRight, FaBell } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

import './styles.scss'

import { NotificationsList } from '../components/notificationsList/notificationsList'

const NotificationsDropdown = () => {
  const [t] = useTranslation('notifications')

  const [showNotifications, setShowNotifications] = useState(false)

  const notificationsRef = useRef()
  const notificationsBellRef = useRef()

  const hideNotifications = useCallback(() => {
    setShowNotifications(false)
  }, [setShowNotifications])

  const toggleNotifications = useCallback(() => {
    setShowNotifications((showNotifications) => !showNotifications)
  }, [setShowNotifications])

  const onClickOutside = useCallback(
    (event: Event) => {
      if (!notificationsRef.current) return
      const eventTarget = event.target as HTMLElement
      // do nothing on clicking ref's element or descendent elements
      if (
        notificationsRef.current.contains(eventTarget) ||
        notificationsBellRef.current.contains(eventTarget)
      )
        return
      // hide menu otherwise
      hideNotifications()
    },
    [hideNotifications],
  )

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside)
    return () => document.removeEventListener('mousedown', onClickOutside)
  }, [onClickOutside])

  return (
    <>
      <div
        ref={notificationsBellRef}
        className='notifications-wrapper d-flex align-items-center justify-content-center'
        onClick={toggleNotifications}
      >
        <FaBell size={24} />
      </div>
      <div
        className={`notifications-dropdown ${showNotifications ? '' : 'hide'}`}
        ref={notificationsRef}
      >
        <NotificationsList height={300} type='tsNewsLetters' isPopup />
        <NavLink
          to='/dashboard/notifications'
          className='notifications-dropdown-footer d-flex align-items-center justify-content-between'
          onClick={hideNotifications}
        >
          <p>{t('viewAllNotifications')}</p>
          <FaArrowRight size={14} />
        </NavLink>
      </div>
    </>
  )
}

export default NotificationsDropdown
