import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaAlignJustify, FaCog } from 'react-icons/fa'
import { RiArrowDownSLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { find, flatten } from 'lodash'
import TDIcon from 'src/assets/images/trade-journal/connections/td-icon.svg'
import WebullIcon from 'src/assets/images/trade-journal/connections/webull-icon.svg'
import { FiltersButton } from 'src/core/components'
import Sidebar from 'src/core/components/layout/sidebar/Sidebar'
import TJCloudFunctions, {
  BrokerageAccount,
} from 'src/core/services/firebase/cloudFunctions/tradeJournal/TJCloudFunctions'
import { setOpenFilterModal } from 'src/core/store/features/filters/filters'
import {
  setReportsLoading,
  setReportsStates,
  setTJSummaryParams,
} from 'src/core/store/features/reports/reports'
import {
  useGetBrokeragesConnectedQuery,
  useGetTradeJournalQuery,
  useGetTradeJournalSummaryQuery,
} from 'src/core/store/features/tradeJournal/api'
import { setTJSelectedConnection } from 'src/core/store/features/tradeJournal/tradeJournal'
import { RootState } from 'src/core/store/Store'
import { NotificationPopup } from 'src/modules/notifications/components/notificationPopup/notificationPopup'
import NotificationsDropdown from 'src/modules/notifications/notificationsDropdown/NotificationsDropdown'
import AddBrokerageConnection, {
  ChosenConnection,
} from 'src/modules/tradeJournal/components/addBrokerageConnection/AddBrokerageConnection'
import AddBrokerageCSV from 'src/modules/tradeJournal/components/addBrokerageCSV/AddBrokerageCSV'
import ChooseBrokerageAccount from 'src/modules/tradeJournal/components/chooseBrokerageAccount/ChooseBrokerageAccount'

import './styles.scss'

import CustomDatePickerRange from '../../calendars/CustomDatePickerRange/CustomDatePickerRange'

const PageLayout = ({ children }: { children: React.ReactNode }) => {
  const tradeJournalSummaryQuery = useGetTradeJournalSummaryQuery()
  const dispatch = useDispatch()
  const location = useLocation()

  const [sidebarOpened, setSidebarOpened] = useState(true)

  // START: TRADE JOURNAL (Adding / Choosing Accounts)
  const selectedAccount = useSelector(
    (state: RootState) => state.tradeJournal.selectedConnection,
  )

  const brokeragesConnected = useGetBrokeragesConnectedQuery().data
  const accounts = brokeragesConnected
    ? flatten(
        brokeragesConnected
          .filter((b) => b.accounts)
          .map((b) =>
            b.accounts?.map((acc) => {
              return { ...acc, brokerage: b.brokerage }
            }),
          ),
      )
    : []

  useEffect(() => {
    if (brokeragesConnected?.[0]?.accounts?.length) {
      dispatch(
        setTJSelectedConnection({
          data: {
            ...brokeragesConnected[0].accounts[0],
            brokerage: brokeragesConnected[0].brokerage,
          },
        }),
      )
    }
  }, [brokeragesConnected])

  const [showChooseBrokerageAccount, setShowChooseBrokerageAccount] =
    useState(false)
  const [showAddBrokerageConnection, setShowAddBrokerageConnection] =
    useState(false)
  const [showUploadYourCSV, setShowUploadYourCSV] = useState(false)
  const [csvConnectionId, setCSVConnectionId] = useState('')

  const handleCSV = (connection: ChosenConnection) => {
    setShowAddBrokerageConnection(false)
    setCSVConnectionId(connection.id)
    setShowUploadYourCSV(true)
  }

  const onChooseAccount = (connections: string[]) => {
    const connectionId = connections[0]
    const chosenAccount = find(accounts, { id: connectionId })
    dispatch(setTJSelectedConnection({ data: chosenAccount! }))
  }
  const netPLFormatter = new Intl.NumberFormat('en-US', { notation: 'compact' })

  const getIcon = (brokerage: string | undefined) => {
    if (brokerage === 'td_ameritrade') {
      return TDIcon
    } else if (brokerage === 'webull') {
      return WebullIcon
    } else {
      return undefined
    }
  }
  // END: TRADE JOURNAL

  const handleClickFiltersButton = () => {
    dispatch(setOpenFilterModal({ openModal: true }))
  }
  const navbarHeight = document
    .getElementById('top-navbar')
    ?.getBoundingClientRect().height

  const [dates, setDates] = useState<Date[]>([])
  const [reportsDateRange, setReportsDateRange] = useState<Date[]>([])

  const tradeJournalQuery = useGetTradeJournalQuery()

  useEffect(() => {
    if (location.pathname === '/reports') {
      setReportsDateRange([])
    }
  }, [location.pathname])

  useEffect(() => {
    async function getSummary() {
      if (reportsDateRange.length === 2 && location.pathname === '/reports') {
        dispatch(setReportsLoading(true))
        // Start Date
        const startDate = new Date(reportsDateRange[0])
        startDate.setDate(startDate.getDate() + 1)
        const startDateFormatted = startDate.toISOString().split('T')[0]

        // End Date
        const endDate = reportsDateRange[1].toISOString().split('T')[0]

        // Get Summary
        const response =
          await TJCloudFunctions.getTradeJournalSummaryForReports({
            startDate: startDateFormatted,
            endDate,
            brokerage: selectedAccount?.brokerage || '',
          })

        // Handle Response
        if (response.error) {
          toast.error(response.error.message)
          setReportsDateRange([])
          dispatch(setReportsLoading(false))
        } else {
          dispatch(
            setTJSummaryParams({ startDate: startDateFormatted, endDate }),
          )
          dispatch(setReportsStates(response.data))
        }
      }
    }
    getSummary()
  }, [reportsDateRange])

  if (
    window.location.pathname.includes('policy') ||
    window.location.pathname.includes('terms-of-service')
  ) {
    return <>{children}</>
  }

  return (
    <div className={'page-layout ' + (sidebarOpened ? '' : 'active')}>
      <div className='wrapper'>
        <div className='section d-flex flex-column'>
          <Container fluid className='top-navbar' id='top-navbar'>
            <Row className='h-100'>
              <Col md={6} className='left-section'>
                <a
                  className='switch-sidebar-btn'
                  href='#'
                  onClick={() => setSidebarOpened(!sidebarOpened)}
                >
                  <FaAlignJustify size='1.3em' />
                </a>
                {location.pathname === '/reports' && (
                  <p className='reports-text'>Reports</p>
                )}
                {['/trade-journal', '/daily-stats'].includes(
                  location.pathname,
                ) && (
                  <div
                    className='d-flex align-items-center ms-2'
                    onClick={() => {
                      setShowChooseBrokerageAccount(true)
                    }}
                  >
                    {selectedAccount && getIcon(selectedAccount.brokerage) && (
                      <img
                        src={getIcon(selectedAccount.brokerage)}
                        className='selected-account-icon me-2'
                      />
                    )}
                    <p className='account-selector'>
                      {selectedAccount
                        ? selectedAccount.name
                        : 'Choose an account'}{' '}
                      <RiArrowDownSLine />
                    </p>
                  </div>
                )}
                {location.pathname === '/trade-journal' && (
                  <p className='journal-profit ms-3'>
                    Net P&L{' $'}
                    {netPLFormatter.format(
                      tradeJournalSummaryQuery.data?.netPL ?? 0,
                    )}
                  </p>
                )}
              </Col>
              <Col md={6} className='right-section'>
                {['/order-flow', '/dashboard'].includes(location.pathname) && (
                  <FiltersButton onClick={handleClickFiltersButton} />
                )}
                {location.pathname === '/trade-journal' && (
                  <div className='me-3'>
                    <FaCog
                      className='settings-icon'
                      onClick={() => {
                        setShowAddBrokerageConnection(true)
                      }}
                    />
                  </div>
                )}
                {/* <ThemeModeButton /> */}
                {location.pathname === '/reports' && (
                  <div className='date-range-picker-container'>
                    <CustomDatePickerRange
                      isShortVersion
                      value={reportsDateRange}
                      onChange={setReportsDateRange}
                      tradingDays={tradeJournalQuery.data}
                    />
                  </div>
                )}
                {location.pathname === '/daily-stats' && (
                  <div className='date-range-picker-container'>
                    <CustomDatePickerRange
                      isShortVersion
                      value={dates}
                      onChange={setDates}
                    />
                  </div>
                )}
                {location.pathname !== '/trade-journal' && (
                  <NotificationsDropdown />
                )}
              </Col>
            </Row>
          </Container>
          <div
            className={`main-page ${sidebarOpened ? 'sidebar-opened' : ''}`}
            style={{
              height: `calc(100vh - ${navbarHeight}px)`,
              overflow: 'scroll',
            }}
          >
            {children}
          </div>
        </div>
        <Sidebar opened={sidebarOpened} />
      </div>
      <NotificationPopup />
      {showAddBrokerageConnection && (
        <AddBrokerageConnection
          visible={showAddBrokerageConnection}
          hideDetails={() => {
            setShowAddBrokerageConnection((s) => !s)
          }}
          onSubmit={handleCSV}
        />
      )}
      {showUploadYourCSV && (
        <AddBrokerageCSV
          visible={showUploadYourCSV}
          onHide={() => {
            setShowUploadYourCSV((s) => !s)
          }}
          brokerage={csvConnectionId}
        />
      )}
      {showChooseBrokerageAccount && (
        // TODO: Depending on the callback assign the correct brokerage instead of hard coding it
        <ChooseBrokerageAccount
          visible={showChooseBrokerageAccount}
          onHide={() => {
            setShowChooseBrokerageAccount((s) => !s)
          }}
          accounts={accounts as BrokerageAccount[]}
          onSubmit={onChooseAccount}
          isMulti={false}
        />
      )}
    </div>
  )
}

export default PageLayout
