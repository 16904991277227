import { useContext, useMemo } from 'react'
import cls from 'classnames'
import BlackBox from 'src/assets/images/order-flow/black-box.svg'
import BlueBox from 'src/assets/images/order-flow/blue-box.svg'
import BlueBoxLight from 'src/assets/images/order-flow/blue-box-light.svg'
import GoldenCircle from 'src/assets/images/order-flow/golden-circle.svg'
import GoldenCircleLight from 'src/assets/images/order-flow/golden-circle-light.svg'
import TwoBarsBlue from 'src/assets/images/order-flow/two-bars-blue.svg'
import TwoBarsBlueLight from 'src/assets/images/order-flow/two-bars-blue-light.svg'
import WhiteBox from 'src/assets/images/order-flow/white-box.svg'
import { ThemeContext } from 'src/core/utils/theme/ThemeContext'

import './styles.scss'

import { OrderType } from '../../types'

type ConsolidationProps = {
  type: OrderType
  isGolden?: boolean
}

export const Consolidation = ({ type, isGolden }: ConsolidationProps) => {
  const { theme } = useContext(ThemeContext)

  const imgSrc = useMemo(() => {
    if (type === 'UnusualSweep') {
      return null
    }
    if (theme === 'dark') {
      return isGolden
        ? GoldenCircle
        : type === 'Block'
        ? BlackBox
        : type === 'Sweep'
        ? BlueBox
        : type === 'SPLIT'
        ? TwoBarsBlue
        : type === 'LARGE'
        ? WhiteBox
        : null
    } else {
      return isGolden
        ? GoldenCircleLight
        : type === 'Block'
        ? BlackBox
        : type === 'Sweep'
        ? BlueBoxLight
        : type === 'SPLIT'
        ? TwoBarsBlueLight
        : type === 'LARGE'
        ? WhiteBox
        : null
    }
  }, [theme, type, isGolden])

  return (
    <span
      className={cls(
        'order-consolidation',
        type.toString().toLocaleLowerCase(),
        { gold: isGolden },
      )}
    >
      {imgSrc && <img src={imgSrc} alt='Consolidation Image' />}
      {type.toString().replace('UnusualSweep', 'Unusual Sweep').toUpperCase()}
    </span>
  )
}
