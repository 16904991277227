import { useSelector } from 'react-redux'
import { AuthHeader, Loading } from 'src/core/components'
import { RootState } from 'src/core/store/Store'

import SelectPackage from '../register/components/selectPackage/SelectPackage'

const ChoosePlan = () => {
  const isSubscribing = useSelector(
    (state: RootState) => state.register.isSubscribing,
  )
  return (
    <>
      {isSubscribing ? (
        <Loading />
      ) : (
        <div className='choose-plan'>
          <AuthHeader />
          <div className='p-4'>
            <SelectPackage />
          </div>
        </div>
      )}
    </>
  )
}

export default ChoosePlan
