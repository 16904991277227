import { configureStore } from '@reduxjs/toolkit'

import filtersReducer from './features/filters/filters'
import newsReducer from './features/news/news'
import notificationsReducer from './features/notifications/notifications'
import ordersReducer from './features/orders/orders'
import registerReducer from './features/registration/registration'
import reportsReducer from './features/reports/reports'
import sentimentsReducer from './features/sentiments/sentiments'
import topMoversReducer from './features/topMovers/topMovers'
import {
  rtkQueryErrorLogger,
  tradeJournalApi,
} from './features/tradeJournal/api'
import tjReducer from './features/tradeJournal/tradeJournal'
import userDetailsReducer from './features/user/userDetails'
import watchlistReducter from './features/watchlist/watchlist'

export const store = configureStore({
  reducer: {
    register: registerReducer,
    userDetails: userDetailsReducer,
    filters: filtersReducer,
    news: newsReducer,
    notifications: notificationsReducer,
    orders: ordersReducer,
    topMovers: topMoversReducer,
    watchlist: watchlistReducter,
    sentiment: sentimentsReducer,
    tradeJournal: tjReducer,
    reports: reportsReducer,
    [tradeJournalApi.reducerPath]: tradeJournalApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(tradeJournalApi.middleware)
      .concat(rtkQueryErrorLogger),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
