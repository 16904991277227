import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle } from 'react-icons/ai'
import { useMediaQuery } from 'react-responsive'
import cls from 'classnames'
import AppLogo from 'src/assets/images/logo.svg'
import { DefaultButton } from 'src/core/components'

import './styles.scss'

interface PackageCardProps {
  type: 'monthly' | 'yearly'
  loadingPriceId: string | undefined
  onSubmit: () => void
}

const PackageCard = ({ type, loadingPriceId, onSubmit }: PackageCardProps) => {
  const [t] = useTranslation('auth')
  const isMobile = useMediaQuery({ query: `(max-width: 599px)` })

  const features = t(`packages.${type}.features`, {
    returnObjects: true,
  }) as Array<string>

  const loading = loadingPriceId
    ? (type === 'monthly' &&
        loadingPriceId === process.env.REACT_APP_MONTHLY_PRICE_ID) ||
      (type === 'yearly' &&
        loadingPriceId === process.env.REACT_APP_YEARLY_PRICE_ID)
    : false

  const goldenClass = type === 'yearly' ? 'golden-border' : ''

  return (
    <div
      className={cls('golden-border d-flex justify-content-center', {
        'w-100': isMobile,
      })}
    >
      <div className={`package-container ${goldenClass}`}>
        <div
          className={`package-title-container d-flex align-items-center justify-content-center ${goldenClass}`}
        >
          <img className='me-2' height={24} src={AppLogo} alt='' />
          <p className='title mb-0'>{t(`packages.${type}.name`)}</p>
        </div>

        <div className={`top-container ${goldenClass}`}>
          {type === 'yearly' && (
            <div className='w-100 d-flex justify-content-center'>
              <div className='offer-container'>
                <p className='offer-text'>{t(`packages.${type}.offer`)}</p>
              </div>
            </div>
          )}
          <div className='old-price-container'>
            <p className='mb-0 title'>{t(`packages.${type}.oldPrice`)}</p>
          </div>
          <div className='strike-through' />
          <p className='price'>{t(`packages.${type}.tempPrice`)}</p>
          {t(`packages.${type}.desc`)
            .split('\n')
            .map((text, index) => (
              <p key={`desc-${index}`} className='desc'>
                {text}
              </p>
            ))}
        </div>
        <div className='bottom-container'>
          <div className='features-container'>
            {features.map((item, index) => {
              return (
                <div
                  key={index}
                  className='feature-row d-flex align-items-center'
                >
                  <AiFillCheckCircle className='check-icon' />
                  <p className='feature-text'>{item}</p>
                </div>
              )
            })}
          </div>

          <DefaultButton
            onClick={onSubmit}
            loading={loading}
            disabled={loadingPriceId !== undefined}
            title={t('packages.submit')}
          />
        </div>
      </div>
    </div>
  )
}

export default PackageCard
