import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdEventNote } from 'react-icons/md'
import { RiArrowDownSLine } from 'react-icons/ri'
import cls from 'classnames'
import { format } from 'date-fns'
import CustomAreaChart from 'src/core/components/charts/AreaChart/AreaChart'
import { TradeJournal } from 'src/core/store/features/tradeJournal/types'
import { currencyFormatter } from 'src/core/utils/number'

import './styles.scss'

import { TransactionsTable } from '../transactionsTable/TransactionsTable'

interface DailyStatsCardProps {
  className?: string
  tradeJournal: TradeJournal
  tradeJournalPath: string
}

const DailyStatsCard = ({
  className,
  tradeJournal,
  tradeJournalPath,
}: DailyStatsCardProps) => {
  const [t] = useTranslation('dailyStats')
  const [IsShowMore, setIsShowMore] = useState(false)
  const reveresedTransactions = [...tradeJournal.transactions].reverse()
  return (
    <div className={cls('daily-stats-card', className)}>
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <div className='d-flex align-items-center'>
          <p className='title'>
            {format(new Date(tradeJournal.date), 'E, MMM dd, yyyy')}
          </p>
          <div className='dot mx-3' />
          <p className={cls('desc', tradeJournal.netPL < 0 && 'negative')}>
            {t('table.netPL')} {currencyFormatter.format(tradeJournal.netPL)}
          </p>
        </div>
        <div className='d-flex align-items-center'>
          <button className='note-button d-flex align-items-center me-4'>
            <MdEventNote className='me-2' />
            {t('writeANote')}
          </button>
          <p
            className='show-more cursor-pointer'
            onClick={() => {
              setIsShowMore((s) => !s)
            }}
          >
            {IsShowMore ? t('hide') : t('showMore')}{' '}
            <RiArrowDownSLine size={16} />
          </p>
        </div>
      </div>
      <div className='d-flex'>
        <div className='flex-1 me-4'>
          <CustomAreaChart
            data={reveresedTransactions.map((transaction) => ({
              value: transaction.grossPL,
            }))}
            xAxisTickFormatter={(index) => {
              const transaction = reveresedTransactions[index]
              return transaction
                ? format(new Date(transaction.transactionDate), 'H:mm')
                : ''
            }}
            height={158 + 40}
            type={tradeJournal.netPL < 0 ? 'danger' : 'success'}
          />
        </div>
        <div className='stats-table flex-1'>
          <div className='d-flex h-50'>
            <DailyStatBox
              title={t('totalTrades')}
              desc={tradeJournal.tradeCount}
              className='br-tl'
            />
            <DailyStatBox
              title={t('winners')}
              className='br-tr'
              desc={tradeJournal.winners}
            />
          </div>
          <div className='d-flex h-50'>
            <DailyStatBox
              title={t('winrate')}
              desc={'%' + tradeJournal.winRate.toFixed(2)}
              className='br-bl'
            />
            <DailyStatBox
              title={t('losers')}
              desc={tradeJournal.losers}
              className='br-br'
            />
          </div>
        </div>
      </div>
      {IsShowMore && (
        <div className='mt-3'>
          <TransactionsTable
            tradeJournalPath={tradeJournalPath + '/' + tradeJournal.id}
            transactions={tradeJournal.transactions}
          />
        </div>
      )}
    </div>
  )
}

const DailyStatBox = ({ title, desc, className }: any) => {
  return (
    <div
      className={`stats-box d-flex flex-column justify-content-center ${className}`}
    >
      <p className='title'>{title}</p>
      <p className='desc'>{desc}</p>
    </div>
  )
}

export default DailyStatsCard
