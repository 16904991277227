import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import './styles.scss'

type DateInputProps = {
  value?: Date | null
  onChange: (date: Date) => void
}

const DateInput = ({ value, onChange }: DateInputProps) => {
  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      placeholderText='Select a date...'
      className='date-input-component'
      dateFormat='MMM dd, yyyy'
    />
  )
}

export default DateInput
