import { ReactNode } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { SectionTitle } from 'src/core/components'

import './styles.scss'

interface PageHeaderProps {
  title: string
  component?: ReactNode
}

const PageHeader = (props: PageHeaderProps) => {
  return (
    <Container className='page-header-component'>
      <Row>
        <Col className='left'>
          <SectionTitle title={props.title} />
        </Col>
        <Col className='right d-inline-flex'>{props.component}</Col>
      </Row>
    </Container>
  )
}

export default PageHeader
