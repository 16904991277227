import { httpsCallable } from 'firebase/functions'
import { reportError } from 'src/core/services'

import { Functions } from '../../Firebase'

interface Response {
  status: boolean
  data: any
  message: string
}
class PaymentCloudFunctions {
  async unsubscribePackage(subscriptionId: string): Promise<Response | null> {
    try {
      const unsubscribeClFunction = httpsCallable(
        Functions,
        'unsubscribePackage',
      )
      const result = await unsubscribeClFunction({ subscriptionId })
      if (result) {
        return result.data as Response
      }
      return null
    } catch (error) {
      reportError(error)
      return null
    }
  }

  async reActivatePackage(subscriptionId: string): Promise<Response | null> {
    try {
      const reactivateClFunction = httpsCallable(Functions, 'reActivatePackage')
      const result = await reactivateClFunction({ subscriptionId })
      if (result) {
        return result.data as Response
      }
      return null
    } catch (error) {
      reportError(error)
      return null
    }
  }

  async switchPackage(
    subscriptionId: string,
    priceId: string,
  ): Promise<Response | null> {
    try {
      const clFunction = httpsCallable(Functions, 'switchPackage')
      const result = await clFunction({ subscriptionId, priceId })
      if (result) {
        return result.data as Response
      }
      return null
    } catch (error) {
      reportError(error)
      return null
    }
  }

  async calculatePackageProrations(
    subscriptionId: string,
    newPriceId: string,
    stripeID: string,
  ): Promise<Response | null> {
    try {
      const clFunction = httpsCallable(Functions, 'calculatePackageProrations')
      const result = await clFunction({ subscriptionId, newPriceId, stripeID })
      if (result) {
        return result.data as Response
      }
      return null
    } catch (error) {
      reportError(error)
      return null
    }
  }
}

export default new PaymentCloudFunctions()
