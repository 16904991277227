import { Link } from 'react-router-dom'

import './styles.scss'

interface Props {
  title: string
  link?: string
  onClick?: any
}

const TextLink = ({ title, link = '#', onClick }: Props) => {
  return (
    <Link onClick={onClick} to={link} className='text-link-component'>
      <p>{title}</p>
    </Link>
  )
}

export default TextLink
