import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ClearFilterButton } from 'src/core/components'
import { setFilters } from 'src/core/store/features/filters/filters'

import { TFilterCondition } from '../../types'
import FilterChip from '../filterChip/FilterChip'

type FilterChipsProps = {
  filters?: {
    [filterConditionName: string]: TFilterCondition
  } | null
}

const FilterChips = ({ filters, ...props }: FilterChipsProps) => {
  const [t] = useTranslation()

  const dispatch = useDispatch()

  const showingFilters = useMemo(() => {
    return Object.keys(filters || [])
      .filter((_key) => {
        return filters?.[_key].operator && filters?.[_key].value
      })
      .map((_key) => {
        return {
          name: _key,
          operator: filters?.[_key].operator,
          value: filters?.[_key].value as any,
        }
      })
      .flat()
  }, [filters])

  if (!showingFilters || showingFilters.length === 0) {
    return <></>
  }
  return (
    <div {...props} className='d-flex gap-2' id='filters-chips'>
      {showingFilters.map((_filter) => (
        <FilterChip
          key={`${_filter.name}_${_filter.operator?.value}_${_filter?.value}`}
          {..._filter}
        />
      ))}
      <ClearFilterButton
        onClick={() => dispatch(setFilters({ filters: null }))}
      >
        {t('common:clear')}
      </ClearFilterButton>
    </div>
  )
}
export default FilterChips
