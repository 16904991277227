import React from 'react'

export const AddIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z'
        fill='black'
      />
    </svg>
  )
}

export const DollarIcon = () => {
  return (
    <svg
      width='9'
      height='15'
      viewBox='0 0 9 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.24 10.008C8.24 10.8827 7.952 11.6027 7.376 12.168C6.8 12.7333 6.05333 13.0693 5.136 13.176V14.6H4.128V13.192C3.25333 13.128 2.50667 12.888 1.888 12.472C1.28 12.056 0.837333 11.496 0.56 10.792L1.84 10.056C2.20267 11.048 2.96533 11.608 4.128 11.736V7.864C3.648 7.69333 3.25867 7.53867 2.96 7.4C2.672 7.25067 2.368 7.05333 2.048 6.808C1.728 6.56267 1.488 6.264 1.328 5.912C1.17867 5.54933 1.104 5.13333 1.104 4.664C1.104 3.8 1.392 3.096 1.968 2.552C2.544 2.008 3.264 1.69333 4.128 1.608V0.2H5.136V1.624C5.776 1.70933 6.34667 1.93333 6.848 2.296C7.34933 2.65867 7.73867 3.14933 8.016 3.768L6.768 4.472C6.45867 3.69333 5.91467 3.22933 5.136 3.08V6.76C5.62667 6.94133 6.016 7.10133 6.304 7.24C6.592 7.37867 6.90667 7.576 7.248 7.832C7.58933 8.07733 7.84 8.38133 8 8.744C8.16 9.10667 8.24 9.528 8.24 10.008ZM2.576 4.664C2.576 5.06933 2.69867 5.4 2.944 5.656C3.18933 5.912 3.584 6.152 4.128 6.376V3.064C3.65867 3.128 3.28 3.304 2.992 3.592C2.71467 3.86933 2.576 4.22667 2.576 4.664ZM5.136 11.704C5.648 11.6293 6.04267 11.4427 6.32 11.144C6.608 10.8347 6.752 10.4613 6.752 10.024C6.752 9.58667 6.61867 9.24 6.352 8.984C6.096 8.71733 5.69067 8.46667 5.136 8.232V11.704Z'
        fill='white'
      />
    </svg>
  )
}

interface Props {
  onClick?: () => void
}

export const DarkCloseIcon = ({
  onClick = () => {
    return null
  },
}: Props) => (
  <svg
    width='54'
    height='54'
    viewBox='0 0 54 54'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    style={{
      cursor: 'pointer',
    }}
    className='close-icon'
  >
    <circle cx='27' cy='27' r='27' fill='#2B2B2B' />
    <path
      d='M21 33L33 21'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M33 33L21 21'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const ArrowIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM14.79 12.53L11.26 16.06C11.11 16.21 10.92 16.28 10.73 16.28C10.54 16.28 10.35 16.21 10.2 16.06C9.91 15.77 9.91 15.29 10.2 15L13.2 12L10.2 9C9.91 8.71 9.91 8.23 10.2 7.94C10.49 7.65 10.97 7.65 11.26 7.94L14.79 11.47C15.09 11.76 15.09 12.24 14.79 12.53Z'
        fill='#181411'
      />
    </svg>
  )
}
