// @ts-nocheck
import { useCallback, useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CgProfile } from 'react-icons/cg'
import { HiCheckCircle } from 'react-icons/hi'
import { MdEmail } from 'react-icons/md'
import { addDoc, collection } from 'firebase/firestore'
import { AuthHeader, DefaultButton, DefaultInput } from 'src/core/components'
import { FireStoreDB } from 'src/core/services'
import { displayErrorMessage } from 'src/core/utils/alerts/ToastAlerts'

import './styles.scss'

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

export const ContactUs = () => {
  const [t] = useTranslation('contactUs')

  const [isContacted, setIsContacted] = useState(false)

  const [fullName, setFullName] = useState<string | null>('')
  const [email, setEmail] = useState<string | null>('')
  const [message, setMessage] = useState<string | null>('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const validate = async () => {
      if (!fullName || !fullName?.trim().length) {
        setIsDisabled(true)
      } else if (!message || !message?.trim().length) {
        setIsDisabled(true)
      } else if (!validateEmail(email)) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    }

    validate()
  }, [fullName, email, message])

  const onSubmit = useCallback(async () => {
    setLoading(true)
    try {
      await addDoc(collection(FireStoreDB, 'contactUsMessages'), {
        to: ['support@boomstocks.app'],
        message: {
          subject: `Online Support from (${fullName} - ${email})!`,
          text: message,
        },
      })
      setLoading(false)
      setIsContacted(true)
      setIsDisabled(true)
    } catch (error) {
      displayErrorMessage(error.message)
      setLoading(false)
    }
  }, [fullName, email, message])

  return (
    <>
      <AuthHeader />
      {!isContacted ? (
        <Col
          xs={{ offset: 1, span: 10 }}
          lg={{ offset: 3, span: 6 }}
          xl={{ offset: 4, span: 4 }}
        >
          <div className='contact-us d-flex flex-column justify-content-center'>
            <p className='title'>{t('contactUs')}</p>
            <p className='desc'>{t('letUsKnowQ')}</p>
            <DefaultInput
              type='text'
              onChange={(event: any) => setFullName(event.target.value)}
              placeholder={t('common:fields.fullName')}
              icon={<CgProfile />}
            />
            <div className='input-container'>
              <DefaultInput
                type='email'
                onChange={(event: any) => setEmail(event.target.value)}
                placeholder={t('common:fields.email')}
                icon={<MdEmail />}
              />
            </div>
            <div className='input-container'>
              <DefaultInput
                resizable={false}
                as='textarea'
                onChange={(event: any) => setMessage(event.target.value)}
                placeholder={t('common:fields.message')}
              />
            </div>
            <div className='button-container'>
              <DefaultButton
                title='Send Message'
                onClick={() => {
                  onSubmit()
                }}
                loading={loading}
                disabled={isDisabled}
              />
            </div>
          </div>
        </Col>
      ) : (
        <div className='contacted-us h-100 w-100'>
          <Col
            xs={{ offset: 1, span: 10 }}
            lg={{ offset: 3, span: 6 }}
            xl={{ offset: 4, span: 4 }}
            className='d-flex flex-column justify-content-center align-items-center'
          >
            <div className='success-icon'>
              <HiCheckCircle size={80} />
            </div>
            <p className='title'>{t('emailSent')}</p>
            <p className='desc'>{t('emailThanks')}</p>
            <div className='button-container w-100'>
              <DefaultButton
                title='Ok'
                onClick={() => {
                  setIsContacted(false)
                }}
              />
            </div>
          </Col>
        </div>
      )}
    </>
  )
}
