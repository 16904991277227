import {
  createUserWithEmailAndPassword,
  deleteUser,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
} from 'firebase/auth'
import { FirebaseAuth } from 'src/core/services'
import { resetNews } from 'src/core/store/features/news/news'
import { resetNotifications } from 'src/core/store/features/notifications/notifications'
import { resetOrders } from 'src/core/store/features/orders/orders'
import { setIsSubscribing } from 'src/core/store/features/registration/registration'
import { resetSentiments } from 'src/core/store/features/sentiments/sentiments'
import { resetTopMovers } from 'src/core/store/features/topMovers/topMovers'
import { resetTJ } from 'src/core/store/features/tradeJournal/tradeJournal'
import { resetUser } from 'src/core/store/features/user/userDetails'
import { store } from 'src/core/store/Store'

import { reportError } from '../../errorReporter'
import Users from '../firestore/Users'

class AuthService {
  async signInEmail(email: string, password: string) {
    const result = await signInWithEmailAndPassword(
      FirebaseAuth,
      email,
      password,
    )
    return result
  }

  async sendForgetPasswordEmail(email: string) {
    const result = await sendPasswordResetEmail(FirebaseAuth, email)
    return result
  }

  async signOut() {
    store.dispatch(resetTJ())
    store.dispatch(resetUser())
    store.dispatch(resetNews())
    store.dispatch(resetNotifications())
    store.dispatch(resetOrders())
    store.dispatch(resetTopMovers())
    store.dispatch(resetSentiments())
    store.dispatch(setIsSubscribing(false))
    return await signOut(FirebaseAuth).then(() => {
      window.location.reload()
    })
  }

  getCurrentUser() {
    return FirebaseAuth.currentUser
  }

  async reloadUserData() {
    return await FirebaseAuth.currentUser?.reload()
  }

  async checkEmailExist(email: string) {
    const result = await fetchSignInMethodsForEmail(FirebaseAuth, email)
    return result.length
  }

  async register(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) {
    const result = await createUserWithEmailAndPassword(
      FirebaseAuth,
      email,
      password,
    )
    await Users.add({
      firstName,
      userID: result.user.uid,
      lastName,
      emailAddress: email,
    })
    this.verifyEmail()
    return result.user.uid
  }

  async verifyEmail() {
    try {
      const user = this.getCurrentUser()
      if (user) {
        await sendEmailVerification(user)
        return true
      }
      return false
    } catch (error) {
      reportError(error)
      return false
    }
  }

  async updateEmail(email: string) {
    try {
      await this.reloadUserData()
      const user = this.getCurrentUser()
      if (user) {
        await updateEmail(user, email)
        return true
      }
      return false
    } catch (error) {
      reportError(error)
      return false
    }
  }

  async reAuthenticate(password: string) {
    try {
      const user = this.getCurrentUser()
      if (user && user.email) {
        const credential = EmailAuthProvider.credential(user.email, password)
        await reauthenticateWithCredential(user, credential)
        return true
      }
      return false
    } catch (error) {
      reportError(error)
      return false
    }
  }

  async updatePassword(newPassword: string) {
    try {
      const user = this.getCurrentUser()
      if (user) {
        await updatePassword(user, newPassword)
        return true
      }
      return false
    } catch (error) {
      reportError(error)
      return false
    }
  }

  async deleteAccount() {
    try {
      const user = this.getCurrentUser()
      if (user) {
        await deleteUser(user)
        return true
      }
      return false
    } catch (error) {
      reportError(error)
      return false
    }
  }
}

export default new AuthService()
