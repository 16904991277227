import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { find } from 'lodash'
import { Loading } from 'src/core/components'
import CustomCalendar from 'src/core/components/calendars/CustomCalendar/CustomCalendar'
import CustomDatePickerRange from 'src/core/components/calendars/CustomDatePickerRange/CustomDatePickerRange'
import TJCloudFunctions, {
  BrokerageAccount,
} from 'src/core/services/firebase/cloudFunctions/tradeJournal/TJCloudFunctions'
import {
  useGetTradeJournalQuery,
  useGetTradeJournalSummaryQuery,
  useGetTradingDaysQuery,
} from 'src/core/store/features/tradeJournal/api'
import {
  setTJSelectedConnection,
  setTJSummaryParams,
} from 'src/core/store/features/tradeJournal/tradeJournal'
import { RootState } from 'src/core/store/Store'
import { displayErrorMessage } from 'src/core/utils/alerts/ToastAlerts'

import './styles.scss'

import { AddGoalButton } from './components/addGoal/AddGoalButton'
import ChooseBrokerageAccount from './components/chooseBrokerageAccount/ChooseBrokerageAccount'
import TradeJournalInfoCard from './components/tradeJournalInfoCard/TradeJournalInfoCard'
import TradeJournalNetChart from './components/tradeJournalNetChart/TradeJournalNetChart'
import TradeJournalWinningCard from './components/tradeJournalWinningCard/TradeJournalWinningCard'

const formatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${year}-${month < 10 ? '0' + month : month}-${
    day < 10 ? '0' + day : day
  }`
}

const today = formatDate(new Date())

const TradeJournalPage = () => {
  const tradeJournalQuery = useGetTradeJournalQuery()

  // get the trade journal that has the closest date to today
  const closestTradeJournal = tradeJournalQuery.data?.reduce((prev, curr) => {
    const prevDate = new Date(prev.date)
    const currDate = new Date(curr.date)
    const todayDate = new Date(today)
    const prevDiff = Math.abs(prevDate.getTime() - todayDate.getTime())
    const currDiff = Math.abs(currDate.getTime() - todayDate.getTime())
    return prevDiff < currDiff ? prev : curr
  }, tradeJournalQuery.data[0])

  const netPL = closestTradeJournal?.netPL.toFixed(2)

  const [t] = useTranslation('tradeJournal')
  const dispatch = useDispatch()
  const dates = useSelector(
    (state: RootState) =>
      state.tradeJournal.datePicker.startDate &&
      state.tradeJournal.datePicker.endDate
        ? [
            new Date(state.tradeJournal.datePicker.startDate),
            new Date(state.tradeJournal.datePicker.endDate),
          ]
        : [],
    (prev, next) => {
      return prev[0] === next[0] && prev[1] === next[1]
    },
  )
  const formatter = Intl.NumberFormat('en', {
    maximumFractionDigits: 2,
  })

  const journalSummaryQuery = useGetTradeJournalSummaryQuery()

  const tradingDaysQuery = useGetTradingDaysQuery()

  useEffect(() => {
    if (tradingDaysQuery.data?.length) {
      const startDateIndex = Math.min(5, tradingDaysQuery.data?.length) - 1
      const endDateIndex = 0
      dispatch(
        setTJSummaryParams({
          startDate: tradingDaysQuery.data[startDateIndex].date,
          endDate: tradingDaysQuery.data[endDateIndex].date,
        }),
      )
    }
  }, [tradingDaysQuery.data])

  const code = new URLSearchParams(window.location.search).get('code')
  const [loading, setLoading] = useState(false)

  const [showChooseBrokerageAccount, setShowChooseBrokerageAccount] =
    useState(false)

  const [accountsOptions, setAccountsOptions] = useState<BrokerageAccount[]>([])

  /**
   * On initial page rendering, we check if there is any callback from connecting accounts such as 'td_ameritrade'.
   * Right now we only handle one case and it is hard coded. Once we connect more accounts this function should adapt to different accounts' callback
   * (Suggestion: This part could be handled in a route alone specifically for handling callback)
   */
  useEffect(() => {
    const tjLogin = async () => {
      if (code) {
        try {
          setLoading(true)
          const data = await TJCloudFunctions.tradeJournalLogin(
            code,
            'td_ameritrade',
          )
          setLoading(false)
          const accounts = find(data, { brokerage: 'td_ameritrade' })?.accounts
          if (accounts) {
            setAccountsOptions(accounts)
          }
          setShowChooseBrokerageAccount(true)
        } catch (error: any) {
          displayErrorMessage(error.message)
          setLoading(false)
        }
      }
    }
    tjLogin()
  }, [code])

  const onAddAccount = async (
    selectedAccounts: string[],
    brokerage: string,
  ) => {
    await TJCloudFunctions.tradeJournalSetupAccounts(
      brokerage,
      selectedAccounts,
    )
    const chosenAccount = find(accountsOptions, { id: selectedAccounts[0] })
    if (chosenAccount) {
      dispatch(
        setTJSelectedConnection({ data: { ...chosenAccount, brokerage } }),
      )
    }
  }

  return (
    <div className='trade-journal'>
      {loading ||
      tradingDaysQuery.isLoading ||
      journalSummaryQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='mb-5'>
            {/* <p className='journal-title'>{t('dailyGoal')}</p> */}
            <div className='d-flex justify-content-between'>
              <div className='add-goal-container'>
                {/* <InputWithAddButton
                  addText={t('addGoal')}
                  className='mt-2'
                  placeholder={t('addGoalPlaceholder')}
                /> */}
                <AddGoalButton netPL={netPL} />
              </div>
              <div className='date-range-picker-container'>
                <CustomDatePickerRange
                  tradingDays={tradingDaysQuery.data}
                  value={dates}
                  onChange={(dates) =>
                    dispatch(
                      setTJSummaryParams({
                        startDate: dates[0].toISOString(),
                        endDate: dates[1].toISOString(),
                      }),
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <TradeJournalInfoCard
              className='w-100 me-3'
              title={t('profitFactor')}
              desc={`${
                journalSummaryQuery.data?.profitFactor === -1
                  ? '🚀'
                  : journalSummaryQuery.data?.profitFactor.toFixed(1) ?? 0
              }`}
            />
            <TradeJournalInfoCard
              className='w-100 me-3'
              variant='success'
              title={t('avgWinningTrade')}
              desc={
                '$' +
                formatter.format(journalSummaryQuery.data?.avgWinningTrade ?? 0)
              }
            />
            <TradeJournalInfoCard
              className='w-100'
              variant='danger'
              title={t('avgLosingTrade')}
              desc={
                '$' +
                formatter.format(journalSummaryQuery.data?.avgLosingTrade ?? 0)
              }
            />
          </div>
          <div className='d-flex mt-4'>
            <div className='me-4 flex-1'>
              <div className='mb-4 d-flex'>
                <div className='flex-1'>
                  <TradeJournalWinningCard
                    title={t('winningPercByTrades')}
                    className='me-3'
                    winners={journalSummaryQuery.data?.winners ?? 0}
                    losers={journalSummaryQuery.data?.losers ?? 0}
                    percentage={Number(
                      journalSummaryQuery.data?.winRateByTrade.toFixed(2) ?? 0,
                    )}
                  />
                </div>
                <div className='flex-1'>
                  <TradeJournalWinningCard
                    title={t('winningPercByDays')}
                    winners={journalSummaryQuery.data?.daysWon ?? 0}
                    losers={journalSummaryQuery.data?.daysLost ?? 0}
                    percentage={Number(
                      journalSummaryQuery.data?.winRateByDay.toFixed(2) ?? 0,
                    )}
                  />
                </div>
              </div>
              <div>
                <TradeJournalNetChart
                  data={journalSummaryQuery.data?.journals}
                />
              </div>
            </div>
            <div className='flex-1'>
              <CustomCalendar
                className='w-100 pt-2'
                data={journalSummaryQuery.data?.journals}
              />
            </div>
          </div>
          {showChooseBrokerageAccount && (
            // TODO: Depending on the callback assign the correct brokerage instead of hard coding it
            <ChooseBrokerageAccount
              visible={showChooseBrokerageAccount}
              onHide={() => {
                setShowChooseBrokerageAccount((s) => !s)
              }}
              accounts={accountsOptions}
              onSubmit={onAddAccount}
            />
          )}
        </>
      )}
    </div>
  )
}

export default TradeJournalPage
