import React, { useEffect, useRef, useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { FixedList } from 'react-recycled-list'
import { useDispatch, useSelector } from 'react-redux'
import { formatDistanceToNowStrict } from 'date-fns'
import { LoadingSpinner } from 'src/core/components'
import { fetchNews } from 'src/core/store/features/news/news'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

import { News } from '../../types'
import NewsDetailsModal from '../newsDetailsModal/newsDetailsModal'

type RenderRow = {
  firstRenderedRowIndex: number
  firstRenderedDataIndex: number
  lastRenderedRowIndex: number
  lastRenderedDataIndex: number
  lastRowIndex: number
}

type NewsTableProps = {
  height: number
  isSmall?: boolean
  isWatchListAndNewsPage?: boolean
}

export const NewsTable = ({
  height,
  isSmall,
  isWatchListAndNewsPage = false,
}: NewsTableProps) => {
  const [t] = useTranslation()

  const dispatch: any = useDispatch()
  const {
    data: news,
    loading: newsLoading,
    isFetchedAll: isFetchedAllNews,
    isFetching: isFetchingNews,
    error,
  } = useSelector((state: RootState) => state.news)

  const [formattedNews, setFormattedNews] = useState<(News | undefined)[]>([])

  const [newsDetails, setNewsDetails] = useState<News | null>(null)

  useEffect(() => {
    if (!news || news.length === 0) {
      return
    }
    if (!isFetchedAllNews) {
      return setFormattedNews([...news, undefined])
    } else {
      setFormattedNews(news)
    }
  }, [news])

  /**
   * Method used to fetch more news while scrolling
   */
  const getNews = async () => {
    const lastNews =
      news && news.length > 0
        ? news[news.length - 1]
          ? news[news.length - 1]
          : news[news.length - 2]
        : undefined

    dispatch(fetchNews({ lastDocumentId: lastNews?.id }))
  }

  /**
   * Method that is triggered whenever the last row in the table is rendered
   */
  const onRenderedRowChange = (renderInfo: RenderRow) => {
    const { lastRenderedRowIndex, lastRowIndex } = renderInfo
    // If the last row is rendered (NOT visible yet!) and we are not already loading data, we fetch new data
    // If you want to fetch data when the last row is visible then use onVisibleRowChange
    if (
      !isFetchingNews &&
      !newsLoading &&
      !isFetchedAllNews &&
      lastRowIndex > 0 &&
      lastRenderedRowIndex === lastRowIndex
    ) {
      getNews()
    }
  }

  const displayNewsDetails = (details: News) => {
    setNewsDetails(details)
  }

  const hideNewsDetails = () => {
    setNewsDetails(null)
  }

  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isWatchListAndNewsPage)
      if (listRef.current)
        listRef.current.parentElement?.classList.add('news-list')
  }, [listRef])

  return newsLoading ? (
    <div
      className={
        isSmall
          ? 'p-3'
          : 'p-3 d-flex d-flex align-items-center justify-content-center'
      }
    >
      <LoadingSpinner />
    </div>
  ) : error ? (
    <Container>
      <Row className='mt-4'>
        <Col sm={12}>
          <Alert variant='danger'>{t('common:loadFailed')}</Alert>
        </Col>
      </Row>
    </Container>
  ) : (
    <>
      <FixedList
        listProps={{
          ref: listRef,
        }}
        height={height}
        rowComponent={(props) => {
          return (
            <NewsRow
              {...props}
              displayNewsDetails={displayNewsDetails}
              isSmall={isSmall}
            />
          )
        }}
        data={formattedNews}
        rowHeight={isSmall ? 100 : 120}
        onRenderedRowChange={onRenderedRowChange}
      />
      {newsDetails && (
        <NewsDetailsModal
          data={newsDetails}
          hideNewsDetails={hideNewsDetails}
        />
      )}
    </>
  )
}

type NewsRowProps = {
  data: News[]
  dataIndex: number
  top: number
  height: number
  displayNewsDetails: Function
  isSmall?: boolean
}

// eslint-disable-next-line
const NewsRow = React.memo((props: NewsRowProps) => {
  // the data here is the same data that is passed into the FixedList
  const { data, dataIndex, top, height, displayNewsDetails, isSmall } = props

  const news = data[dataIndex]

  const getClippedTitle = (title: string, isSmall?: boolean) => {
    const numberOfWords = isSmall ? 18 : 35
    if (title.split(' ').length > numberOfWords) {
      return title.split(' ').slice(0, numberOfWords).join(' ') + '...'
    } else {
      return title
    }
  }

  const getClippedDate = (date: Date) => {
    return formatDistanceToNowStrict(date)
      .replace(/ seconds| second/, 's.')
      .replace(/ minutes| minute/, 'm.')
      .replace(/ hours| hour/, 'h.')
      .replace(/ days| day/, 'd.')
      .replace(/ months| month/, 'mon.')
      .replace(/ years| year/, 'y.')
  }

  // Important!, make sure you inline-style your component with the the provided top, height. Also make sure to set your container element to position absolute
  return (
    <div className={`${isSmall ? 'small' : ''}`}>
      <div
        style={{ top, height, position: 'absolute' }}
        className={`react-recycled-row news-row ${isSmall ? 'px-4' : ''} ${
          isSmall && dataIndex === 0 ? 'pt-3' : ''
        }`}
        onClick={() => displayNewsDetails(news)}
      >
        {news === undefined ? (
          <div className='h-100 d-flex align-items-center justify-content-center'>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className='d-flex align-items-center mb-2'>
              <p className='title me-2'>{news.source_name}</p>
              {news.tickers && news.tickers.length > 0 && (
                <>
                  <p
                    className={`ticker ${
                      news.sentiment === 'Negative' ? 'danger' : ''
                    } me-1`}
                  >
                    {isSmall ? news.tickers[0] : news.tickers.join(', ')}{' '}
                    {(Math.floor(dataIndex % 10) + 0.25).toFixed(2)}%
                  </p>
                  {news.sentiment === 'Negative' ? (
                    <FaCaretDown size={18} className='caret danger' />
                  ) : (
                    <FaCaretUp size={18} className='caret success' />
                  )}
                </>
              )}
              <p className='time ms-1'>
                {getClippedDate(new Date(news.date.seconds * 1000))}
              </p>
            </div>
            <p className='desc'>{getClippedTitle(news.text, isSmall)}</p>
          </>
        )}
      </div>
    </div>
  )
})
