import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { TradeJournal } from 'src/core/store/features/tradeJournal/types'
import { currencyFormatter } from 'src/core/utils/number'

import './styles.scss'

import DailyStatsDetailsModal from '../dailyStatsDetailsModal/DailyStatsDetailsModal'

export const TransactionsTable = ({
  transactions,
  tradeJournalPath,
}: {
  transactions: TradeJournal['transactions']
  tradeJournalPath: string
}) => {
  const [t] = useTranslation('dailyStats')
  const [activeTransaction, setActiveTransaction] =
    useState<TradeJournal['transactions'][number]>()

  // This useEffect updates the active transaction with Trade Journal transactions data changes
  useEffect(() => {
    const _activeTransaction = transactions.find(
      (transaction) =>
        transaction.transactionID === activeTransaction?.transactionID,
    )
    if (_activeTransaction) setActiveTransaction(_activeTransaction)
  }, [transactions])

  const columns = [
    {
      title: t('table.openTime'),
      key: 'openTime',
    },
    {
      title: t('table.ticker'),
      key: 'ticker',
    },
    {
      title: t('table.side'),
      key: 'side',
    },
    {
      title: t('table.instrument'),
      key: 'instrument',
    },
    {
      title: t('table.volume'),
      key: 'volume',
    },
    {
      title: t('table.netPL'),
      key: 'netPL',
    },
    {
      title: t('table.netROI'),
      key: 'netROI',
    },
    {
      title: t('table.rrm'),
      key: 'rrm',
    },
  ]

  return (
    <>
      <Table responsive='sm' className='daily-stats-table'>
        <thead id='orders-table-header'>
          <tr>
            {columns.map((_col) => (
              <th key={_col.title}>
                <div className='d-flex align-items-center justify-content-center'>
                  {_col.title}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr
              key={transaction.transactionID}
              onClick={() => {
                setActiveTransaction(transaction)
              }}
            >
              <td>{new Date(transaction.openTime).toLocaleTimeString()}</td>
              <td>{transaction.symbol}</td>
              <td>{transaction.side}</td>
              <td>{transaction.instrument}</td>
              <td>{transaction.volume}</td>
              <td>{currencyFormatter.format(transaction.netPL)}</td>
              <td>{transaction.netROI.toFixed(2)}%</td>
              <td>-</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {activeTransaction && (
        <DailyStatsDetailsModal
          tradeJournalPath={tradeJournalPath}
          transaction={activeTransaction}
          hideDetails={() => {
            setActiveTransaction(undefined)
          }}
        />
      )}
    </>
  )
}
