import cls from 'classnames'

import './styles.scss'

import { OrderContract } from '../../types'

type ContractProps = {
  orderContract: OrderContract
}

export const Contract = ({ orderContract }: ContractProps) => {
  return (
    <span
      className={cls('order-contract', String(orderContract).toLowerCase())}
    >
      {orderContract}
    </span>
  )
}
