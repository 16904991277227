// TODO: Merge these functions into one formatter
export const megafiy = (num: number): string => {
  return `${Math.floor((num * 100) / 1000000) / 100}M`
}

export const billionfy = (num: number): string => {
  return `${Math.floor((num * 100) / 1000000000) / 100}B`
}

export const currencyFormatter = Intl.NumberFormat('en', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  style: 'currency',
  currency: 'USD',
})
