import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdMarkEmailUnread } from 'react-icons/md'
import { InfoBanner } from 'src/core/components'
import { AuthService } from 'src/core/services'
import { displaySuccessMessage } from 'src/core/utils/alerts/ToastAlerts'

const VerifyEmailBanner = () => {
  const [t] = useTranslation()
  const [loading, setLoading] = useState(false)

  const verifyUserEmail = () => {
    setLoading(true)
    AuthService.verifyEmail().finally(() => {
      displaySuccessMessage(t('common:messages.emailSent'))
      setLoading(false)
    })
  }

  return (
    <div>
      <InfoBanner
        icon={<MdMarkEmailUnread />}
        message={t('common:actions.verifyEmail')}
        actionTitle={t('common:actions.resend')}
        onClick={verifyUserEmail}
        loading={loading}
      />
    </div>
  )
}

export default VerifyEmailBanner
