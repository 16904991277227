import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle } from 'react-icons/ai'
import TDIcon from 'src/assets/images/trade-journal/connections/td-icon.svg'
import WebullIcon from 'src/assets/images/trade-journal/connections/webull-icon.svg'
import { DefaultButton } from 'src/core/components'
import DefaultModal from 'src/core/components/modals/DefaultModal/DefaultModal'
import { BrokerageAccount } from 'src/core/services/firebase/cloudFunctions/tradeJournal/TJCloudFunctions'

import './styles.scss'

interface Props {
  visible: any
  onHide: any
  accounts: BrokerageAccount[]
  brokerage?: string
  onSubmit: Function
  isMulti?: boolean
}

const ChooseBrokerageAccount = ({
  visible,
  onHide,
  accounts,
  brokerage,
  onSubmit,
  isMulti = true,
}: Props) => {
  const [t] = useTranslation('tradeJournal')
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const onSelectAccount = (account: BrokerageAccount) => {
    if (!isMulti) {
      setSelectedAccounts([account.id])
      return
    }
    setSelectedAccounts((accountIds) => {
      let cloneAccountIds = [...accountIds]
      const isExists = cloneAccountIds.indexOf(account.id) > -1
      if (isExists) {
        cloneAccountIds = cloneAccountIds.filter((a) => a !== account.id)
      } else {
        cloneAccountIds.push(account.id)
      }
      return cloneAccountIds
    })
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await onSubmit(selectedAccounts, brokerage)
      setLoading(false)
      onHide()
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <DefaultModal
      visible={visible}
      onHide={onHide}
      title={t('chooseConnection.title')}
    >
      <div className='choose-brokerage-account-modal'>
        <div className='mt-3'>
          {accounts.map((account, index) => (
            <AccountOption
              onClick={() => {
                onSelectAccount(account)
              }}
              key={account.id}
              account={account}
              hideSeperator={index === accounts.length - 1}
              isSelected={selectedAccounts.includes(account.id)}
            />
          ))}
        </div>
        <div className='d-flex align-items-center justify-content-end mt-5'>
          <DefaultButton
            className='px-4 w-fit-content me-3'
            variant='secondary'
            title={t('chooseConnection.cancel')}
            onClick={onHide}
          />
          <DefaultButton
            className='px-4 w-fit-content'
            type='submit'
            title={t('chooseConnection.continue')}
            disabled={selectedAccounts.length === 0}
            loading={loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </DefaultModal>
  )
}

interface AccountOptionProps {
  account: BrokerageAccount
  hideSeperator: boolean
  isSelected: boolean
  onClick: () => void
}

const AccountOption = ({
  account,
  hideSeperator,
  isSelected,
  onClick,
}: AccountOptionProps) => {
  const getIcon = (brokerage: string | undefined) => {
    if (brokerage === 'td_ameritrade') {
      return TDIcon
    } else if (brokerage === 'webull') {
      return WebullIcon
    } else {
      return undefined
    }
  }
  return (
    <>
      <div
        className='account-option d-flex align-items-center justify-content-between'
        onClick={onClick}
      >
        <div className='d-flex align-items-center'>
          {getIcon(account.brokerage) && (
            <>
              <img src={getIcon(account.brokerage)} className='me-3' />
              <p>{account.name}</p>
            </>
          )}
        </div>
        {isSelected && <AiFillCheckCircle className='icon' size={20} />}
      </div>
      {!hideSeperator && <hr className='account-option-seperator' />}
    </>
  )
}

export default ChooseBrokerageAccount
