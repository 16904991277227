import { FaSpinner } from 'react-icons/fa'

import './styles.scss'

const Loading = () => {
  return (
    <p className='loading-spinner-centered-component'>
      <FaSpinner className='spinner' />
    </p>
  )
}

export default Loading
