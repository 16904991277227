import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultButton } from 'src/core/components'
import FileInput from 'src/core/components/inputs/fileInput/FileInput'
import DefaultModal from 'src/core/components/modals/DefaultModal/DefaultModal'
import {
  useGetBrokeragesConnectedQuery,
  useUploadCSVMutation,
} from 'src/core/store/features/tradeJournal/api'
import { displaySuccessMessage } from 'src/core/utils/alerts/ToastAlerts'

// import { ValueSelector } from 'src/modules/orderFlow/components/valueSelector/ValueSelector'
import './styles.scss'

interface Props {
  visible: any
  onHide: any
  brokerage: string
}

// TODO: Add other components in the design. I just don't see a use for it right now because they are included in the cloud function parameters
const AddBrokerageCSV = ({ visible, onHide, brokerage }: Props) => {
  const [file, setFile] = useState<File>()
  const [upload, { isLoading: isUploading }] = useUploadCSVMutation()

  const { refetch } = useGetBrokeragesConnectedQuery()

  const [t] = useTranslation('tradeJournal')

  return (
    <DefaultModal
      visible={visible}
      onHide={onHide}
      title={t('addCSV.title')}
      DescriptionNode={
        <div className='add-brokerage-csv-modal-header mb-0 mt-3'>
          <p className='header-desc'>{t('addCSV.desc')}</p>
        </div>
      }
    >
      <div className='add-brokerage-csv-modal'>
        {/* <div>
          <p className='title'>{t('addCSV.account')}</p>
          <ValueSelector options={options} placeholder='My Trades' />
        </div> */}
        <div className='mb-4 mt-3'>
          <FileInput
            file={file}
            onFileChange={setFile}
            isUploading={isUploading}
          />
        </div>
        <div className='d-flex align-items-center justify-content-end mt-5'>
          <DefaultButton
            className='px-4 w-fit-content me-3'
            variant='secondary'
            title={t('addCSV.cancel')}
            onClick={onHide}
          />
          <DefaultButton
            className='px-4 w-fit-content'
            type='submit'
            title={t('addCSV.save')}
            disabled={!file && isUploading}
            onClick={() =>
              upload({ file: file!, brokerage })
                .unwrap()
                .then(() => {
                  displaySuccessMessage('CSV uploaded successfully')
                  onHide()
                  refetch()
                })
            }
            loading={isUploading}
          />
        </div>
      </div>
    </DefaultModal>
  )
}

export default AddBrokerageCSV
