export type News = {
  id: string
  date: { seconds: number; nanoseconds: number }
  image_url: string
  news_url: string
  sentiment: NewsSentiment
  source_name: string
  text: string
  tickers: string[]
  title: string
  topics: string[]
  type: string
}

export type NewsSentiment = 'Positive' | 'Negative'

export const NEWS_PER_SCROLL = 1000
export const NEWS_SUBSCRIPTION_COUNT = 50
