import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { DefaultText, InfoText } from 'src/core/components'
import DefaultModal from 'src/core/components/modals/DefaultModal/DefaultModal'

import { News } from '../../types'

interface Props {
  data: News
  hideNewsDetails: any
}

const NewsDetailsModal = ({ data, hideNewsDetails }: Props) => {
  const [t] = useTranslation('news')
  const keySize = 'sm'
  const valueSize = 'sm'

  return (
    <DefaultModal
      visible={!!data}
      onHide={hideNewsDetails}
      title={data.source_name}
    >
      <div id='news-details-modal-component'>
        <Row>
          <Col md={6}>
            <InfoText title={t('title')} size={keySize} />
          </Col>
          <Col md={6}>
            <DefaultText title={data.title} size={valueSize} />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col md={6}>
            <InfoText title={t('channels')} size={keySize} />
          </Col>
          <Col md={6}>
            <DefaultText title={data.topics.join(', ')} size={valueSize} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('symbols')} size={keySize} />
          </Col>
          <Col md={6}>
            <DefaultText title={data.tickers.join(', ')} size={valueSize} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('postedOn')} size={keySize} />
          </Col>
          <Col md={6}>
            <DefaultText
              title={format(
                new Date(data.date.seconds * 1000),
                `p 'on' LLLL do',' y`,
              )}
              size={valueSize}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('text')} size={keySize} />
          </Col>
          <Col md={6}>
            <DefaultText title={data.text} size={valueSize} />
          </Col>
        </Row>
      </div>
    </DefaultModal>
  )
}

export default NewsDetailsModal
