import { collection, doc, getDocs, onSnapshot, query } from 'firebase/firestore'
import { FireStoreDB, reportError } from 'src/core/services'

export type DailySentiment = {
  id: string
  bearish: number
  bullish: number
  call: number
  callPremium: number
  callVolume: number
  neutral: number
  put: number
  putCallPremiumRatio: number
  putCallRatio: number
  putCallVolumeRatio: number
  putPremium: number
  putVolume: number
  sentiment: string
}

class DailySentiments {
  COLLECTION_NAME = 'dailySentiment'

  /**
   * getSentiment
   * Get Sentiments data for a given date
   * @param { Function } onValue Callback function on getting realtime data change
   * @returns
   */
  async retreiveSentiments(
    onValue: (data: DailySentiment | null, error?: any) => void,
  ) {
    try {
      const documentDate = await this.getLastDate()
      const docRef = doc(FireStoreDB, this.COLLECTION_NAME, documentDate)
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          onValue(docSnap.data() as DailySentiment)
        } else {
          onValue(null, new Error('load_failed'))
        }
      })
      return unsubscribe
    } catch (e) {
      reportError(e)
      onValue(
        null,
        (e as Error)?.message ? (e as Error)?.message : JSON.stringify(e),
      )
    }
  }

  async getLastDate(): Promise<string> {
    try {
      let result = ''
      const q = query(collection(FireStoreDB, this.COLLECTION_NAME))
      const querySnapshot = await getDocs(q)

      querySnapshot.forEach((dailySenti) => {
        // return last document date exist
        result = dailySenti.id
      })
      return result
    } catch (error) {
      reportError(error)
      return ''
    }
  }
}

export default new DailySentiments()
