import { ReactNode, useContext } from 'react'
import { Modal, Row } from 'react-bootstrap'
import { AiFillCloseCircle } from 'react-icons/ai'
import cls from 'classnames'
import { SectionTitle } from 'src/core/components'
import { ThemeContext } from 'src/core/utils/theme/ThemeContext'

import './styles.scss'

interface Props {
  visible?: boolean
  title?: string
  variant?: 'default' | 'secondary'
  titleVariant?: 'default' | 'secondary'
  onHide?: any
  size?: 'sm' | 'lg' | 'xl'
  children?: ReactNode
  DescriptionNode?: ReactNode
  bodyRef?: any
}

const DefaultModal = ({
  visible,
  title,
  variant = 'default',
  titleVariant = 'default',
  onHide,
  size = 'lg',
  children,
  DescriptionNode,
  bodyRef,
}: Props) => {
  const { theme } = useContext(ThemeContext)

  return (
    <Modal
      id='default-modal-component'
      className={cls(`theme-${theme}`)}
      show={visible}
      size={size}
      onHide={onHide}
      bac
    >
      <Modal.Body ref={bodyRef}>
        <Row className='header'>
          <div className='d-flex align-items-start justify-content-between'>
            <div>
              <SectionTitle
                size='lg'
                title={title ?? ''}
                variant={titleVariant}
              />
              {DescriptionNode}
            </div>
            {onHide && (
              <AiFillCloseCircle onClick={onHide} className='close-icon' />
            )}
          </div>
        </Row>
        <hr className={variant} />
        <div className='content'>{children}</div>
      </Modal.Body>
    </Modal>
  )
}

export default DefaultModal
