export type CardType = 'error' | 'success' | 'info' | 'secondary' | 'neutral'

export type FlowSentiment = {
  title: string
  sentiment?: string
  content: string
  value?: number
  type?: CardType
}

export type SortDir = 'asc' | 'desc' | 'none'
export type OrderTicker = 'SPX' | 'SPY' | 'AAPL'
export type OrderContract = 'CALL' | 'PUT'
export type OrderType = 'Sweep' | 'Block' | 'SPLIT' | 'LARGE' | 'UnusualSweep'
export type OrderSentiment = 'Bullish'

export type Order = {
  id: string
  ask: number
  bid: number
  contract: OrderContract
  contractPrice: number
  delta: number
  details: string
  expirationDate: {
    seconds: number
    nanoSeconds: number
  }
  gamma: number
  impliedVolatility: number
  isGoldenSweep: boolean
  openInterest: number
  premium: number
  sector: string
  sentiment: OrderSentiment
  spot: number
  strikePrice: number
  theta: number
  ticker: OrderTicker
  time: number
  totalSize: number
  type: OrderType
  underlyingPrice: number
  vega: number
  volume: number
}

export type OrderKey = keyof Order

export type TableColumn = {
  title: string
  key: string
  sortable: boolean
  sortDir?: SortDir
}

export type OperatorType =
  | 'is'
  | 'is_not'
  | 'is_greater_than_or_equal_to'
  | 'is_less_than_or_equal_to'
  | 'is_less_than'
  | 'is_greater_than'

export type TOperator = {
  title: string
  value: string
  type?: OperatorType
}

export type TOption = {
  label: string
  value: string
}

export type TFilterCondition = {
  operator: TOperator | null
  value: TOption | Date | number | string | null | boolean
}

export const ORDERS_PER_SCROLL = 1000
export const ORDERS_SUBSCRIPTION_COUNT = 50
