export const RefundPolicy = () => {
  return (
    <>
      <h1>Refund Policy</h1>
      <p>
        ***** IF YOU DO NOT AGREE TO THESE "TERMS OF SERVICE", PLEASE DO NOT USE
        THE SITE.****
      </p>
      <p>
        Capitalized terms not defined herein shall have the meaning given to
        them in the Boom Stocks Terms of Service.
      </p>
      <p>
        1. Since services are nontangible, irrevocable digital goods, we do not
        issue refunds. By accepting the Terms of Service and using the Site, You
        hereby acknowledge and agree that no refunds of purchases shall be
        issued. Upon clicking the final submit button during checkout, your
        purchase is charged to the credit/debit card you supplied. All sales are
        final.
      </p>
      <p>
        2. You cannot cancel a purchase or receive a refund for a purchase. You
        can cancel future recurring subscription payments at any time prior to
        the recurring renewal of the account by cancellation through your
        profile settings page. No refunds will be issued for partial billing
        periods. Any cancellation will be effective as of the next recurring
        renewal.
      </p>
      <p>
        3. If you are under a free trial period, you will be charged at the end
        of your trial unless cancelled prior to the completion of the trial
        period. We do not provide refunds. All sales are final. It is your
        responsibility to manage your subscription accordingly.
      </p>
      <p>
        All plans auto-renew. If you do not wish to renew, you must cancel 24
        hours prior to your renewal date.
      </p>
      <p>
        4. You are entitled to one (1) trial period- this may be paid or unpaid
        depending on the promotion. If it is found that You have attempted to
        create multiple trial periods, your account will be cancelled and banned
        without notice. Users who severely breach these terms on a continued
        basis may also have their account set to active.
      </p>
      <p>
        5. If it is found that you are, or have been, in breach of our Terms of
        Service in any way including, but not limited to, scrapping,
        reproducing, or distributing our data, your account will be banned, and
        no refund will be issued if your subscription is active.
      </p>
      <p>
        LEGAL ACTION WILL BE TAKEN WHERE DEEMED APPROPRIATE IF IT IS FOUND THAT
        YOU ARE REDISTRIBUTING OR RESELLING THE INFORMATION ON THE SITE.
      </p>
    </>
  )
}
