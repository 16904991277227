import i18next from 'i18next'

import authEn from './en/auth.json'
import commonEn from './en/common.json'
import ContactUsEn from './en/contactUs.json'
import DailyStatsEn from './en/dailyStats.json'
import dashboardEn from './en/dashboard.json'
import landingEn from './en/landing.json'
import NewsFlowEn from './en/news.json'
import NotificationsEn from './en/notifications.json'
import orderFlowEn from './en/orderFlow.json'
import settingsEn from './en/settings.json'
import TradeJournalEn from './en/tradeJournal.json'
import WatchListEn from './en/watchlist.json'

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      common: commonEn,
      auth: authEn,
      landing: landingEn,
      settings: settingsEn,
      orderFlow: orderFlowEn,
      dashboard: dashboardEn,
      news: NewsFlowEn,
      watchlist: WatchListEn,
      notifications: NotificationsEn,
      contactUs: ContactUsEn,
      tradeJournal: TradeJournalEn,
      dailyStats: DailyStatsEn,
    },
  },
})
