import { useMediaQuery } from 'react-responsive'
import PlatformDemo from 'src/assets/images/landing/PlatformDemo.png'
import PlatformDemoMobile from 'src/assets/images/landing/PlatformDemo_Mobile.png'

import './styles.scss'

import { AppStoreButton } from '../AppStoreButton/AppStoreButton'

export const PlatformCard = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 599px)` })
  return (
    <div className='platform-card'>
      <img src={isMobile ? PlatformDemoMobile : PlatformDemo} alt='' />
      <div
        className={`store-buttons ${
          !isMobile ? 'd-flex' : ''
        } align-items-center justify-content-center`}
      >
        <div className={`${!isMobile ? 'me-3' : 'mb-2'}`}>
          <AppStoreButton type='apple' isFullWidth={isMobile} />
        </div>
        <AppStoreButton type='googlePlay' isFullWidth={isMobile} />
      </div>
    </div>
  )
}
