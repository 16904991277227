import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Loading } from 'src/core/components'
import { AuthService, FirebaseAuth } from 'src/core/services'
import Users from 'src/core/services/firebase/firestore/Users'
import ChoosePlan from 'src/modules/auth/choosePlan/ChoosePlan'
import ForgetPassword from 'src/modules/auth/forgetPassword/ForgetPassword'
import LoginPage from 'src/modules/auth/login/LoginPage'
import RegisterPage from 'src/modules/auth/register/RegisterPage'
import { ContactUs } from 'src/modules/contactUs/ContactUs'
import LandingPage from 'src/modules/landing/LandingPage'
import { PrivacyPolicy } from 'src/modules/legalPages/PrivacyPolicy/PrivacyPolicy'
import { RefundPolicy } from 'src/modules/legalPages/RefundPolicy/RefundPolicy'
import { TermsOfService } from 'src/modules/legalPages/TermsOfService/TermsOfService'

import { RootState } from '../store/Store'
import { PrivateRoutes } from './components/PrivateRoutes/PrivateRoutes'
import { useIntercom } from './hooks/useIntercom'

function AppRoutes() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!AuthService.getCurrentUser())
  const [loading, setLoading] = useState(!AuthService.getCurrentUser())
  const [isSubscribed, setIsSubscribed] = useState(false)

  useIntercom()

  const isSubscribing = useSelector(
    (state: RootState) => state.register.isSubscribing,
  )

  const checkSubscription = async () => {
    const result = await Users.checkSubscription()
    return result
  }

  useEffect(() => {
    if (loading) {
      FirebaseAuth.onAuthStateChanged((user) => {
        if (!isSubscribing) {
          setLoading(true)
          if (user) {
            AuthService.reloadUserData()
            setIsLoggedIn(true)
            checkSubscription().then((result: boolean) => {
              setIsSubscribed(result)
              setLoading(false)
            })
          } else {
            setIsLoggedIn(false)
            setLoading(false)
          }
        }
      })
    }
  }, [])

  if (loading || isSubscribing) {
    return <Loading />
  }

  if (!isLoggedIn) {
    return <AuthRoutes />
  }
  return isSubscribed ? <PrivateRoutes /> : <SubscribeRoutes />
}

const SubscribeRoutes = () => {
  return (
    <Routes>
      <Route>
        <Route path='/choose-plan' element={<ChoosePlan />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-service' element={<TermsOfService />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='*' element={<Navigate to='/choose-plan' replace />} />
      </Route>
    </Routes>
  )
}

const AuthRoutes = () => {
  return (
    <Routes>
      <Route>
        <Route path='/' element={<LandingPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/forget-password' element={<ForgetPassword />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-service' element={<TermsOfService />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
