import { NumberFormatValues, NumericFormat } from 'react-number-format'

import './styles.scss'

type FilterValueInputProps = {
  isPrefixNeeded?: boolean
  defaultValue?: string | number
  onValueChange?: (values: NumberFormatValues) => void
  value?: string | number
}

const FilterValueInput = ({
  isPrefixNeeded = true,
  defaultValue,
  onValueChange,
  value,
}: FilterValueInputProps) => {
  return (
    <NumericFormat
      defaultValue={defaultValue}
      className='filter-value-input'
      displayType='input'
      thousandSeparator=','
      thousandsGroupStyle='thousand'
      placeholder='Enter value...'
      prefix={isPrefixNeeded ? '$' : ''}
      value={value}
      onValueChange={onValueChange}
    />
  )
}

export default FilterValueInput
