import {
  collection,
  getDocs,
  orderBy,
  query,
  QuerySnapshot,
} from 'firebase/firestore'
import {
  TradeJournal,
  TradeTag,
} from 'src/core/store/features/tradeJournal/types'

import { reportError } from '../../errorReporter'
import { FireStoreDB } from '../Firebase'

export const TradeJorunalsService = {
  async getTradeJournals(collectionPath: string) {
    try {
      const querySnapshot = (await getDocs(
        query(collection(FireStoreDB, collectionPath), orderBy('id', 'desc')),
      )) as QuerySnapshot<TradeJournal>

      const transactions = await Promise.all(
        querySnapshot.docs.map((doc) =>
          getDocs(
            query(
              collection(
                FireStoreDB,
                collectionPath,
                doc.id,
                'tradeJournalTransactions',
              ),
              orderBy('transactionDate', 'desc'),
            ),
          ),
        ),
      )

      return {
        data: querySnapshot.docs.map((doc, index) => ({
          ...doc.data(),
          transactions: transactions[index].docs.map(
            (doc) => doc.data() as TradeJournal['transactions'][number],
          ),
        })),
      }
    } catch (e) {
      reportError(e)
      return {
        error: { message: (e as Error)?.message ?? 'An error occured' },
      }
    }
  },
  async getTradeTags() {
    try {
      const querySnapshot = (await getDocs(
        query(collection(FireStoreDB, 'tradeTags'), orderBy('type', 'desc')),
      )) as QuerySnapshot<TradeTag>

      const tags = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
      }))

      return {
        data: {
          strategy: tags.filter((tag) => tag.type === 'Strategy'),
          mistake: tags.filter((tag) => tag.type === 'Mistake'),
        },
      }
    } catch (e) {
      reportError(e)
      return {
        error: { message: (e as Error)?.message ?? 'An error occured' },
      }
    }
  },
}
