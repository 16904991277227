import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flatten, uniqBy } from 'lodash'
import { TopMoversService } from 'src/core/services'
import {
  TOP_MOVERS_PER_SCROLL,
  TOP_MOVERS_SUBSCRIPTION_COUNT,
} from 'src/modules/topMovers/types'

import {
  FetchTopMovers,
  SetSubscribedTopMoversData,
  SetSubscribedTopMoversError,
  TopMoversState,
} from './types'

const initialSlice = {
  error: null,
  data: [],
  loading: true,
  isFetchedAll: false,
  isFetching: false,
}

const initialState: TopMoversState = {
  gainers: initialSlice,
  losers: initialSlice,
}

export const fetchTopMovers = createAsyncThunk(
  'topMovers/fetchTopMovers',
  async ({ type, lastDocumentId }: FetchTopMovers) => {
    const response = await TopMoversService.getTopMovers(type, lastDocumentId)
    return { type, data: response }
  },
)

export const topMoversSlice = createSlice({
  name: 'topMovers',
  initialState,
  reducers: {
    setSubscribedTopMoversData: (
      state,
      action: PayloadAction<SetSubscribedTopMoversData>,
    ) => {
      const { type, data } = action.payload

      if (data.length < TOP_MOVERS_SUBSCRIPTION_COUNT) {
        state[type].isFetchedAll = true
      }

      state[type].data = uniqBy(flatten([data, state[type].data]), 'id')

      state[type].loading = false
    },
    setSubscribedTopMoversError: (
      state,
      action: PayloadAction<SetSubscribedTopMoversError>,
    ) => {
      const { type, error } = action.payload
      state[type].error = error
      state[type].loading = false
    },
    resetTopMovers: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTopMovers.pending, (state, action) => {
      const { type } = action.meta.arg
      state[type].isFetching = true
    })
    builder.addCase(fetchTopMovers.fulfilled, (state, action) => {
      const { type, data } = action.payload

      if (data.length < TOP_MOVERS_PER_SCROLL) {
        state[type].isFetchedAll = true
      }
      const newTopMovers = uniqBy(flatten([state[type].data, data]), 'id') // append the NEWLY ADDED data to the start of the table
      state[type].data = newTopMovers
      state[type].loading = false
      state[type].isFetching = false
    })
    builder.addCase(fetchTopMovers.rejected, (state, action) => {
      const { type } = action.meta.arg
      state[type].error = action.error.message
      state[type].loading = false
      state[type].isFetching = false
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  setSubscribedTopMoversData,
  setSubscribedTopMoversError,
  resetTopMovers,
} = topMoversSlice.actions

export default topMoversSlice.reducer
