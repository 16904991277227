import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { NumberFormatValues } from 'react-number-format'
import { DateInput, FilterValueInput, SectionTitle } from 'src/core/components'

import './styles.scss'

import { TFilterCondition, TOption } from '../../types'
import { ValueSelector } from '../valueSelector/ValueSelector'

type FilterConditionProps = {
  isPrefixNeeded?: boolean
  name: string
  label: string
  valueOptions?: TOption[]
  type?: 'input' | 'select' | 'date' | 'range'
  defaultValue?: string | number
  onChange?: (val: TFilterCondition) => void
  value?: TFilterCondition | null
  initialValue?: TFilterCondition | null
}

export const FilterCondition = ({
  isPrefixNeeded = true,
  label,
  valueOptions = [],
  type = 'select',
  defaultValue,
  value,
  initialValue,
  onChange,
}: FilterConditionProps) => {
  const [selectedVal, setSelectedVal] = useState<
    TOption | Date | number | string | null | boolean
  >(initialValue ? initialValue.value : null)

  const onChangeDate = (date: Date) => {
    setSelectedVal(date)
  }

  const onChangeInput = (values: NumberFormatValues) => {
    setSelectedVal(values.floatValue as number)
  }

  const onChangeSelect = (option: TOption | null) => {
    setSelectedVal(option)
  }

  const onChangeRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedVal(Number(e.target.value))
  }

  useEffect(() => {
    onChange?.({
      operator: {
        title: 'Is',
        value: 'is',
      },
      value: selectedVal,
    })
  }, [selectedVal])

  return (
    <Row className='filter-condition'>
      <Col sm={12} className='filter-condition_label'>
        <SectionTitle size='sm' variant='secondary' title={label} />
      </Col>
      <Col sm={12}>
        {type === 'input' && (
          <FilterValueInput
            isPrefixNeeded={isPrefixNeeded}
            defaultValue={defaultValue}
            onValueChange={onChangeInput}
            value={value?.value ? (value?.value as number) : ''}
          />
        )}
        {type === 'select' && (
          <ValueSelector
            value={value ? (value.value as TOption) : null}
            options={valueOptions}
            onChange={onChangeSelect}
          />
        )}
        {type === 'date' && (
          <DateInput
            value={value ? (value.value as Date) : null}
            onChange={onChangeDate}
          />
        )}
        {type === 'range' && (
          <div className='d-flex align-items-center justify-content-between filter-value-range'>
            <div>{value ? (value.value as number) : 0}</div>
            <Form.Range
              // change color of range progress

              style={{
                color: '#FFC107',
              }}
              className='filter-value-range__input'
              min={0}
              max={12}
              value={value ? (value.value as number) : 0}
              onChange={onChangeRange}
            />
            <div>12</div>
          </div>
        )}
      </Col>
    </Row>
  )
}
