import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore'
import {
  TOP_MOVERS_PER_SCROLL,
  TOP_MOVERS_SUBSCRIPTION_COUNT,
} from 'src/modules/topMovers/types'

import { reportError } from '../../errorReporter'
import { FireStoreDB } from '../Firebase'

class TopMoversService {
  /**
   * subscribeToTopMovers
   * Subscribe to Top Movers sorted by the date
   */
  async subscribeToTopMovers(
    type: 'gainers' | 'losers',
    onValue: (data: any, error?: any) => void,
  ) {
    try {
      const documentDate = await this.getLastDate(type)
      const COLLECTION_NAME = type === 'gainers' ? 'topGainers' : 'topLosers'
      const docRef = query(
        collection(FireStoreDB, `${COLLECTION_NAME}/${documentDate}/topMovers`),
        where('sortOrder', '>', 0),
        where('sortOrder', '<=', 10),
        orderBy('sortOrder', 'asc'),
        limit(TOP_MOVERS_SUBSCRIPTION_COUNT),
      )
      const unsubscribe = onSnapshot(
        docRef,
        (docSnap) => {
          onValue(docSnap.docs.map((_doc) => ({ id: _doc.id, ..._doc.data() })))
        },
        (error) => {
          console.log(error)
        },
      )
      return unsubscribe
    } catch (e) {
      reportError(e)
      onValue(null, e as Error)
      return null
    }
  }

  /**
   * GET Top Movers with pagination (scrolling edition)
   * @param type
   * @param lastDocumentId
   */
  async getTopMovers(type: 'gainers' | 'losers', lastDocumentId?: string) {
    const documentDate = await this.getLastDate(type)
    const COLLECTION_NAME = type === 'gainers' ? 'topGainers' : 'topLosers'
    let docRef = query(
      collection(FireStoreDB, `${COLLECTION_NAME}`),
      where('sortOrder', '>', 0),
      where('sortOrder', '<=', 10),
      orderBy('sortOrder', 'asc'),
      limit(TOP_MOVERS_PER_SCROLL),
    )
    if (lastDocumentId) {
      const lastDocRef = doc(
        FireStoreDB,
        `${COLLECTION_NAME}/${documentDate}/topMovers`,
        lastDocumentId,
      )
      const lastDocumentSnap = await getDoc(lastDocRef)

      docRef = query(
        collection(FireStoreDB, `${COLLECTION_NAME}/${documentDate}/topMovers`),
        orderBy('sortOrder', 'asc'),
        startAfter(lastDocumentSnap),
        limit(TOP_MOVERS_PER_SCROLL),
      )
    }

    const docs = await getDocs(docRef)
    return docs.docs.map((_doc: any) => ({ id: _doc.id, ..._doc.data() }))
  }

  async getLastDate(type: 'gainers' | 'losers'): Promise<string> {
    try {
      const COLLECTION_NAME = type === 'gainers' ? 'topGainers' : 'topLosers'

      let result = ''
      const q = query(collection(FireStoreDB, COLLECTION_NAME))
      const querySnapshot = await getDocs(q)

      querySnapshot.forEach((mover) => {
        // return last document date exist
        result = mover.id
      })
      return result
    } catch (error) {
      reportError(error)
      return ''
    }
  }
}

export default new TopMoversService()
