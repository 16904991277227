import { useTranslation } from 'react-i18next'
import { FaApple, FaGooglePlay } from 'react-icons/fa'

import './styles.scss'

type AppStoreProps = {
  type: string
  isFullWidth?: boolean
}

export const AppStoreButton = ({ type, isFullWidth }: AppStoreProps) => {
  const [t] = useTranslation('landing')

  const title = type === 'apple' ? t('downloadOnThe') : t('getItOn')
  const store = type === 'apple' ? t('appStore') : t('googlePlay')

  const link =
    type === 'apple'
      ? 'https://apps.apple.com/us/app/boom-stocks/id1641581805'
      : 'https://play.google.com/store/apps/details?id=com.BoomStocks.App'

  return (
    <a
      href={link}
      target='_blank'
      rel='noreferrer'
      className={`app-store-button d-flex align-items-center ${
        isFullWidth ? 'w-100' : ''
      }`}
    >
      {type === 'apple' ? (
        <FaApple className='app-store-icon' />
      ) : (
        <FaGooglePlay className='app-store-icon' />
      )}
      <p className='app-store-button-title ms-2'>
        {title} <br /> <span className='store-name'>{store}</span>
      </p>
    </a>
  )
}
