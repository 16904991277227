import React, { useEffect, useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LoadingSpinner } from 'src/core/components'
import { RootState } from 'src/core/store/Store'

import { FlowSentiment } from '../../types'
import { ChartCard } from '../chartCard/ChartCard'

const formatter = Intl.NumberFormat('en', {
  notation: 'compact',
  maximumFractionDigits: 2,
})

export const SentimentsCards = () => {
  const [t] = useTranslation('orderFlow')

  const {
    data: sentiment,
    loading: loadingSentiment,
    error,
  } = useSelector((state: RootState) => state.sentiment)

  const [flowSentiments, setFlowSentiments] = useState<Array<FlowSentiment>>([])

  useEffect(() => {
    if (sentiment) {
      const putCallRatio = Math.round(sentiment.putCallRatio * 100 * 100) / 100
      const putCallPremiumRatio =
        Math.round(sentiment.putCallPremiumRatio * 100 * 100) / 100
      const putCallVolumeRatio =
        Math.round(sentiment.putCallVolumeRatio * 100 * 100) / 100

      setFlowSentiments(() => [
        {
          title: t('marketSentiment'),
          sentiment: sentiment.sentiment,
          content:
            sentiment.sentiment === 'neutral'
              ? t('neutral')
              : sentiment.sentiment === 'bearish'
              ? t('bearish')
              : t('bullish'),
          type:
            sentiment.sentiment === 'neutral'
              ? 'neutral'
              : sentiment.sentiment === 'bearish'
              ? 'error'
              : 'success',
        },
        {
          title: t('putCallRatio'),
          sentiment: sentiment.sentiment,
          content: `${putCallRatio}%`,
          type: 'info',
          value: putCallRatio,
        },
        {
          title: t('putCallVolume'),
          sentiment: sentiment.sentiment,
          content: `${formatter.format(
            sentiment?.putVolume,
          )} / ${formatter.format(sentiment?.callVolume)}`,
          type: 'error',
          value: putCallVolumeRatio,
        },
        {
          title: t('putCallPremium'),
          sentiment: sentiment.sentiment,
          content: `$${formatter.format(
            sentiment?.putPremium,
          )} / $${formatter.format(sentiment?.callPremium)}`,
          type: 'success',
          value: putCallPremiumRatio,
        },
      ])
    }
  }, [sentiment])

  if (error) {
    return (
      <Container>
        <Row className='mt-4'>
          <Col sm={12}>
            <Alert variant='danger'>{t('common:loadFailed')}</Alert>
          </Col>
        </Row>
      </Container>
    )
  }

  if (loadingSentiment) {
    return <LoadingSpinner />
  }

  return (
    <>
      {flowSentiments.map((_sentiment) => (
        <Col key={_sentiment.title} lg={3} md={6} sm={6}>
          <ChartCard sentiment={_sentiment} />
        </Col>
      ))}
    </>
  )
}
