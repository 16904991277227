import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Loading } from 'src/core/components'
import PageLayout from 'src/core/components/layout/pageLayout/PageLayout'
import Tickers from 'src/core/services/firebase/firestore/Tickers'
import Users from 'src/core/services/firebase/firestore/Users'
import { setTickers } from 'src/core/store/features/filters/filters'
import { useGetBrokeragesConnectedQuery } from 'src/core/store/features/tradeJournal/api'
import {
  setActiveSubscribtion,
  setUserDetails,
} from 'src/core/store/features/user/userDetails'
import SuccessPage from 'src/modules/alerts/success/SuccessPage'
import FormAgreement from 'src/modules/auth/formAgreement/FormAgreement'
import DailyStats from 'src/modules/dailyStats/DailyStats'
import DashboardPage from 'src/modules/dashboard/DashboardPage'
import { PrivacyPolicy } from 'src/modules/legalPages/PrivacyPolicy/PrivacyPolicy'
import { RefundPolicy } from 'src/modules/legalPages/RefundPolicy/RefundPolicy'
import { TermsOfService } from 'src/modules/legalPages/TermsOfService/TermsOfService'
import { useSubNews } from 'src/modules/news/components/useSubNews/useSubNews'
import NewsPage from 'src/modules/news/NewsPage'
import { useSubNotifications } from 'src/modules/notifications/components/useSubNotifications/useSubNotifications'
import NotificationsPage from 'src/modules/notifications/NotificationsPage'
import OptionHeatMapPage from 'src/modules/optionHeatMap/OptionHeatMapPage'
import { useSubOrders } from 'src/modules/orderFlow/components/useSubOrders/useSubOrders'
import { useSubSentiments } from 'src/modules/orderFlow/components/useSubSentiments/useSubSentiments'
import OrderFlowPage from 'src/modules/orderFlow/OrderFlowPage'
import { Reports } from 'src/modules/reports/Reports'
import SettingsPage from 'src/modules/settings/SettingsPage'
import { useSubTopMovers } from 'src/modules/topMovers/components/useSubTopMovers/useSubTopMovers'
import TradeJournalPage from 'src/modules/tradeJournal/TradeJournalPage'
import { useSubWatchlist } from 'src/modules/watchList/components/useSubWatchlist/useSubWatchlist'
import WatchListPage from 'src/modules/watchList/WatchListPage'

export const PrivateRoutes = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [isSigned, setIsSigned] = useState(false)
  const brokeragesConnectedQuery = useGetBrokeragesConnectedQuery()

  useSubNews()
  useSubNotifications('tsNewsLetters')
  useSubNotifications('tsTechnicalAnalysis')
  const { subscribeOrders, unsubscribeOrders } = useSubOrders()
  useSubTopMovers('gainers')
  useSubTopMovers('losers')
  useSubWatchlist()
  useSubSentiments()

  useEffect(() => {
    const fetchTickers = async () => {
      const tickers = await Tickers.getTickers()
      dispatch(setTickers({ tickers }))
    }
    fetchTickers()
  }, [])

  useEffect(() => {
    subscribeOrders(null)
    return () => {
      if (unsubscribeOrders) {
        unsubscribeOrders()
      }
    }
  }, [])

  useEffect(() => {
    Users.getUserDetails().then((userData) => {
      if (userData) {
        dispatch(setUserDetails(userData))
        setLoading(false)
        setIsSigned(!!userData.settings?.isOpraAgreementSigned)
        Users.getActiveSubscribtion().then((activeSubscribtion) => {
          if (activeSubscribtion) {
            dispatch(setActiveSubscribtion(activeSubscribtion))
          }
        })
      }
    })
  }, [])

  if (
    loading ||
    brokeragesConnectedQuery.isLoading ||
    brokeragesConnectedQuery.isUninitialized
  ) {
    return <Loading />
  }

  return (
    <PageLayout>
      {!isSigned ? (
        <Routes>
          <Route path='/form-agreement' element={<FormAgreement />} />
          <Route path='*' element={<Navigate to='/form-agreement' replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            path='/dashboard/notifications'
            element={<NotificationsPage />}
          />
          <Route path='/dashboard' element={<DashboardPage />} />
          <Route path='/dashboard' element={<DashboardPage />} />
          <Route path='/trade-journal' element={<TradeJournalPage />} />
          <Route path='/daily-stats' element={<DailyStats />} />
          <Route path='/reports' element={<Reports />} />
          <Route path='/option-heat-map' element={<OptionHeatMapPage />} />
          <Route path='/watch-list' element={<WatchListPage />} />
          <Route path='/news' element={<NewsPage />} />
          <Route path='/settings' element={<SettingsPage />} />
          <Route path='/success' element={<SuccessPage />} />
          <Route path='/order-flow' element={<OrderFlowPage />} />
          <Route path='*' element={<Navigate to='/dashboard' replace />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-of-service' element={<TermsOfService />} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
        </Routes>
      )}
    </PageLayout>
  )
}
