import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  SentimentsState,
  SetSubscribedSentimentsData,
  SetSubscribedSentimentsError,
} from './types'

const initialState: SentimentsState = {
  error: null,
  data: null,
  loading: true,
}

export const sentimentsSlice = createSlice({
  name: 'sentiments',
  initialState,
  reducers: {
    setSubscribedSentimentsData: (
      state,
      action: PayloadAction<SetSubscribedSentimentsData>,
    ) => {
      const { data } = action.payload

      state.data = data

      state.loading = false
    },
    setSubscribedSentimentsError: (
      state,
      action: PayloadAction<SetSubscribedSentimentsError>,
    ) => {
      const { error } = action.payload
      state.error = error
      state.loading = false
    },
    resetSentiments: () => {
      return initialState
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setSubscribedSentimentsData,
  setSubscribedSentimentsError,
  resetSentiments,
} = sentimentsSlice.actions

export default sentimentsSlice.reducer
