import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FaKey, FaUserCircle } from 'react-icons/fa'
import { MdPayments } from 'react-icons/md'
import { DefaultTabs, PageHeader } from 'src/core/components'

import Billing from './components/billing/Billing'
import AccountDelete from './components/deletion/AccountDelete'
import General from './components/general/General'
import Security from './components/security/Security'

const SettingsPage = () => {
  const [t] = useTranslation()

  return (
    <div>
      <PageHeader title={t('common:modules.settings')} />
      <Container>
        <Row>
          <Col md={12}>
            <DefaultTabs
              tabs={[
                {
                  key: 'general',
                  title: t('settings:general'),
                  icon: <FaUserCircle />,
                  component: <General />,
                },
                {
                  key: 'security',
                  title: t('settings:security'),
                  icon: <FaKey />,
                  component: <Security />,
                },
                {
                  key: 'billing',
                  title: t('settings:billing'),
                  icon: <MdPayments />,
                  component: <Billing />,
                },
                {
                  key: 'deletion',
                  title: t('settings:accountDelete'),
                  icon: <AiFillCloseCircle />,
                  component: <AccountDelete />,
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SettingsPage
