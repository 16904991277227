import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Unsubscribe } from 'firebase/auth'
import { NewsService } from 'src/core/services'
import {
  setSubscribedNewsData,
  setSubscribedNewsError,
} from 'src/core/store/features/news/news'

import { News } from '../../types'

export const useSubNews = () => {
  const dispatch = useDispatch()
  const unsubscribeNewsRef = useRef<Unsubscribe | null>()

  useEffect(() => {
    subscribeNews()
    return () => {
      if (unsubscribeNewsRef.current) {
        unsubscribeNewsRef?.current()
      }
    }
  }, [])

  /**
   * Method used to get real-time news. It keeps updating the last {NEWS_SUBSCRIPTION_COUNT} records added in the database
   */
  const subscribeNews = useCallback(async () => {
    // delete any subscribtion before getting new data
    if (unsubscribeNewsRef.current) {
      unsubscribeNewsRef?.current()
    }
    unsubscribeNewsRef.current = await NewsService.subscribeToNews(
      (data: News[], error: any) => {
        if (!error) {
          dispatch(setSubscribedNewsData({ data }))
        } else {
          dispatch(setSubscribedNewsError({ error }))
        }
      },
    )
  }, [])

  return {}
}
