import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiLinkExternal } from 'react-icons/bi'
import { format } from 'date-fns'
import USAFlag from 'src/assets/images/watchlist/usa-flag.png'
import { LoadingSpinner } from 'src/core/components'
import DefaultModal from 'src/core/components/modals/DefaultModal/DefaultModal'
import { ChartCard } from 'src/modules/orderFlow/components'
import { FlowSentiment } from 'src/modules/orderFlow/types'

import './styles.scss'

import { WatchList } from '../../types'
import { WatchListChart } from '../watchListChart/WatchListChart'

const formatter = Intl.NumberFormat('en', {
  maximumFractionDigits: 2,
})

const compactFormatter = Intl.NumberFormat('en', {
  notation: 'compact',
  maximumFractionDigits: 2,
})

const numberOfWords = 30

interface Props {
  data: WatchList | undefined
  hideWatchlistDetails: any
}

const WatchlistDetailsModal = ({ data, hideWatchlistDetails }: Props) => {
  const [t] = useTranslation('')
  const [isShowMoreDesc, setIsShowMoreDesc] = useState(false)
  const [flowSentiments, setFlowSentiments] = useState<Array<FlowSentiment>>([])

  const getClippedTitle = useCallback(
    (title: string | undefined) => {
      if (title === undefined) {
        return '-'
      }
      if (isShowMoreDesc) {
        return title
      }
      if (title.split(' ').length > numberOfWords) {
        return title.split(' ').slice(0, numberOfWords).join(' ')
      } else {
        return title
      }
    },
    [isShowMoreDesc],
  )

  useEffect(() => {
    const sentiment = data?.dailySentiment
    if (
      sentiment &&
      sentiment.putCallRatio &&
      sentiment.putCallPremiumRatio &&
      sentiment.putCallVolumeRatio
    ) {
      const putCallRatio = Math.round(sentiment.putCallRatio * 100 * 100) / 100
      const putCallPremiumRatio =
        Math.round(sentiment.putCallPremiumRatio * 100 * 100) / 100
      const putCallVolumeRatio =
        Math.round(sentiment.putCallVolumeRatio * 100 * 100) / 100

      setFlowSentiments(() => [
        {
          title: t('orderFlow:marketSentiment'),
          sentiment: sentiment.sentiment,
          content:
            sentiment.sentiment === 'neutral'
              ? t('orderFlow:neutral')
              : sentiment.sentiment === 'bearish'
              ? t('orderFlow:bearish')
              : t('orderFlow:bullish'),
          type:
            sentiment.sentiment === 'neutral'
              ? 'neutral'
              : sentiment.sentiment === 'bearish'
              ? 'error'
              : 'success',
        },
        {
          title: t('orderFlow:putCallRatio'),
          sentiment: sentiment.sentiment,
          content: `${putCallRatio}%`,
          type: 'info',
          value: putCallRatio,
        },
        {
          title: t('orderFlow:putCallVolume'),
          sentiment: sentiment.sentiment,
          content: `${compactFormatter.format(
            sentiment?.putVolume,
          )} / ${compactFormatter.format(sentiment?.callVolume)}`,
          type: 'error',
          value: putCallVolumeRatio,
        },
        {
          title: t('orderFlow:putCallPremium'),
          sentiment: sentiment.sentiment,
          content: `$${compactFormatter.format(
            sentiment?.putPremium,
          )} / $${compactFormatter.format(sentiment?.callPremium)}`,
          type: 'success',
          value: putCallPremiumRatio,
        },
      ])
    }
  }, [data?.dailySentiment])

  if (data === undefined) {
    return (
      <DefaultModal onHide={hideWatchlistDetails}>
        <LoadingSpinner />
      </DefaultModal>
    )
  }

  const getPriceDiff = (
    previousPrice: number | undefined,
    currentPrice: number | undefined,
  ) => {
    currentPrice = currentPrice ?? 0
    previousPrice = previousPrice ?? currentPrice

    return currentPrice - previousPrice
  }

  const getPriceDiffPercentage = (
    previousPrice: number | undefined,
    currentPrice: number | undefined,
  ) => {
    currentPrice = currentPrice ?? 0
    previousPrice = previousPrice ?? currentPrice

    const diff = getPriceDiff(previousPrice, currentPrice)

    return previousPrice ? (diff / previousPrice) * 100 : 0
  }

  const getEarningsDate = () => {
    if (
      !data.ticker?.financials ||
      !data.ticker?.financials.earningsTimestamp
    ) {
      return '-'
    }
    return format(
      new Date(data.ticker?.financials.earningsTimestamp * 1000),
      'MMMM d',
    )
  }

  const isShowMoreText =
    !isShowMoreDesc &&
    data.ticker?.description &&
    data.ticker?.description.split(' ').length > numberOfWords

  return (
    <DefaultModal
      size='xl'
      visible={!!data}
      onHide={hideWatchlistDetails}
      title={data.ticker?.companyName ? data.ticker?.companyName : data.name}
      DescriptionNode={
        <p className='watchlist-desc mt-1'>
          {data.ticker?.symbol}
          {data.ticker?.country === 'United States of America' && (
            <img src={USAFlag} alt='' height={24} width={24} className='mx-2' />
          )}
          {data.ticker?.exchange}
        </p>
      }
    >
      <div id='watchlist-details-modal-component'>
        <p className='last-price-title'>
          {data.ticker?.lastPrice
            ? formatter.format(data.ticker?.lastPrice)
            : 0}

          <span className='last-price-desc ms-1'>
            {t('watchlist:usd')}{' '}
            {formatter.format(
              getPriceDiff(
                data.ticker?.financials?.regularMarketPrice,
                data.ticker?.lastPrice,
              ),
            )}{' '}
            (
            {formatter.format(
              getPriceDiffPercentage(
                data.ticker?.financials?.regularMarketPrice,
                data.ticker?.lastPrice,
              ),
            )}
            %)
          </span>
        </p>
        <div className='d-flex flex-row justify-content-between'>
          <div className='w-100'>
            <p className='watchlist-section-title'>{getEarningsDate()}</p>
            <p className='watchlist-section-desc'>
              {t('watchlist:upcomingEarnings')}
            </p>
          </div>
          <div className='w-100'>
            <p className='watchlist-section-title'>
              {data.ticker?.financials
                ? data.ticker.financials.epsTrailingTwelveMonths
                : '-'}
            </p>
            <p className='watchlist-section-desc'>{t('watchlist:epsTTM')}</p>
          </div>
          <div className='w-100'>
            <p className='watchlist-section-title'>
              {data.ticker?.financials && data.ticker?.financials.marketCap
                ? compactFormatter.format(data.ticker?.financials.marketCap)
                : '-'}
            </p>
            <p className='watchlist-section-desc'>{t('watchlist:marketCap')}</p>
          </div>
          <div className='w-100'>
            <p className='watchlist-section-title'>
              {data.ticker?.financials
                ? data.ticker.financials.epsTrailingTwelveMonths
                : '-'}
            </p>
            <p className='watchlist-section-desc'>
              {t('watchlist:priceToEarnings')}
            </p>
          </div>
        </div>
        <div className='d-flex flex-row justify-content-between my-4'>
          {flowSentiments.map((_sentiment, index) => (
            <div
              className={`w-100 ${
                index + 1 < flowSentiments.length ? 'pe-2' : ''
              }`}
              key={_sentiment.title}
            >
              <ChartCard noBackground sentiment={_sentiment} />
            </div>
          ))}
        </div>
        <div className='mb-5'>
          <WatchListChart
            height={300}
            watchListDetails={data.id}
            showHeader={false}
          />
        </div>
        <div className='d-flex flex-row justify-content-between'>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>{t('watchlist:sector')}</p>
            <p className='watchlist-default-text'>{data.ticker?.sector}</p>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>{t('watchlist:website')}</p>
            <a
              className='watchlist-default-text'
              href={`https://${data.ticker?.website}`}
              target='_blank'
              rel='noreferrer'
            >
              {data.ticker?.website} <BiLinkExternal size={16} />
            </a>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:industry')}
            </p>
            <p className='watchlist-default-text'>{data.ticker?.industry}</p>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:NumberOfEmployees')}
            </p>
            <p className='watchlist-default-text'>
              {data.ticker?.employees
                ? compactFormatter.format(data.ticker?.employees)
                : '-'}
            </p>
          </div>
        </div>
        <hr />
        <p className='watchlist-default-text mb-4'>
          {getClippedTitle(data.ticker?.description)}{' '}
          {isShowMoreText ? (
            <span
              className='primary'
              onClick={() => {
                setIsShowMoreDesc(true)
              }}
            >
              {t('watchlist:showMore')}
            </span>
          ) : (
            <></>
          )}
        </p>
        <div className='d-flex flex-row justify-content-between'>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>{t('watchlist:ceo')}</p>
            <p className='watchlist-default-text'>{data.ticker?.CEO}</p>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:employees')}
            </p>
            <p className='watchlist-default-text'>
              {data.ticker?.employees
                ? compactFormatter.format(data.ticker?.employees)
                : '-'}{' '}
            </p>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:headquarters')}
            </p>
            <p className='watchlist-default-text'>
              {data.ticker?.city}, {data.ticker?.state}
            </p>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>{t('watchlist:founded')}</p>
            <p className='watchlist-default-text'>-</p>
          </div>
        </div>
        <hr />
        <div className='d-flex flex-row justify-content-between'>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:marketCap')}
            </p>
            <p className='watchlist-default-text'>
              {data.ticker?.financials && data.ticker?.financials.marketCap
                ? compactFormatter.format(data.ticker?.financials.marketCap)
                : '-'}
            </p>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:priceEarnings')}
            </p>
            <p className='watchlist-default-text'>
              {data.ticker?.financials &&
              data.ticker?.financials.regularMarketVolume
                ? compactFormatter.format(
                    data.ticker?.financials.regularMarketVolume,
                  )
                : '-'}
            </p>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:dividentYield')}
            </p>
            <p className='watchlist-default-text'>
              {data.ticker?.financials && data.ticker?.financials.epsForward
                ? compactFormatter.format(data.ticker?.financials.epsForward)
                : '-'}
            </p>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:averageVolume')}
            </p>
            <p className='watchlist-default-text'>
              {data.ticker?.financials &&
              data.ticker?.financials.averageDailyVolume10Day
                ? compactFormatter.format(
                    data.ticker?.financials.averageDailyVolume10Day,
                  )
                : '-'}
            </p>
          </div>
        </div>
        <div className='d-flex flex-row justify-content-between mt-4'>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:highToday')}
            </p>
            <p className='watchlist-default-text'>
              {data.ticker?.financials &&
              data.ticker?.financials.regularMarketDayHigh
                ? compactFormatter.format(
                    data.ticker?.financials.regularMarketDayHigh,
                  )
                : '-'}
            </p>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:lowToday')}
            </p>
            <p className='watchlist-default-text'>
              {data.ticker?.financials &&
              data.ticker?.financials.regularMarketDayLow
                ? compactFormatter.format(
                    data.ticker?.financials.regularMarketDayLow,
                  )
                : '-'}
            </p>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:openPrice')}
            </p>
            <p className='watchlist-default-text'>
              {data.ticker?.financials &&
              data.ticker?.financials.regularMarketOpen
                ? compactFormatter.format(
                    data.ticker?.financials.regularMarketOpen,
                  )
                : '-'}
            </p>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>{t('watchlist:volume')}</p>
            <p className='watchlist-default-text'>
              {data.ticker?.financials &&
              data.ticker?.financials.regularMarketVolume
                ? compactFormatter.format(
                    data.ticker?.financials.regularMarketVolume,
                  )
                : '-'}
            </p>
          </div>
        </div>
        <div className='d-flex flex-row justify-content-between mt-4'>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:52WeekHigh')}
            </p>
            <p className='watchlist-default-text'>
              {data.ticker?.financials &&
              data.ticker?.financials.fiftyTwoWeekHigh
                ? compactFormatter.format(
                    data.ticker?.financials.fiftyTwoWeekHigh,
                  )
                : '-'}
            </p>
          </div>
          <div className='w-100'>
            <p className='watchlist-info-text mb-2'>
              {t('watchlist:52WeekLow')}
            </p>
            <p className='watchlist-default-text'>
              {data.ticker?.financials &&
              data.ticker?.financials.fiftyTwoWeekLow
                ? compactFormatter.format(
                    data.ticker?.financials.fiftyTwoWeekLow,
                  )
                : '-'}
            </p>
          </div>
          <div className='w-100' />
          <div className='w-100' />
        </div>
      </div>
    </DefaultModal>
  )
}

export default WatchlistDetailsModal
