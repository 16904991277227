import React from 'react'
import { useSelector } from 'react-redux'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { RootState } from 'src/core/store/Store'

export const CustomBarChart = () => {
  const journals = useSelector(
    (state: RootState) => state.reports.states.journals,
  )

  const barChartData = journals
    .map((journal) => {
      return {
        date: new Date(journal.date).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        value: [0, journal.grossPL],
      }
    })
    .reverse()

  return (
    <ResponsiveContainer width='100%' height={300} className='flex-shrink-1'>
      <BarChart data={barChartData}>
        {/* Black Horizontal lines */}
        <CartesianGrid vertical={false} stroke='#000000'>
          <XAxis
            dataKey='date'
            axisLine={false}
            tickLine={false}
            tickMargin={15}
            tick={{
              stroke: '#F4F4F4',
              strokeWidth: 0.6,
              fontSize: '12px',
            }}
          />
        </CartesianGrid>
        {/* Dashed Line on Y = 0 */}
        <ReferenceLine y={0} strokeDasharray='3 3' stroke='#424242' />
        {/* X Axis */}
        <XAxis
          dataKey='date'
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          tick={{
            stroke: '#F4F4F4',
            strokeWidth: 0.6,
            fontSize: '12px',
          }}
        />
        {/* Y Axis */}
        <YAxis
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          tick={{
            stroke: '#F4F4F4',
            fontSize: '12px',
            strokeWidth: 0.6,
          }}
          tickFormatter={(value) => {
            return `$${value}`
          }}
        />
        {/* Bars */}
        <Bar dataKey='value' fill='#fff' barSize={32}>
          {barChartData.map((entry, index) => {
            const color = entry.value[1] > 0 ? '#3EBC61' : '#F45B6C'
            return <Cell key={`cell-${index}`} fill={color} />
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
