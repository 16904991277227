import React from 'react'
import { AuthService } from 'src/core/services'

import './styles.scss'

import DefaultButton from '../../buttons/defaultButton/DefaultButton'
import DefaultModal from '../DefaultModal/DefaultModal'

interface Props {
  visible: boolean
  onHide: () => void
}
const LogoutModal = ({ visible, onHide }: Props) => {
  return (
    <DefaultModal
      title='Are you sure you want to logout?'
      visible={visible}
      onHide={onHide}
    >
      <div className='d-flex'>
        <DefaultButton
          className='px-4 w-fit-content me-3 logout-btn'
          variant='secondary'
          title='Logout'
          onClick={async () => {
            await AuthService.signOut()
          }}
        />
        <DefaultButton
          className='px-4 w-fit-content'
          variant='default'
          title='Cancel'
          onClick={onHide}
        />
      </div>
    </DefaultModal>
  )
}

export default LogoutModal
