import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Unsubscribe } from 'firebase/auth'
import { NotificationsService } from 'src/core/services'
import {
  setSubscribedNotError,
  setSubscribedNotificationsData,
} from 'src/core/store/features/notifications/notifications'

import { Notification } from '../../types'

export const useSubNotifications = (
  type: 'tsNewsLetters' | 'tsTechnicalAnalysis',
) => {
  const dispatch = useDispatch()

  const unsubscribeNotificationsRef = useRef<Unsubscribe | null>()

  useEffect(() => {
    subscribeNotifiations(type)
    return () => {
      if (unsubscribeNotificationsRef.current) {
        unsubscribeNotificationsRef?.current()
      }
    }
  }, [])

  /**
   * Method used to get real-time notifications. It keeps updating the last {NOTIFICATIONS_SUBSCRIPTION_COUNT} records added in the database
   */
  const subscribeNotifiations = useCallback(
    async (type: 'tsNewsLetters' | 'tsTechnicalAnalysis') => {
      // delete any subscribtion before getting new data
      if (unsubscribeNotificationsRef.current) {
        unsubscribeNotificationsRef?.current()
      }
      unsubscribeNotificationsRef.current =
        await NotificationsService.subscribeToNotifications(
          type,
          (data: Notification[], error: any) => {
            if (!error) {
              dispatch(setSubscribedNotificationsData({ type, data }))
            } else {
              dispatch(setSubscribedNotError({ type, error }))
            }
          },
        )
    },
    [],
  )
}
