import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Unsubscribe } from 'firebase/auth'
import { TopMoversService } from 'src/core/services'
import {
  setSubscribedTopMoversData,
  setSubscribedTopMoversError,
} from 'src/core/store/features/topMovers/topMovers'

import { TopMover } from '../../types'

export const useSubTopMovers = (type: 'gainers' | 'losers') => {
  const dispatch = useDispatch()

  const unsubscribeTopMoversRef = useRef<Unsubscribe | null>()

  useEffect(() => {
    subscribeTopMovers(type)
    return () => {
      if (unsubscribeTopMoversRef.current) {
        unsubscribeTopMoversRef?.current()
      }
    }
  }, [])

  /**
   * Method used to get real-time top movers. It keeps updating the last {TOP_MOVERS_SUBSCRIPTION_COUNT} records added in the database
   */
  const subscribeTopMovers = useCallback(async (type: 'gainers' | 'losers') => {
    // delete any subscribtion before getting new data
    if (unsubscribeTopMoversRef.current) {
      unsubscribeTopMoversRef?.current()
    }
    unsubscribeTopMoversRef.current =
      await TopMoversService.subscribeToTopMovers(
        type,
        (data: TopMover[], error: any) => {
          if (!error) {
            dispatch(setSubscribedTopMoversData({ type, data }))
          } else {
            dispatch(setSubscribedTopMoversError({ type, error }))
          }
        },
      )
  }, [])
}
