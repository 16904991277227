import { endOfDay } from 'date-fns'
import {
  collection,
  //   doc,
  //   getDoc,
  getDocs,
  //   limit,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  //   startAfter,
  where,
  WhereFilterOp,
} from 'firebase/firestore'
import { reportError } from 'src/core/services'
import {
  //   ORDERS_PER_SCROLL,
  //   ORDERS_SUBSCRIPTION_COUNT,
  TFilterCondition,
  TOption,
} from 'src/modules/orderFlow/types'

import { FireStoreDB } from '../Firebase'

class OrderFlow {
  COLLECTION_NAME = 'optionOrderFlow'

  /**
   * retrieveOrders
   * Retrieve Orders according to the date
   */
  async retrieveOrders(
    onValue: (data: any, error?: any) => void,
    conditions:
      | {
          [conditionName: string]: TFilterCondition
        }
      | null
      | undefined,
    // sortBy?: { [key: string]: OrderByDirection } | null,
  ) {
    try {
      const documentDate = await this.getLastDate()
      const newConditions = Object.keys(conditions || {}).reduce((acc, key) => {
        if (
          conditions![key].operator?.value === 'is' &&
          key !== 'dte' &&
          key !== 'expirationDate'
        ) {
          acc[key] = conditions![key]
        }
        return acc
      }, {} as { [conditionName: string]: TFilterCondition })
      const constraints = this.buildQueryConstraints(newConditions)
      // const orderByConstraints: QueryConstraint[] = !sortBy
      //   ? []
      //   : Object.keys(sortBy).map((key) => {
      //       return orderBy(key, sortBy[key])
      //     })
      const docRef = query(
        collection(
          FireStoreDB,
          `${this.COLLECTION_NAME}/${documentDate}/orders`,
        ),
        ...constraints,
        orderBy('time', 'desc'),
        // limit(ORDERS_SUBSCRIPTION_COUNT),
        // ...orderByConstraints,
      )

      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        const filteredDocs = docSnap.docs.filter((doc) => {
          return Object.keys(conditions || {}).every((key) => {
            if (
              conditions![key].operator?.value !== 'is' ||
              conditions![key].operator?.value !== 'is_not'
            ) {
              if (key === 'dte') {
                const value = doc.data().expirationDate
                const expirationDate = new Date(
                  value.seconds * 1000 + value.nanoseconds / 1000000,
                )
                const daysToExpiration = conditions![key].value as number
                const date = new Date(
                  new Date().setDate(new Date().getDate() + daysToExpiration),
                )
                switch (conditions![key].operator?.value) {
                  case 'is_greater_than':
                    return expirationDate > date
                  case 'is_greater_than_or_equal_to':
                    return expirationDate >= date
                  case 'is_less_than':
                    return expirationDate < date
                  case 'is_less_than_or_equal_to':
                    return expirationDate <= date
                  default:
                    return true
                }
              } else if (key === 'expirationDate') {
                const value = doc.data().expirationDate
                const expirationDate = new Date(
                  value.seconds * 1000 + value.nanoseconds / 1000000,
                )
                const expirationDateWithoutTime = expirationDate.toDateString()
                const date = new Date(conditions![key].value as string)
                const dateWithoutTime = date.toDateString()
                return expirationDateWithoutTime === dateWithoutTime
              } else {
                const value = doc.data()[key]
                switch (conditions![key].operator?.value) {
                  case 'is_not':
                    return value !== (conditions![key].value as number)
                  case 'is_greater_than':
                    return value > (conditions![key].value as number)
                  case 'is_greater_than_or_equal_to':
                    return value >= (conditions![key].value as number)
                  case 'is_less_than':
                    return value < (conditions![key].value as number)
                  case 'is_less_than_or_equal_to':
                    return value <= (conditions![key].value as number)
                  default:
                    return true
                }
              }
            }
            return true
          })
        })
        onValue(filteredDocs.map((_doc) => ({ id: _doc.id, ..._doc.data() })))
      })
      return unsubscribe
    } catch (e) {
      reportError(e)
      onValue(null, e as Error)
      return null
    }
  }

  /**
   * GET Orders with pagination (scrolling edition)
   * @param lastDocumentId
   */
  async getOrders(
    conditions:
      | {
          [conditionName: string]: TFilterCondition
        }
      | null
      | undefined,
    // lastDocumentId?: string,
  ) {
    const documentDate = await this.getLastDate()
    const newConditions = Object.keys(conditions || {}).reduce((acc, key) => {
      if (
        conditions![key].operator?.value === 'is' &&
        key !== 'dte' &&
        key !== 'expirationDate'
      ) {
        acc[key] = conditions![key]
      }
      return acc
    }, {} as { [conditionName: string]: TFilterCondition })
    const constraints = this.buildQueryConstraints(newConditions)
    const docRef = query(
      collection(FireStoreDB, `${this.COLLECTION_NAME}/${documentDate}/orders`),
      //   limit(ORDERS_PER_SCROLL),
      ...constraints,
      orderBy('time', 'desc'),
    )
    // if (lastDocumentId) {
    //   const lastDocRef = doc(
    //     FireStoreDB,
    //     this.COLLECTION_NAME,
    //     documentDate,
    //     'orders',
    //     lastDocumentId,
    //   )
    //   const lastDocumentSnap = await getDoc(lastDocRef)

    //   docRef = query(
    //     collection(
    //       FireStoreDB,
    //       `${this.COLLECTION_NAME}/${documentDate}/orders`,
    //     ),
    //     ...constraints,
    //     orderBy('time', 'desc'),
    //     startAfter(lastDocumentSnap),
    //     // limit(ORDERS_PER_SCROLL),
    //   )
    // }

    const docs = await getDocs(docRef)
    // filter doc with conditions that does not have operator value "is"
    const filteredDocs = docs.docs.filter((doc) => {
      return Object.keys(conditions || {}).every((key) => {
        if (
          conditions![key].operator?.value !== 'is' ||
          conditions![key].operator?.value !== 'is_not'
        ) {
          if (key === 'dte') {
            const value = doc.data().expirationDate
            const expirationDate = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000,
            )
            const daysToExpiration = conditions![key].value as number
            const date = new Date(
              new Date().setDate(new Date().getDate() + daysToExpiration),
            )
            switch (conditions![key].operator?.value) {
              case 'is_greater_than':
                return expirationDate > date
              case 'is_greater_than_or_equal_to':
                return expirationDate >= date
              case 'is_less_than':
                return expirationDate < date
              case 'is_less_than_or_equal_to':
                return expirationDate <= date
              default:
                return true
            }
          } else if (key === 'expirationDate') {
            const value = doc.data().expirationDate
            const expirationDate = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000,
            )
            // remove time from date
            const expirationDateWithoutTime = expirationDate.toDateString()

            const date = new Date(conditions![key].value as string)
            const dateWithoutTime = date.toDateString()

            return expirationDateWithoutTime === dateWithoutTime
          } else {
            const value = doc.data()[key]
            switch (conditions![key].operator?.value) {
              case 'is_not':
                return value !== (conditions![key].value as number)
              case 'is_greater_than':
                return value > (conditions![key].value as number)
              case 'is_greater_than_or_equal_to':
                return value >= (conditions![key].value as number)
              case 'is_less_than':
                return value < (conditions![key].value as number)
              case 'is_less_than_or_equal_to':
                return value <= (conditions![key].value as number)
              default:
                return true
            }
          }
        }
        return true
      })
    })
    return filteredDocs.map((_doc: any) => ({ id: _doc.id, ..._doc.data() }))
  }

  async getLastDate(): Promise<string> {
    try {
      let result = ''
      const q = query(collection(FireStoreDB, this.COLLECTION_NAME))
      const querySnapshot = await getDocs(q)

      querySnapshot.forEach((optionOrderFlow) => {
        // return last document date exist
        result = optionOrderFlow.id
      })
      return result
    } catch (error) {
      reportError(error)
      return ''
    }
  }

  buildQueryConstraints(
    conditions:
      | {
          [conditionName: string]: TFilterCondition
        }
      | null
      | undefined,
  ) {
    const constraints: QueryConstraint[] = !conditions
      ? []
      : Object.keys(conditions)
          ?.filter(
            (_condKey) =>
              !!conditions?.[_condKey]?.operator &&
              !!conditions?.[_condKey]?.value,
          )
          .map((_cond) => {
            let operator = ''

            switch (conditions?.[_cond]?.operator?.value) {
              case 'is':
                operator = '=='
                break
              case 'is_greater_than':
                operator = '>'
                break
              case 'is_not':
                operator = '!='
                break
              case 'is_greater_than_or_equal_to':
                operator = '>='
                break
              case 'is_less_than':
                operator = '<'
                break
              case 'is_less_than_or_equal_to':
                operator = '<='
                break
              default:
                operator = '=='
                break
            }

            if (
              _cond === 'ticker' ||
              _cond === 'type' ||
              _cond === 'contract'
            ) {
              const { value } = conditions?.[_cond].value as TOption
              return where(_cond, operator as WhereFilterOp, value)
            }
            const value = conditions?.[_cond].value

            if (_cond === 'expirationDate' && operator === '==') {
              return where('expirationDate', '>', value)
            }
            return where(_cond, operator as WhereFilterOp, value)
          })
          .flat()
    if (
      conditions?.expirationDate?.operator?.value === 'is' &&
      conditions?.expirationDate?.value
    ) {
      constraints.push(
        where(
          'expirationDate',
          '<',
          endOfDay(conditions?.expirationDate.value as Date),
        ),
      )
    }
    return constraints
  }
}

export default new OrderFlow()
