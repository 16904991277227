import './styles.scss'

interface Props {
  title: string
}

const PageTitle = ({ title }: Props) => {
  return <p className='page-title-component'>{title}</p>
}

export default PageTitle
