// @ts-nocheck
import { useCallback } from 'react'
import { toArray } from 'react-emoji-render'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { formatRelative } from 'date-fns'
import DefaultModal from 'src/core/components/modals/DefaultModal/DefaultModal'

import './styles.scss'

import { Notification } from '../../types'

const parseEmojis = (value) => {
  const emojisArray = toArray(value)

  // toArray outputs React elements for emojis and strings for other
  const newValue = emojisArray.reduce((previous, current) => {
    if (typeof current === 'string') {
      return previous + current
    }
    return previous + current.props.children
  }, '')

  return newValue
}

interface Props {
  data: Notification
  hideNotificationDetails: any
  type: string
}

const NotificationDetailsModal = ({
  data,
  hideNotificationDetails,
  type,
}: Props) => {
  const formatDate = useCallback((date: Date) => {
    const dateString = formatRelative(date, new Date()).split(' at')[0]
    return `${dateString.charAt(0).toUpperCase()}${dateString.slice(1)}`
  }, [])

  const getTitle = useCallback(() => {
    if (type === 'tsTechnicalAnalysis') {
      return t('technicalAnalysis')
    }

    return `🔥 ${t('dailyNewsLetter')} - ${formatDate(
      new Date(data.date.seconds * 1000),
    )}`
  }, [])

  const [t] = useTranslation('notifications')

  return (
    <DefaultModal
      visible={!!data}
      onHide={hideNotificationDetails}
      title={getTitle()}
    >
      <div id='notification-details-modal-component'>
        <ReactMarkdown className='data'>{parseEmojis(data.data)}</ReactMarkdown>
      </div>
    </DefaultModal>
  )
}

export default NotificationDetailsModal
