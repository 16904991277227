// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { toArray } from 'react-emoji-render'
import { useTranslation } from 'react-i18next'
import { FixedList } from 'react-recycled-list'
import { useDispatch, useSelector } from 'react-redux'
import { formatRelative } from 'date-fns'
import { LoadingSpinner } from 'src/core/components'
import { fetchNotifications } from 'src/core/store/features/notifications/notifications'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

import { Notification } from '../../types'
import NotificationDetailsModal from '../notificationDetailsModal/NotificationDetailsModal'

type RenderRow = {
  firstRenderedRowIndex: number
  firstRenderedDataIndex: number
  lastRenderedRowIndex: number
  lastRenderedDataIndex: number
  lastRowIndex: number
}

type NotificationsTableProps = {
  height: number
  isPopup?: boolean
  type: 'tsTechnicalAnalysis' | 'tsNewsLetters'
}

export const NotificationsList = ({
  height,
  isPopup,
  type,
}: NotificationsTableProps) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const {
    data: notifications,
    loading: notificationsLoading,
    isFetchedAll: isFetchedAllNotifications,
    isFetching: isFetchingNotifications,
    error,
  } = useSelector((state: RootState) => state.notifications[type])

  const [formattedNotifications, setFormattedNotifications] = useState<
    (Notification | undefined)[]
  >([])

  const [notificationDetails, setNotificationDetails] =
    useState<Notification | null>(null)

  useEffect(() => {
    if (!notifications || notifications.length === 0) {
      return
    }
    if (!isFetchedAllNotifications) {
      return setFormattedNotifications([...notifications, undefined])
    } else {
      setFormattedNotifications(notifications)
    }
  }, [notifications])

  /**
   * Method used to fetch more notifications while scrolling
   */
  const getNotifications = async () => {
    const lastNotification =
      notifications && notifications.length > 0
        ? notifications[notifications.length - 1]
          ? notifications[notifications.length - 1]
          : notifications[notifications.length - 2]
        : undefined

    dispatch(fetchNotifications({ type, lastDocumentId: lastNotification?.id }))
  }

  /**
   * Method that is triggered whenever the last row in the table is rendered
   */
  const onRenderedRowChange = (renderInfo: RenderRow) => {
    const { lastRenderedRowIndex, lastRowIndex } = renderInfo
    // If the last row is rendered (NOT visible yet!) and we are not already loading data, we fetch new data
    // If you want to fetch data when the last row is visible then use onVisibleRowChange
    if (
      !isFetchingNotifications &&
      !notificationsLoading &&
      !isFetchedAllNotifications &&
      lastRowIndex > 0 &&
      lastRenderedRowIndex === lastRowIndex
    ) {
      getNotifications()
    }
  }

  const displayNotificationDetails = (details: Notification) => {
    setNotificationDetails(details)
  }

  const hideNotificationDetails = () => {
    setNotificationDetails(null)
  }

  return notificationsLoading ? (
    <div
      style={{ height }}
      className={
        isPopup
          ? 'p-3'
          : 'p-3 d-flex d-flex align-items-center justify-content-center'
      }
    >
      <LoadingSpinner />
    </div>
  ) : error ? (
    <Container>
      <Row className='mt-4'>
        <Col sm={12}>
          <Alert variant='danger'>{t('common:loadFailed')}</Alert>
        </Col>
      </Row>
    </Container>
  ) : (
    <>
      <FixedList
        height={height}
        rowComponent={(props) => {
          return (
            <NotificationRow
              {...props}
              displayNotificationDetails={displayNotificationDetails}
              isPopup={isPopup}
              type={type}
            />
          )
        }}
        data={formattedNotifications}
        rowHeight={isPopup ? 100 : 110}
        onRenderedRowChange={onRenderedRowChange}
      />
      {notificationDetails && (
        <NotificationDetailsModal
          data={notificationDetails}
          hideNotificationDetails={hideNotificationDetails}
          type={type}
        />
      )}
    </>
  )
}

type NotificationRowProps = {
  data: Notification[]
  dataIndex: number
  top: number
  height: number
  displayNotificationDetails: Function
  isPopup?: boolean
  type: string
}

const parseEmojis = (value: string) => {
  const emojisArray = toArray(value)

  // toArray outputs React elements for emojis and strings for other
  const newValue = emojisArray.reduce((previous, current) => {
    if (typeof current === 'string') {
      return previous + current
    }
    return previous + current.props.children
  }, '')

  return newValue
}

// eslint-disable-next-line
const NotificationRow = React.memo((props: NotificationRowProps) => {
  // the data here is the same data that is passed into the FixedList
  const {
    data,
    dataIndex,
    top,
    height,
    displayNotificationDetails,
    isPopup,
    type,
  } = props

  const [t] = useTranslation('notifications')

  const notification = data[dataIndex]

  const isFirstRow = notification && dataIndex === 0

  const formatDate = useCallback((date: Date) => {
    const dateString = formatRelative(date, new Date()).split(' at')[0]
    return `${dateString.charAt(0).toUpperCase()}${dateString.slice(1)}`
  }, [])

  const getClippedTitle = useCallback(
    (title: string) => {
      if (type !== 'tsTechnicalAnalysis') {
        title =
          title.split('__**Market Notes**__').length > 1
            ? title.split('__**Market Notes**__')[1]
            : title
      }
      const numberOfCharacters = isPopup ? 125 : 150
      if (title.length > numberOfCharacters) {
        return title.slice(0, numberOfCharacters) + '...'
      } else {
        return title
      }
    },
    [isPopup],
  )

  // Important!, make sure you inline-style your component with the the provided top, height. Also make sure to set your container element to position absolute
  return (
    <div className={`${isPopup ? 'small' : ''} ${isFirstRow ? 'latest' : ''}`}>
      <div
        style={{ top, height, position: 'absolute' }}
        className='react-recycled-row notification-row'
        onClick={() => displayNotificationDetails(notification)}
      >
        {notification === undefined ? (
          <div className='h-100 d-flex align-items-center justify-content-center'>
            <LoadingSpinner />
          </div>
        ) : (
          <div className='h-100'>
            <p className='title'>
              {isFirstRow && <span className='new-tag'>{t('new')}</span>}
              <span className=''>🔥</span>{' '}
              {type === 'tsTechnicalAnalysis'
                ? t('technicalAnalysis')
                : t('dailyNewsLetter')}{' '}
              <span className='date'>
                {formatDate(new Date(notification.date.seconds * 1000))}
              </span>
            </p>
            <p className='desc'>
              {parseEmojis(getClippedTitle(notification.data))}
            </p>
          </div>
        )}
      </div>
    </div>
  )
})
