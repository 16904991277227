import { useTranslation } from 'react-i18next'
import { BsFilter } from 'react-icons/bs'

import './styles.scss'

type FiltersButtonProps = {
  onClick: () => void
}

const FiltersButton = ({ onClick }: FiltersButtonProps) => {
  const [t] = useTranslation()

  return (
    <div className='filters-btn' onClick={onClick}>
      <span>{t('common:filters')}</span>
      <span>
        <BsFilter className='filter-icon' />
      </span>
    </div>
  )
}

export default FiltersButton
