import { ReactNode } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'

import './styles.scss'

interface DefaultTabsProps {
  tabs: Array<{
    key: string
    title: string
    icon?: ReactNode
    component: ReactNode
  }>
}

const DefaultTabs = ({ tabs }: DefaultTabsProps) => {
  return (
    <div className='default-tabs-component'>
      <Tab.Container defaultActiveKey={tabs[0].key}>
        <Container>
          <Row className='tabs-container'>
            <Col sm={12}>
              <Nav variant='pills' className='tabs'>
                {tabs.map((tab) => {
                  return (
                    <Nav.Item key={tab.key}>
                      <Nav.Link eventKey={tab.key}>
                        {tab.icon && (
                          <span className='tab-icon'>{tab.icon}</span>
                        )}{' '}
                        {tab.title}
                      </Nav.Link>
                    </Nav.Item>
                  )
                })}
              </Nav>
            </Col>
            <Col sm={12} className='section-content'>
              <Tab.Content>
                {tabs.map((tab) => {
                  return (
                    <Tab.Pane key={tab.key} eventKey={tab.key}>
                      {tab.component}
                    </Tab.Pane>
                  )
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Container>
      </Tab.Container>
    </div>
  )
}

export default DefaultTabs
