import { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-calendar/dist/Calendar.css'
import 'react-daterange-picker/dist/css/react-calendar.css'
import 'react-quill/dist/quill.snow.css'
import AppRoutes from 'src/core/routes/AppRoutes'
import {
  getDefaultTheme,
  ThemeContext,
} from 'src/core/utils/theme/ThemeContext'

import './calendar.scss'
import './rich-quill.scss'

function App() {
  const [theme, setTheme] = useState(getDefaultTheme())

  return (
    <div className='App'>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <div className={`theme-${theme}`}>
          <div className='layout-wrapper'>
            <AppRoutes />
          </div>
        </div>
        <ToastContainer />
      </ThemeContext.Provider>
    </div>
  )
}

export default App
