import { Form, FormControlProps, InputGroup } from 'react-bootstrap'

import './styles.scss'

interface Props extends FormControlProps {
  icon?: any // Consider specifying a more precise type, e.g., JSX.Element
  errorMsg?: string
  placeholder?: string // Explicitly declare placeholder
}

const DefaultInput = ({
  icon,
  errorMsg,
  onChange,
  type,
  value,
  placeholder,
  ...props
}: Props) => {
  return (
    <div className='default-input-group-container'>
      <InputGroup className='default-input-group'>
        {icon ? (
          <InputGroup.Text className={'icon ' + (errorMsg ? 'error' : '')}>
            {icon}
          </InputGroup.Text>
        ) : null}
        <Form.Control
          className={
            'default-input-component ' +
            (errorMsg ? 'error ' : '') +
            (icon ? 'ps-0 no-b-l ' : '')
          }
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          value={value}
          {...props}
        />
      </InputGroup>
      {errorMsg && <p className='error'>{errorMsg}</p>}
    </div>
  )
}

export default DefaultInput
