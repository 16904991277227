import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  PackageSubscribtion,
  UserData,
} from 'src/core/services/firebase/firestore/Users'

export interface UserDetailsState {
  data: UserData | null
  activeSubscribtion: PackageSubscribtion | null
}

const initialState: UserDetailsState = {
  data: null,
  activeSubscribtion: null,
}

export const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<UserData>) => {
      state.data = action.payload
    },
    setActiveSubscribtion: (
      state,
      action: PayloadAction<PackageSubscribtion>,
    ) => {
      state.activeSubscribtion = action.payload
    },
    resetUser: () => {
      return initialState
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUserDetails, setActiveSubscribtion, resetUser } =
  userDetailsSlice.actions

export default userDetailsSlice.reducer
