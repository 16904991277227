import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import desktopPhoneDemo from 'src/assets/images/landing/desktopPhoneDemo.png'

import './styles.scss'

import { AppStoreButton } from '../AppStoreButton/AppStoreButton'

export const HomeMainCard = () => {
  const [t] = useTranslation('landing')
  const isTablet = useMediaQuery({ query: `(max-width: 991px)` })

  return (
    <div className='landing-home d-flex'>
      <div>
        <p className='title'>
          {t('landingTitle')}
          <br />
          {t('forInvestors')}
          <br />
          <span className='primary'>{t('madeByInvestors')}</span>
        </p>
        <p className='desc'>{t('landingDesc')}</p>
        {isTablet && (
          <img src={desktopPhoneDemo} width='100%' className='mb-5' />
        )}
        <div className='d-flex'>
          <div className='me-3'>
            <AppStoreButton type='apple' />
          </div>
          <AppStoreButton type='google' />
        </div>
      </div>
      {!isTablet && <img src={desktopPhoneDemo} width='55%' />}
    </div>
  )
}
