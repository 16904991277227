import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import VerifyEmailBanner from 'src/core/components/banners/verifyEmail/VerifyEmailBanner'
import { AuthService } from 'src/core/services'
import {
  setFilters,
  setOpenFilterModal,
} from 'src/core/store/features/filters/filters'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

import { NewsTable } from '../news/components/newsTable/NewsTable'
import { FiltersModal, OrdersTable } from '../orderFlow/components'
import FilterChips from '../orderFlow/components/filterChips/FilterChips'
import { SentimentsCards } from '../orderFlow/components/sentimentsCards/SentimentsCards'
import { TFilterCondition } from '../orderFlow/types'
import { TopMoversTable } from '../topMovers/components/topMoversTable/topMoversTable'
import { WatchListTable } from '../watchList/components/watchListTable/WatchListTable'

const DashboardPage = () => {
  const [t] = useTranslation('dashboard')
  const user = AuthService.getCurrentUser()

  const dispatch = useDispatch()
  const filters = useSelector((state: RootState) => state.filters.filters)

  const openFilterModal = useSelector(
    (state: RootState) => state.filters.openModal,
  )

  const onHideModal = () => {
    dispatch(setOpenFilterModal({ openModal: false }))
  }

  const onApplyFilter = (
    filterConditions: {
      [condition: string]: TFilterCondition
    } | null,
  ) => {
    dispatch(setOpenFilterModal({ openModal: false }))
    dispatch(setFilters({ filters: filterConditions }))
  }

  const pageHeight =
    document.getElementById('dashboard-page')?.getBoundingClientRect().height ??
    0

  const aboveOrdersHeight =
    document.getElementById('dashboard-above-orders')?.getBoundingClientRect()
      .height ?? 0

  let ordersTableHeight = pageHeight - aboveOrdersHeight - 300 - 200
  ordersTableHeight = ordersTableHeight < 250 ? 250 : ordersTableHeight

  // const ordersTableHeight = 55 * 4 + 32 // rowHeight * 4 + tableHeader

  return (
    <div id='dashboard-page' className='p-2 h-100'>
      <Container className='pt-4 pb-4'>
        <div id='dashboard-above-orders'>
          {!user?.emailVerified && (
            <Row className='mb-3'>
              <VerifyEmailBanner />
            </Row>
          )}
          <Row>
            <SentimentsCards />
          </Row>
        </div>
        <Row>
          <div className='main-tabs'>
            <Tab.Container id='main-tabs' defaultActiveKey='orders'>
              <div>
                <Nav variant='tabs'>
                  <Nav.Item>
                    <Nav.Link eventKey='orders'>{t('orderFlow')}</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link className='tab-add'>+</Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </div>
              <div>
                <Tab.Content>
                  <Tab.Pane eventKey='orders'>
                    <OrdersTable height={ordersTableHeight} filters={filters} />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </Row>
        {filters && (
          <Row className='mt-3'>
            <FilterChips filters={filters} />
          </Row>
        )}
        <Row className='mt-4'>
          <Col xs md={6} lg={4}>
            <div className='secondary-tabs'>
              <Tab.Container id='seondary-tabs' defaultActiveKey='top-gainers'>
                <div>
                  <Nav variant='tabs'>
                    <Nav.Item>
                      <Nav.Link eventKey='top-gainers'>
                        {t('topGainers')}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='top-losers'>
                        {t('topLosers')}
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link className='tab-add'>+</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                </div>
                <div>
                  <Tab.Content>
                    <Tab.Pane eventKey='top-gainers'>
                      <TopMoversTable height={300} isSmall type='gainers' />
                    </Tab.Pane>
                    <Tab.Pane eventKey='top-losers'>
                      <TopMoversTable height={300} isSmall type='losers' />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </Col>
          <Col xs md={6} lg={4}>
            <div className='secondary-tabs'>
              <Tab.Container id='seondary-tabs' defaultActiveKey='news'>
                <div>
                  <Nav variant='tabs'>
                    <Nav.Item>
                      <Nav.Link eventKey='news'>{t('news')}</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link className='tab-add'>+</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                </div>
                <div>
                  <Tab.Content>
                    <Tab.Pane eventKey='news'>
                      <NewsTable height={300} isSmall />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </Col>
          <Col xs md={6} lg={4}>
            <div className='secondary-tabs'>
              <Tab.Container id='seondary-tabs' defaultActiveKey='watchList'>
                <div>
                  <Nav variant='tabs'>
                    <Nav.Item>
                      <Nav.Link eventKey='watchList'>{t('watchList')}</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link className='tab-add'>+</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                </div>
                <div>
                  <Tab.Content>
                    <Tab.Pane eventKey='watchList'>
                      <WatchListTable height={300} isSmall />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
      <FiltersModal
        show={openFilterModal}
        onHide={onHideModal}
        onApplyFilter={onApplyFilter}
        initialConditions={filters}
      />
    </div>
  )
}

export default DashboardPage
