import { httpsCallable } from 'firebase/functions'
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import { reportError } from 'src/core/services'
import { TradingStats } from 'src/core/store/features/reports/types'
import {
  JournalSummary,
  TradeJournal,
  TradingDays,
} from 'src/core/store/features/tradeJournal/types'

import { FirebaseAuth, Functions } from '../../Firebase'

export interface BrokerageAccount {
  id: string
  name: string
  enabled: boolean
  tradeJournalPath: string
  brokerage?: string
}
export interface Brokerage {
  accounts?: BrokerageAccount[]
  brokerage: string
  isConnected: boolean
}

interface Response {
  status: boolean
  data: any
  message: string
}

class TJCloudFunctions {
  async tradeJournalLogin(
    code: string,
    brokerage: string,
  ): Promise<Brokerage[] | null> {
    try {
      const clFunction = httpsCallable(Functions, 'oauthCode')
      const result = await clFunction({ code, brokerage })
      if (result) {
        return result.data as Brokerage[]
      }
      return null
    } catch (error) {
      reportError(error)
      throw error
    }
  }

  async tradeJournalSetupAccounts(
    brokerage: string,
    accountIDs: string[],
  ): Promise<Response[] | null> {
    try {
      const clFunction = httpsCallable(Functions, 'setupAccounts')
      const result = await clFunction({ brokerage, accountIDs })
      if (result) {
        return result.data as Response[]
      }
      return null
    } catch (error) {
      reportError(error)
      throw error
    }
  }

  async brokeragesConnected() {
    try {
      const clFunction = httpsCallable<void, Brokerage[]>(
        Functions,
        'brokeragesConnected',
      )
      const result = await clFunction()
      if (result) {
        return result
      }
      throw new Error('No data returned')
    } catch (error) {
      reportError(error)
      return {
        error: { message: (error as Error)?.message ?? 'An error occured' },
      }
    }
  }

  async deleteBrokerageAccount(params: { brokerage: string }): Promise<any> {
    try {
      const clFunction = httpsCallable(Functions, 'deleteBrokerageAccount')
      const result = await clFunction({ brokerage: params.brokerage })
      if (result) {
        return result.data
      }
      return null
    } catch (error) {
      reportError(error)
      throw error
    }
  }

  async tradeJournalUploadBrokerageData(
    brokerage: string,
    dataType: string,
    data: any[],
  ): Promise<any> {
    try {
      const clFunction = httpsCallable(Functions, 'uploadBrokerageData')
      const result = await clFunction({ brokerage, dataType, data })
      if (result) {
        return result.data
      }
      return []
    } catch (error) {
      reportError(error)
      throw error
    }
  }

  async getTradingDays(params: { accountID: string }) {
    try {
      const clFunction = httpsCallable<
        {
          accountID: string
        },
        TradingDays
      >(Functions, 'tradeJournalCalendar')
      const result = await clFunction(params)
      if (result) {
        return result
      }
      throw new Error('No data returned')
    } catch (error) {
      console.log(error)
      reportError(error)
      return {
        error: { message: (error as Error)?.message ?? 'An error occured' },
      }
    }
  }

  async getTradeJournalSummary(params: {
    startDate: string
    endDate: string
    brokerage: string
  }) {
    try {
      const clFunction = httpsCallable<
        {
          startDate: string
          endDate: string
          brokerage: string
        },
        JournalSummary
      >(Functions, 'tradeJournalSummary')
      const result = await clFunction(params)

      return { data: result.data }
    } catch (error) {
      reportError(error)
      return {
        error: { message: (error as Error)?.message ?? 'An error occured' },
      }
    }
  }

  async getTradeJournalSummaryForReports(params: {
    startDate: string
    endDate: string
    brokerage: string
  }) {
    try {
      const clFunction = httpsCallable<
        {
          startDate: string
          endDate: string
          brokerage: string
        },
        TradingStats
      >(Functions, 'tradeJournalSummary')
      const result = await clFunction(params)

      return { data: result.data }
    } catch (error) {
      reportError(error)
      return {
        error: { message: (error as Error)?.message ?? 'An error occured' },
      }
    }
  }

  async uploadCSV(params: { file: File; brokerage: string }) {
    try {
      if (!FirebaseAuth.currentUser) {
        throw new Error('User not logged in')
      }

      const storageRef = ref(
        getStorage(),
        `/uploads/users/${FirebaseAuth.currentUser.uid}/brokerages/${params.brokerage}/${params.file.name}`,
      )
      const uploadTask = await uploadBytesResumable(storageRef, params.file)
      const downloadUrl = await getDownloadURL(uploadTask.ref)
      return { data: downloadUrl }
    } catch (e) {
      reportError(e)
      return {
        error: { message: (e as Error)?.message ?? 'An error occured' },
      }
    }
  }

  async modifyTradeJournalTransaction(params: {
    tradeTagIDs: string[]
    transactionID: string
    tradeJournalPath: string
    profitTarget: string
    stopLoss: string
    comment: string
  }) {
    try {
      const clFunction = httpsCallable<
        {
          tradeTagIDs: string[]
          transactionID: string
          tradeJournalPath: string
          profitTarget: string
          stopLoss: string
          comment: string
        },
        TradeJournal['transactions'][number]
      >(Functions, 'modifyTradeJournalTransaction')

      const result = await clFunction(params)

      if (result) {
        return result
      }
      throw new Error('No data returned')
    } catch (error) {
      reportError(error)
      return {
        error: { message: (error as Error)?.message ?? 'An error occured' },
      }
    }
  }
}

export default new TJCloudFunctions()
