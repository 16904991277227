export type Notification = {
  id: string
  data: string
  date: { seconds: number; nanoseconds: number }
  image?: string
  sequence: string
  type: string
  notificationType?: 'tsTechnicalAnalysis' | 'tsNewsLetters'
  url?: string
}

export const NOTIFICATIONS_PER_SCROLL = 1000
export const NOTIFICATIONS_SUBSCRIPTION_COUNT = 50
