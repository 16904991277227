import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'
import cls from 'classnames'

import './styles.scss'

interface TradeJournalWinningCardProps {
  title: string
  percentage: number
  winners: number
  losers: number
  className?: string
}

const TradeJournalWinningCard = ({
  title,
  className,
  percentage,
  winners,
  losers,
}: TradeJournalWinningCardProps) => {
  return (
    <div className={cls('trade-journal-winning-card', className)}>
      <p className='winning-title'>{title}</p>
      <div className='chart-container'>
        <div className='chart-trades'>
          <CircularProgressbarWithChildren
            value={percentage}
            className='chart-trades-bar'
            strokeWidth={10}
            styles={buildStyles({
              rotation: 0.6,
            })}
          >
            <p className='chart-trades-text mb-0'>{percentage}%</p>
          </CircularProgressbarWithChildren>
        </div>
      </div>
      <div className='d-flex justify-content-between bullet-container'>
        <div className='d-flex align-items-center'>
          <div className='bullet-circle me-1' />
          <p className='bullet-title me-1'>winners</p>
          <p className='bullet-desc'>{winners}</p>
        </div>
        <div className='d-flex align-items-center'>
          <div className='bullet-circle danger me-1' />
          <p className='bullet-title me-1'>losers</p>
          <p className='bullet-desc'>{losers}</p>
        </div>
      </div>
    </div>
  )
}

export default TradeJournalWinningCard
