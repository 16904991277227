import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface RegisterState {
  selectedPackage: string
  isSubscribing: boolean
}

const initialState: RegisterState = {
  selectedPackage: '',
  isSubscribing: false,
}

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setSelectedPackage: (state, action: PayloadAction<string>) => {
      state.selectedPackage = action.payload
    },
    setIsSubscribing: (state, action: PayloadAction<boolean>) => {
      state.isSubscribing = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSelectedPackage, setIsSubscribing } = registerSlice.actions

export default registerSlice.reducer
