import { Col, Container, Row } from 'react-bootstrap'

import { NewsTable } from './components/newsTable/NewsTable'

const NewsPage = () => {
  return (
    <div id='news-page' className='h-100'>
      <Container className='d-flex justify-content-center align-items-center h-100'>
        <Row className='w-100 h-100 d-flex align-items-center h-100'>
          <Col xs md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
            <NewsTable
              height={
                (document.getElementById('news-page')?.getBoundingClientRect()
                  .height ?? 600) * 0.9
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NewsPage
