import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

import { CustomBarChart } from './components/CustomBarChart'

export const OverviewBarChart = () => {
  const dates = useSelector((state: RootState) => state.reports.datePicker)
  const startDateFormatted = new Date(dates.startDate).toLocaleDateString(
    'en-US',
    {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    },
  )
  const endDateFormatted = new Date(dates.endDate).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })

  return (
    <div className='bar-chart-container'>
      <div className='bar-chart-title'>Gross Daily P&L</div>
      <div className='bar-chart-subtitle'>
        (From {startDateFormatted} to {endDateFormatted})
      </div>
      <div className='bar-chart'>
        <CustomBarChart />
      </div>
    </div>
  )
}
