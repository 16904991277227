import { loadStripe } from '@stripe/stripe-js'

class PaymentService {
  module: any

  constructor() {
    loadStripe(process.env.REACT_APP_STRIPE_KEY as string).then((stripe) => {
      this.module = stripe
    })
  }
}
export default new PaymentService()
