// @ts-nocheck
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flatten, uniqBy } from 'lodash'
import { NewsService } from 'src/core/services'
import {
  NEWS_PER_SCROLL,
  NEWS_SUBSCRIPTION_COUNT,
} from 'src/modules/news/types'

import {
  FetchNews,
  NewsState,
  SetSubscribedNewsData,
  SetSubscribedNewsError,
} from './types'

const initialState: NewsState = {
  error: null,
  data: [],
  loading: true,
  isFetchedAll: false,
  isFetching: false,
}

export const fetchNews = createAsyncThunk(
  'news/fetchNews',
  async ({ lastDocumentId }: FetchNews) => {
    const response = await NewsService.getNews(lastDocumentId)
    return { data: response }
  },
)

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setSubscribedNewsData: (
      state,
      action: PayloadAction<SetSubscribedNewsData>,
    ) => {
      const { data } = action.payload

      if (data.length < NEWS_SUBSCRIPTION_COUNT) {
        state.isFetchedAll = true
      }

      state.data = uniqBy(flatten([data, state.data]), 'id')

      state.loading = false
    },
    setSubscribedNewsError: (
      state,
      action: PayloadAction<SetSubscribedNewsError>,
    ) => {
      const { error } = action.payload
      state.error = error
      state.loading = false
    },
    resetNews: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNews.pending, (state) => {
      state.isFetching = true
    })
    builder.addCase(fetchNews.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data.length < NEWS_PER_SCROLL) {
        state.isFetchedAll = true
      }
      const newNews = uniqBy(flatten([state.data, data]), 'id') // append the NEWLY ADDED data to the start of the table
      state.data = newNews
      state.loading = false
      state.isFetching = false
    })
    builder.addCase(fetchNews.rejected, (state, action) => {
      state.error = action.error.message
      state.loading = false
      state.isFetching = false
    })
  },
})

// Action creators are generated for each case reducer function
export const { setSubscribedNewsData, setSubscribedNewsError, resetNews } =
  newsSlice.actions

export default newsSlice.reducer
