import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { find } from 'lodash'
import { LoadingSpinner } from 'src/core/components'
import CustomAreaChart from 'src/core/components/charts/AreaChart/AreaChart'
import { WatchListService } from 'src/core/services'
import { setChartDataAction } from 'src/core/store/features/watchlist/watchlist'
import { RootState } from 'src/core/store/Store'

import '../../styles.scss'

import { WatchList } from '../../types'

interface WatchListChartProps {
  watchListDetails: string | null
  height: number
  showHeader?: boolean
}

const formatter = Intl.NumberFormat('en', {
  maximumFractionDigits: 2,
})

const getPriceDiff = (
  previousPrice: number | undefined,
  currentPrice: number | undefined,
) => {
  currentPrice = currentPrice ?? 0
  previousPrice = previousPrice ?? currentPrice

  return currentPrice - previousPrice
}

const getPriceDiffPercentage = (
  previousPrice: number | undefined,
  currentPrice: number | undefined,
) => {
  currentPrice = currentPrice ?? 0
  previousPrice = previousPrice ?? currentPrice

  const diff = getPriceDiff(previousPrice, currentPrice)

  return previousPrice ? (diff / previousPrice) * 100 : 0
}

export const WatchListChart = ({
  watchListDetails,
  height,
  showHeader = true,
}: WatchListChartProps) => {
  const [t] = useTranslation('')

  const [chartLoading, setChartLoading] = useState<boolean>(true)

  const {
    data: watchList,
    loading: watchListLoading,
    chartData,
  } = useSelector((state: RootState) => state.watchlist)
  const dispatch = useDispatch()

  const currentWatchList = useMemo(
    () => find(watchList, { id: watchListDetails }) as WatchList,
    [watchListDetails],
  )

  useEffect(() => {
    async function getChartWatchList() {
      const response = await WatchListService.getChartWatchList(
        watchListDetails,
      )

      if (!response) {
        dispatch(setChartDataAction({ chartData: [] }))
        setChartLoading(false)
        return
      }

      // convert the response object keys to timestamp
      const converted = await Object.keys(response as Object).reduce(
        (acc, key) => {
          const timestamp = new Date(parseInt(key) * 1000).toLocaleTimeString(
            'en-US',
            {
              timeZone: 'America/Chicago',
              hour12: true,
              hour: '2-digit',
              minute: '2-digit',
            },
          )

          acc[timestamp] = response ? response[key] : 0
          return acc
        },
        {} as any,
      )

      // convert the object to array
      const convertedToArray = await Object.keys(converted).map((key) => ({
        time: key,
        value: converted[key].toFixed(2),
      }))
      dispatch(setChartDataAction({ chartData: convertedToArray }))
      setChartLoading(false)
    }
    getChartWatchList()
  }, [watchListDetails])

  const percentageClass = useMemo(() => {
    const change = getPriceDiff(
      currentWatchList?.ticker?.financials?.regularMarketPrice,
      currentWatchList?.ticker?.lastPrice,
    )

    return change === 0 ? '' : change > 0 ? 'success' : 'danger'
  }, [currentWatchList])

  const priceDiff = useMemo(() => {
    return getPriceDiff(
      currentWatchList?.ticker?.financials?.regularMarketPrice,
      currentWatchList?.ticker?.lastPrice,
    )
  }, [currentWatchList])

  const priceDiffPercentage = useMemo(() => {
    return getPriceDiffPercentage(
      currentWatchList?.ticker?.financials?.regularMarketPrice,
      currentWatchList?.ticker?.lastPrice,
    )
  }, [currentWatchList])

  return (
    <>
      {chartLoading || !currentWatchList || watchListLoading ? (
        <div className='d-flex align-items-center justify-content-center'>
          <LoadingSpinner />
        </div>
      ) : (
        <div className='h-100 d-flex flex-column'>
          {showHeader && (
            <div>
              <div>
                <span className='chart-title'>
                  {t(`${currentWatchList?.ticker?.companyName}`)}{' '}
                </span>
                {t(`$${currentWatchList?.ticker?.lastPrice?.toFixed(2)}`)}
              </div>
              <div className={`mt-1 ${percentageClass}`}>
                <p className='percentage'>
                  ${formatter.format(priceDiff)} (
                  {formatter.format(priceDiffPercentage)}
                  %)
                </p>
              </div>
            </div>
          )}
          {chartData.length > 0 ? (
            <CustomAreaChart
              data={chartData}
              height={height}
              xAxisTickFormatter={(index) => {
                const data = chartData[index].time
                return data as string
              }}
              isWatchListChart
              type={
                priceDiff === 0
                  ? 'default'
                  : priceDiff > 0
                  ? 'success'
                  : 'danger'
              }
            />
          ) : (
            // make it centered vertically and horizontally
            <div className='d-flex align-items-center justify-content-center h-100'>
              {t(`No data available for today's chart`)}
            </div>
          )}
        </div>
      )}
    </>
  )
}
