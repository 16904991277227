import React, { ReactNode } from 'react'
import { Button } from 'react-bootstrap'
import { FaSpinner } from 'react-icons/fa'
import cls from 'classnames'

import './styles.scss'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'danger' | 'outlined' | 'secondary'
  icon?: ReactNode
  loading?: boolean
  title: string
  isSmall?: boolean
  className?: string
}

const DefaultButton = ({
  variant = 'default',
  icon,
  loading,
  title,
  isSmall,
  className,
  disabled,
  ...props
}: Props) => {
  return (
    <Button
      className={cls(
        'default-button-component d-flex justify-content-center',
        `${variant}`,
        { small: isSmall },
        className,
      )}
      disabled={loading || disabled}
      {...props}
    >
      {icon && !loading && <div className='icon pe-2'>{icon}</div>}
      {!loading && title}
      {loading && <FaSpinner className='spinner' />}
    </Button>
  )
}

export default DefaultButton
