import { DailySentiment } from 'src/core/services/firebase/firestore/DailySentiment'

export type WatchList = {
  id: string
  name: string
  sortOrder: number
  symbol: string
  createdOn: {
    seconds: number
    nanoSeconds: number
  }
  updatedOn: {
    seconds: number
    nanoSeconds: number
  }
  ticker?: Ticker
  dailySentiment?: DailySentiment
}

export type Ticker = {
  companyName?: string
  symbol: string
  exchange?: string
  lastPrice?: number
  sector?: string
  website?: string
  industry?: string
  employees?: number
  CEO?: string
  description?: string
  city?: string
  state?: string
  country?: string

  financials?: {
    currency?: string
    earningsTimestamp?: number
    marketCap?: number
    epsTrailingTwelveMonths?: string
    averageDailyVolume10Day?: number
    fiftyTwoWeekHigh?: number
    fiftyTwoWeekLow?: number
    epsForward?: number
    regularMarketVolume?: number
    regularMarketPrice?: number
    regularMarketDayHigh?: number
    regularMarketDayLow?: number
    regularMarketTime?: number
    regularMarketChange?: number
    regularMarketChangePercent?: number
    regularMarketOpen?: number
  }
}

export type chartData = {
  value: any
  time: string
}

export const WATCH_LIST_PER_SCROLL = 1000
export const WATCH_LIST_SUBSCRIPTION_COUNT = 500
