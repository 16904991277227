import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillEye } from 'react-icons/ai'
import ReactQuill from 'react-quill'
import classNames from 'classnames'
import { format } from 'date-fns'
import { isEqual } from 'lodash'
import { DefaultButton, DefaultInput } from 'src/core/components'
import DefaultModal from 'src/core/components/modals/DefaultModal/DefaultModal'
import {
  useGetTradeTagsQuery,
  useModifyTradeJournalTransactionMutation,
} from 'src/core/store/features/tradeJournal/api'
import { TradeJournal } from 'src/core/store/features/tradeJournal/types'
import { displaySuccessMessage } from 'src/core/utils/alerts/ToastAlerts'
import { currencyFormatter } from 'src/core/utils/number'

import './styles.scss'

import { TagsSelectionModal } from '../tagsSelectionModal/TagsSelectionModal'
import { TradeTag } from '../tradeTag/TradeTag'

interface Props {
  transaction: TradeJournal['transactions'][number]
  tradeJournalPath: string

  hideDetails: any
}

const DailyStatsDetailsModal = ({
  transaction,
  tradeJournalPath,
  hideDetails,
}: Props) => {
  const [t] = useTranslation('dailyStats')
  const [tradeTagsModalOpen, setTradeTagsModalOpen] = useState(false)
  const tagsQuery = useGetTradeTagsQuery()
  const [modify, modifyMutation] = useModifyTradeJournalTransactionMutation()
  const [selectedTags, setSelectedTags] = useState(
    transaction.tradeTagIDs ?? [],
  )

  useEffect(() => {
    setSelectedTags(transaction.tradeTagIDs ?? [])
    setComment(transaction.comment ?? '')
    setProfitTarget(transaction.profitTarget ?? '')
    setStopLoss(transaction.stopLoss ?? '')
  }, [transaction])

  const [comment, setComment] = useState<string>(transaction.comment ?? '')
  const [profitTarget, setProfitTarget] = useState(
    transaction.profitTarget ?? '',
  )
  const [stopLoss, setStopLoss] = useState(transaction.stopLoss ?? '')
  const tags = [
    ...(tagsQuery.data?.mistake ?? []),
    ...(tagsQuery.data?.strategy ?? []),
  ]

  const disabled =
    profitTarget === (transaction.profitTarget ?? '') &&
    stopLoss === (transaction.stopLoss ?? '') &&
    isEqual(selectedTags, transaction.tradeTagIDs) &&
    comment === (transaction.comment ?? '')

  const onSaveClick = () => {
    modify({
      transactionID: transaction.transactionID,
      comment,
      profitTarget,
      stopLoss,
      tradeTagIDs: selectedTags,
      tradeJournalPath,
    })
      .unwrap()
      .then(() => {
        displaySuccessMessage(t('details.toast.success'))
      })
  }
  return (
    <>
      <DefaultModal
        visible={!!transaction}
        onHide={hideDetails}
        title={transaction.symbol}
        DescriptionNode={
          <div className='daily-stats-title-container mt-3'>
            <p className='date'>
              {format(new Date(transaction.transactionDate), 'E, MMM dd, yyyy')}
            </p>
            <p className='instrument'>{transaction.instrument}</p>
          </div>
        }
      >
        <div className='daily-stats-details-modal'>
          <div
            className={classNames(
              'd-flex justify-content-between align-items-center',
              transaction.netPL < 0 ? 'danger' : 'success',
            )}
          >
            <p className='net' style={{ color: 'currentcolor' }}>
              {t('table.netPL')}
            </p>
            <div className='d-flex align-items-center'>
              <p
                className='net-desc me-2'
                style={{
                  color: 'currentcolor',
                }}
              >
                {currencyFormatter.format(transaction.netPL)}
              </p>
              <AiFillEye
                className='success'
                style={{
                  color: 'currentcolor',
                }}
                size={20}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <p className='title'>{t('details.optionsTraded')}</p>
            <p className='desc'>{transaction.volume}</p>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <p className='title'>{t('details.commAndFees')}</p>
            <p className='desc'>
              {currencyFormatter.format(transaction.commissionFees)}
            </p>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <p className='title'>{t('table.netROI')}</p>
            <p
              className={classNames(
                'desc',
                transaction.netPL < 0 ? 'danger' : 'success',
              )}
            >
              {transaction.netROI.toFixed(2) + '%'}
            </p>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <p className='title'>{t('grossPL')}</p>
            <p
              className={classNames(
                'desc',
                transaction.netPL < 0 ? 'danger' : 'success',
              )}
            >
              {currencyFormatter.format(transaction.grossPL)}
            </p>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <p className='title'>{t('details.adjustedCost')}</p>
            <p className='desc'>
              {currencyFormatter.format(transaction.adjustedCost)}
            </p>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <p className='title'>{t('details.profitTarget')}</p>
            <div className='input-wrapper'>
              <DefaultInput
                type='number'
                value={profitTarget}
                onChange={(e) => setProfitTarget(e.target.value)}
                icon={<p className='dollar-icon'>$</p>}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <p className='title'>{t('details.stopLoss')}</p>
            <div className='input-wrapper'>
              <DefaultInput
                icon={<p className='dollar-icon'>$</p>}
                value={stopLoss}
                onChange={(e) => setStopLoss(e.target.value)}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <p className='title'>{t('details.initialTarget')}</p>
            <p className='desc'>--</p>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <p className='title'>{t('details.tradeRisk')}</p>
            <p className='desc'>--</p>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <p className='title'>{t('details.plannedRMultiple')}</p>
            <p className='desc'>--</p>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <p className='title'>{t('details.realizedRMultiple')}</p>
            <p className='desc'>--</p>
          </div>
          <div className='d-flex flex-column mt-2'>
            <p className='title'>{t('details.tags')}</p>
            <div className='trade-tags-container'>
              {selectedTags.length === 0 && (
                <TradeTag
                  active={false}
                  tag={{
                    tagID: 'addTag',
                    name: '#ClickToAddTag',
                    type: 'Strategy',
                  }}
                  onClick={() => setTradeTagsModalOpen(true)}
                />
              )}
              {selectedTags.map((tagID) => {
                const tag = tags.find((tag) => tag.tagID === tagID)
                if (!tag) return null
                return (
                  <TradeTag
                    key={tagID}
                    active
                    tag={tag}
                    onClick={() => setTradeTagsModalOpen(true)}
                  />
                )
              })}
            </div>
          </div>
          <ReactQuill
            value={comment}
            onChange={(_, __, ___, editor) => setComment(editor.getText())}
            placeholder='Write your text here'
            modules={{
              toolbar: [
                [{ header: [false, 3, 2, 1] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ align: [] }],
              ],
            }}
          />
        </div>
        <DefaultButton
          className='mt-4'
          onClick={onSaveClick}
          title={t('details.save')}
          disabled={disabled}
          loading={modifyMutation.isLoading}
        />
      </DefaultModal>
      <TagsSelectionModal
        selectedTags={selectedTags}
        onTagClick={(tagID) => {
          if (selectedTags.includes(tagID)) {
            setSelectedTags(selectedTags.filter((tag) => tag !== tagID))
          } else {
            setSelectedTags([...selectedTags, tagID])
          }
        }}
        visible={tradeTagsModalOpen}
        onHide={() => setTradeTagsModalOpen(false)}
      />
    </>
  )
}

export default DailyStatsDetailsModal
