import cls from 'classnames'

import './styles.scss'

import { TOperator, TOption } from '../../types'

type FilterChipProps = {
  name: string
  operator?: TOperator | null
  value: any
}

const FilterChip = ({ name, value, operator }: FilterChipProps) => {
  if (name === 'ticker' || name === 'type' || name === 'contract') {
    return (
      <div className={cls('filter-chip', { ticker: name === 'ticker' })}>
        {(value as TOption)?.label}
      </div>
    )
  }

  if (name === 'expirationDate') {
    return <div className='filter-chip'>{(value as Date).toDateString()}</div>
  }

  if (name === 'isGoldenSweep') {
    return <div className='filter-chip'>Golden Sweep</div>
  }

  if (name === 'isVolAboveOI') {
    return <div className='filter-chip'>{'Vol > OI'}</div>
  }

  if (name === 'premium') {
    return (
      <div className='filter-chip'>
        {'Premium' + ' ' + operator?.title + ' ' + value}
      </div>
    )
  }

  if (name === 'dte') {
    return (
      <div className='filter-chip'>
        {'DTE' + ' ' + operator?.title + ' ' + value}
      </div>
    )
  }

  if (name === 'boomAlgo') {
    return (
      <div className='filter-chip'>
        {'Boom Algo' + ' ' + operator?.title + ' ' + value}
      </div>
    )
  }

  return <div className='filter-chip'>{JSON.stringify(value)}</div>
}

export default FilterChip
