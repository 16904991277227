import { BsFillExclamationCircleFill } from 'react-icons/bs'
import cls from 'classnames'
import DecreaseGraph from 'src/assets/images/trade-journal/decrease-graph.svg'
import IncreaseGraph from 'src/assets/images/trade-journal/increase-graph.svg'
import ProfitGraph from 'src/assets/images/trade-journal/profit-graph.svg'

import './styles.scss'

interface TradeJournalInfoCardProps {
  title: string
  desc: string
  className?: string
  variant?: string
}

const TradeJournalInfoCard = ({
  title,
  desc,
  className,
  variant = 'default',
}: TradeJournalInfoCardProps) => {
  return (
    <div className={cls('trade-journal-info-card', className)}>
      <div className='d-flex justify-content-between h-100'>
        <div className='d-flex h-100'>
          <img
            className='info-img'
            src={
              variant === 'default'
                ? ProfitGraph
                : variant === 'success'
                ? IncreaseGraph
                : DecreaseGraph
            }
            alt=''
          />
          <div className='ms-3'>
            <p className='info-title'>{title}</p>
            <p className={`info-desc ${variant}`}>{desc}</p>
          </div>
        </div>
        <BsFillExclamationCircleFill className='info-icon' />
      </div>
    </div>
  )
}

export default TradeJournalInfoCard
