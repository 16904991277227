import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OrdersService } from 'src/core/services'
import {
  resetOrders,
  setSubscribedOrdersData,
  setSubscribedOrdersError,
  setUnsub,
} from 'src/core/store/features/orders/orders'
import { RootState } from 'src/core/store/Store'

import { Order, TFilterCondition } from '../../types'

export const useSubOrders = () => {
  const dispatch = useDispatch()
  const unsub = useSelector((state: RootState) => state.orders.unsub)

  /**
   * Method used to get real-time orders. It keeps updating the last {ORDERS_SUBSCRIPTION_COUNT} records added in the database
   */
  const subscribeOrders = useCallback(
    async (
      conditions:
        | {
            [conditionName: string]: TFilterCondition
          }
        | null
        | undefined,
    ) => {
      // delete any subscribtion before getting new data
      if (unsub) {
        unsub()
      }
      dispatch(resetOrders())
      const unsubNew = await OrdersService.retrieveOrders(
        (data: Order[], error: any) => {
          if (!error) {
            dispatch(setSubscribedOrdersData({ data }))
          } else {
            dispatch(setSubscribedOrdersError({ error }))
          }
        },
        conditions,
      )
      if (unsubNew) {
        dispatch(setUnsub({ unsub: unsubNew }))
      }
    },
    [unsub],
  )

  return { subscribeOrders, unsubscribeOrders: unsub }
}
