import { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MdLock } from 'react-icons/md'
import { DefaultButton, DefaultText, SecretInput } from 'src/core/components'
import { AuthService } from 'src/core/services'
import {
  displayErrorMessage,
  displaySuccessMessage,
} from 'src/core/utils/alerts/ToastAlerts'
import { validatePassword } from 'src/core/utils/validation/ValidationHelper'
import { SecurityFormFields } from 'src/modules/settings/types'

import './styles.scss'

const Security = () => {
  const [t] = useTranslation()
  const [loading, setLoading] = useState(false)
  const [oldPassword, setOldPassword] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [validationMsgs, setValidationMsgs] = useState<SecurityFormFields>({})

  const updatePassword = async (event: any) => {
    event.preventDefault()
    setLoading(true)
    const hasError = await validate()
    if (hasError || !password) {
      setLoading(false)
      return
    }
    const isUpdated = await AuthService.updatePassword(password)
    if (isUpdated) {
      displaySuccessMessage(t('common:messages.changesSaved'))
    } else {
      displayErrorMessage(t('common:messages.error'))
    }
    setPassword('')
    setOldPassword('')
    setConfirmPassword('')
    setLoading(false)
  }

  const validate = async () => {
    const validationObj: SecurityFormFields = {}
    if (!oldPassword || !oldPassword?.trim().length) {
      validationObj.oldPassword = t('common:validation.required')
    } else {
      const isValidOldPassword = await AuthService.reAuthenticate(oldPassword)
      if (!isValidOldPassword) {
        validationObj.oldPassword = t('common:validation.invalidPassword')
      }
    }
    if (!password || !password?.trim().length) {
      validationObj.newPassword = t('common:validation.required')
    } else {
      const validationPasswordMsg = validatePassword(password)
      if (validationPasswordMsg)
        validationObj.newPassword = validationPasswordMsg
    }
    if (!confirmPassword || !confirmPassword?.trim().length) {
      validationObj.confirmPassword = t('common:validation.required')
    } else {
      if (password !== confirmPassword) {
        validationObj.confirmPassword = t('common:validation.notMatched')
      }
    }
    setValidationMsgs(validationObj)
    if (Object.keys(validationObj).length) {
      return true
    }
    return false
  }

  return (
    <div className='security-settings-page mb-4'>
      <Form onSubmit={updatePassword}>
        <Row className='mb-3'>
          <Col md={12} className='mb-3'>
            <DefaultText title={t('settings:securityHint')} />
          </Col>
          <Col md={12} className='mb-3'>
            <Form.Group>
              <SecretInput
                value={oldPassword}
                onChange={(event: any) => setOldPassword(event.target.value)}
                icon={<MdLock />}
                errorMsg={validationMsgs.oldPassword}
                placeholder={t('common:fields.currentPassword')}
              />
            </Form.Group>
          </Col>
          <Col md={12} className='mb-3'>
            <Form.Group>
              <SecretInput
                value={password}
                onChange={(event: any) => setPassword(event.target.value)}
                icon={<MdLock />}
                errorMsg={validationMsgs.newPassword}
                placeholder={t('common:fields.newPassword')}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <SecretInput
                value={confirmPassword}
                onChange={(event: any) =>
                  setConfirmPassword(event.target.value)
                }
                icon={<MdLock />}
                errorMsg={validationMsgs.confirmPassword}
                placeholder={t('common:fields.confirmPassword')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className='mb-3'>
          <DefaultButton
            loading={loading}
            type='submit'
            title={t('common:actions.save')}
          />
        </Form.Group>
      </Form>
    </div>
  )
}

export default Security
