import { useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { GiPresent } from 'react-icons/gi'
import { useMediaQuery } from 'react-responsive'
import { onSnapshot } from 'firebase/firestore'
import Bull from 'src/assets/images/choose-plan/Bull.png'
import PlanBG from 'src/assets/images/choose-plan/PlanBG.png'
import { AuthService, PaymentService } from 'src/core/services'
import Users from 'src/core/services/firebase/firestore/Users'
import { displayErrorMessage } from 'src/core/utils/alerts/ToastAlerts'

import './styles.scss'

import PackageCard from './packageCard/PackageCard'

interface SelectPackageProps {
  setSelectedPlan?: any
}
// eslint-disable-next-line
const SelectPackage = ({ setSelectedPlan }: SelectPackageProps) => {
  const loggedUser = AuthService.getCurrentUser()
  const isTablet = useMediaQuery({ query: `(max-width: 991px)` })

  const [t] = useTranslation()
  const [loadingPriceId, setLoadingPriceId] = useState<undefined | string>(
    undefined,
  )

  const subscribePackage = (priceId: string) => {
    if (loggedUser) {
      setLoadingPriceId(priceId)
      Users.addCheckoutSession({ price: priceId })
        .then((docRef) => {
          if (docRef) {
            onSnapshot(docRef, async (snap: any) => {
              const { error, sessionId } = snap.data()
              if (error) {
                displayErrorMessage(t('common:messages.error'))
                setLoadingPriceId(undefined)
              }
              if (sessionId) {
                PaymentService.module.redirectToCheckout({ sessionId })
                setLoadingPriceId(undefined)
              }
            })
          }
        })
        .catch(() => {
          displayErrorMessage(t('common:messages.error'))
          setLoadingPriceId(undefined)
        })
    } else {
      setSelectedPlan(priceId)
    }
  }

  return (
    <div className='select-package'>
      {!isTablet && <img className='bull-img' src={Bull} alt='' />}
      <div
        className='select-package-banner d-flex flex-column align-items-center justify-content-center'
        style={{
          backgroundImage: `url('${PlanBG}')`,
          backgroundSize: 'cover',
        }}
      >
        <div className='prize-container d-flex align-items-center'>
          <GiPresent size={isTablet ? 20 : 24} className='gift-icon' />
          <p className='title'>{t('auth:packages.giftTitle')}</p>
        </div>

        <p className='page-title'>{t('auth:packages.title')}</p>
        <p className='page-desc'>{t('auth:packages.desc')}</p>
      </div>
      {isTablet ? (
        <Tab.Container defaultActiveKey='yearly'>
          <Nav variant='pills' className='packages-tabs nav-fill my-4'>
            <Nav.Item>
              <Nav.Link eventKey='monthly'>
                <p>{t('auth:packages.monthly.tabTitle')}</p>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='yearly'>
                <p>
                  {t('auth:packages.yearly.tabTitle')} |{' '}
                  <strong>{t('auth:packages.yearly.savePercentage')}</strong>
                </p>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className='mt-3'>
            <Tab.Pane eventKey='monthly'>
              <PackageCard
                type='monthly'
                loadingPriceId={loadingPriceId}
                onSubmit={() => {
                  subscribePackage(
                    process.env.REACT_APP_MONTHLY_PRICE_ID as string,
                  )
                }}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='yearly'>
              <PackageCard
                type='yearly'
                loadingPriceId={loadingPriceId}
                onSubmit={() => {
                  subscribePackage(
                    process.env.REACT_APP_YEARLY_PRICE_ID as string,
                  )
                }}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      ) : (
        <div className='package-containers d-flex align-items-center justify-content-center'>
          <div className='me-4'>
            <PackageCard
              type='monthly'
              loadingPriceId={loadingPriceId}
              onSubmit={() => {
                subscribePackage(
                  process.env.REACT_APP_MONTHLY_PRICE_ID as string,
                )
              }}
            />
          </div>
          <PackageCard
            type='yearly'
            loadingPriceId={loadingPriceId}
            onSubmit={() => {
              subscribePackage(process.env.REACT_APP_YEARLY_PRICE_ID as string)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default SelectPackage
