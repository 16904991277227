import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
  FaChartLine,
  FaChartPie,
  FaCog,
  FaFileInvoice,
  FaListUl,
  FaSignOutAlt,
  FaThLarge,
} from 'react-icons/fa'
import { NavLink, useLocation } from 'react-router-dom'
import AppLogo from 'src/assets/images/logo.svg'

import './styles.scss'

import LogoutModal from '../../modals/LogoutModal/LogoutModal'
import { ReportIcon } from './icons'

interface SidebarProps {
  opened?: boolean
}

const Sidebar = ({ opened }: SidebarProps) => {
  const [showModal, setShowModal] = useState(false)
  const [t] = useTranslation('common')
  const location = useLocation()
  let links = [
    {
      name: t('modules.dashboard'),
      link: '/dashboard',
      icon: <FaThLarge />,
    },
    {
      name: t('modules.orderFlow'),
      link: '/order-flow',
      icon: <FaChartLine />,
    },

    {
      name: t('modules.tradeJournal'),
      link: '/trade-journal',
      icon: <FaFileInvoice />,
    },
    {
      name: t('modules.dailyStats'),
      link: '/daily-stats',
      icon: <FaChartPie />,
    },
    {
      name: t('modules.reports'),
      link: '/reports',
      icon: <ReportIcon />,
    },
    // {
    //   name: t('modules.optionHeatMap'),
    //   link: '/option-heat-map',
    //   icon: <FaMapMarkerAlt />,
    // },
    // {
    //   name: t('modules.news'),
    //   link: '/news',
    //   icon: <FaNewspaper />,
    // },
    {
      name: t('modules.watchListAndNews'),
      link: '/watch-list',
      icon: <FaListUl />,
    },
    {
      name: t('modules.settings'),
      link: '/settings',
      icon: <FaCog />,
    },
  ]

  if (location.pathname === '/form-agreement') {
    links = [links[0]]
  }
  return (
    <div className={'sidebar-component ' + (opened ? '' : 'active')}>
      <div className='header'>
        <Row>
          <Col lg='3' md='12' sm='12'>
            <img className='app-logo' src={AppLogo} alt='App Logo' />
          </Col>
          <Col lg='9' md='12' sm='12' className='app-title'>
            <p className='title'>{t('sidebarName')}</p>
          </Col>
        </Row>
      </div>
      <div className='links'>
        {links.map((item, index) => {
          return (
            <NavLink
              key={index}
              to={item.link}
              className={({ isActive }) => (isActive ? 'active link' : 'link')}
            >
              <Row>
                {item.link === '/reports' ? (
                  <Col
                    md='2'
                    style={{
                      paddingLeft: '10px',
                    }}
                  >
                    {item.icon}
                  </Col>
                ) : (
                  <Col md='2'>{item.icon}</Col>
                )}

                <Col md='10'>
                  <p>{item.name}</p>
                </Col>
              </Row>
            </NavLink>
          )
        })}
        <div
          className='link logout-link'
          onClick={() => {
            setShowModal(true)
          }}
        >
          <Row>
            <Col md='2'>
              <FaSignOutAlt />
            </Col>
            <Col md='10'>
              <p>Logout</p>
            </Col>
          </Row>
        </div>
      </div>
      <p className='copy-rights'>{new Date().getFullYear() + t('copyRight')}</p>
      <LogoutModal
        visible={showModal}
        onHide={() => {
          setShowModal(false)
        }}
      />
    </div>
  )
}

export default Sidebar
