import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AiOutlineClose } from 'react-icons/ai'
import { FaBars } from 'react-icons/fa'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import AppLogo from 'src/assets/images/logo.svg'

import './styles.scss'

export const LandingTopBar = () => {
  const [t] = useTranslation('landing')
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const isMobile = useMediaQuery({ query: `(max-width: 599px)` })

  return (
    <>
      <div className='landing-top-bar d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <img className='app-logo' src={AppLogo} alt='App Logo' />
          {!isMobile && (
            <>
              <p className='nav-item me-4'>{t('titleName')}</p>
              <Link to='/register'>
                <p className='nav-item me-4'>{t('pricing')}</p>
              </Link>
              <Link to='/contact-us'>
                <p className='nav-item'>{t('contactUs')}</p>
              </Link>
            </>
          )}
        </div>
        {!isMobile && (
          <div>
            <Link to='/register'>
              <Button className='early-access' variant='primary'>
                {t('getStarted')}
              </Button>
            </Link>
            <Link to='/login'>
              <Button className='login-button' variant='primary'>
                {t('login')}
              </Button>
            </Link>
          </div>
        )}
        {isMobile &&
          (openMobileMenu ? (
            <AiOutlineClose
              className='menu-bars'
              size={24}
              onClick={() => {
                setOpenMobileMenu(false)
              }}
            />
          ) : (
            <FaBars
              className='menu-bars'
              size={24}
              onClick={() => {
                setOpenMobileMenu(true)
              }}
            />
          ))}
      </div>
      {isMobile && openMobileMenu && (
        <div className='landing-menu-mobile d-flex flex-column justify-content-between'>
          <div>
            <p className='nav-item'>{t('titleName')}</p>
            <Link to='/register'>
              <p className='nav-item'>{t('pricing')}</p>
            </Link>
            <Link to='/contact-us'>
              <p className='nav-item'>{t('contactUs')}</p>
            </Link>
          </div>
          <div className='d-flex flex-column w-100'>
            <Link to='/register' className='mb-3'>
              <Button className='early-access w-100' variant='primary'>
                {t('getStarted')}
              </Button>
            </Link>
            <Link to='/login'>
              <Button className='login-button w-100' variant='primary'>
                {t('login')}
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  )
}
