import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { format } from 'date-fns'
import { DefaultText, InfoText } from 'src/core/components'
import DefaultModal from 'src/core/components/modals/DefaultModal/DefaultModal'

import './styles.scss'

import { Order } from '../../types'
import { Consolidation } from '../consolidation/Consolidation'
import { Contract } from '../contract/Contract'

interface Props {
  data: Order
  hideOrderDetails: any
}

const OrderDetailsModal = ({ data, hideOrderDetails }: Props) => {
  const [t] = useTranslation('orderFlow')
  const keySize = 'sm'
  const valueSize = 'sm'
  const dateFormat = new Date(1970, 0, 1)
  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <DefaultModal
      visible={!!data}
      onHide={hideOrderDetails}
      title={data?.ticker}
    >
      <div id='order-details-modal-component'>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.time')} size={keySize} />
          </Col>
          <Col md={6} className='text-end'>
            <DefaultText
              title={format(data.time, 'hh:mm:ss aa')}
              size={valueSize}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.expiration')} size={keySize} />
          </Col>
          <Col md={6} className='text-end'>
            <DefaultText
              title={format(
                new Date(dateFormat.setSeconds(data.expirationDate.seconds)),
                'MMM dd, yyyy',
              )}
              size={valueSize}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.contract')} size={keySize} />
          </Col>
          <Col md={6} className='text-end'>
            <Contract orderContract={data.contract} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.strikePrice')} size={keySize} />
          </Col>
          <Col md={6} className='text-end'>
            <DefaultText
              title={moneyFormatter.format(data.strikePrice)}
              size={valueSize}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.volumeOI')} size={keySize} />
          </Col>
          <Col md={6} className='text-end'>
            <DefaultText
              title={data.volume + ' / ' + data.openInterest}
              size={valueSize}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.sizePrice')} size={keySize} />
          </Col>
          <Col md={6} className='text-end'>
            <DefaultText title={data.details} size={valueSize} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.premium')} size={keySize} />
          </Col>
          <Col md={6} className='text-end'>
            <NumericFormat
              className='success'
              displayType='text'
              value={Number(data.premium)}
              thousandSeparator=','
              thousandsGroupStyle='thousand'
              suffix='K'
              prefix='$'
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.goldenSweep')} size={keySize} />
          </Col>
          <Col md={6} className='text-end'>
            <DefaultText
              title={data.isGoldenSweep ? t('common:yes') : t('common:no')}
              size={valueSize}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.iv')} size={keySize} />
          </Col>
          <Col md={6} className='text-end'>
            <DefaultText
              title={`${Number(data.impliedVolatility)
                .toFixed(2)
                ?.toString()}%`}
              size={valueSize}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.consolidation')} size={keySize} />
          </Col>
          <Col md={6} className='text-end d-flex justify-content-end'>
            <div className='w-25'>
              <Consolidation type={data.type} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.theta')} size={keySize} />
          </Col>
          <Col md={6} className='text-end'>
            <DefaultText title={data.theta.toString()} size={valueSize} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.delta')} size={keySize} />
          </Col>
          <Col md={6} className='text-end'>
            <DefaultText title={data.delta.toString()} size={valueSize} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoText title={t('table.gamma')} size={keySize} />
          </Col>
          <Col md={6} className='text-end'>
            <DefaultText title={data.gamma.toString()} size={valueSize} />
          </Col>
        </Row>
      </div>
    </DefaultModal>
  )
}

export default OrderDetailsModal
