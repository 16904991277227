import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import DataIcon from 'src/assets/images/landing/data-icon.png'
import EducationIcon from 'src/assets/images/landing/education-icon.png'
import FilteringIcon from 'src/assets/images/landing/filtering-icon.png'
import StatisticsIcon from 'src/assets/images/landing/statistics-icon.png'

import './styles.scss'

export const RetailTraderCard = () => {
  const [t] = useTranslation('landing')

  return (
    <div className='retail-trader-card'>
      <p className='retail-title'>{t('retailTitle')}</p>
      <Table>
        <RetailCardRow
          img={DataIcon}
          title={t('historicalData')}
          desc={t('historicalDataDesc')}
        />
        <RetailCardRow
          img={StatisticsIcon}
          title={t('marketStats')}
          desc={t('marketStatsDesc')}
        />
        <RetailCardRow
          img={FilteringIcon}
          title={t('advancedFiltering')}
          desc={t('advancedFilteringDesc')}
        />
        <RetailCardRow
          img={EducationIcon}
          title={t('educationalContent')}
          desc={t('educationalContentDesc')}
          noBorder
        />
      </Table>
    </div>
  )
}

interface RetailCardRowProps {
  img: string
  title: string
  desc: string
  noBorder?: boolean
}

export const RetailCardRow = ({
  img,
  title,
  desc,
  noBorder,
}: RetailCardRowProps) => {
  const isTablet = useMediaQuery({ query: `(max-width: 991px)` })

  return (
    <tr className={`retail-feature ${noBorder ? 'no-border' : ''}`}>
      <td className='retail-feature-title'>
        <img src={img} alt='' />
        {title}
        {isTablet && (
          <p className='retail-feature-desc mt-3 mb-0 w-100'>{desc}</p>
        )}
      </td>
      {!isTablet && <td className='retail-feature-desc'>{desc}</td>}
    </tr>
  )
}
