import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Unsubscribe } from 'firebase/auth'
import { DailySentiments } from 'src/core/services'
import { DailySentiment } from 'src/core/services/firebase/firestore/DailySentiment'
import {
  setSubscribedSentimentsData,
  setSubscribedSentimentsError,
} from 'src/core/store/features/sentiments/sentiments'

export const useSubSentiments = () => {
  const dispatch = useDispatch()
  const unsubscribeSentimentsRef = useRef<Unsubscribe | null>()

  useEffect(() => {
    subscribeSentiments()
    return () => {
      if (unsubscribeSentimentsRef.current) {
        unsubscribeSentimentsRef?.current()
      }
    }
  }, [])

  /**
   * Method used to get real-time sentiments. It keeps updating the last {NEWS_SUBSCRIPTION_COUNT} records added in the database
   */
  const subscribeSentiments = useCallback(async () => {
    // delete any subscribtion before getting new data
    if (unsubscribeSentimentsRef.current) {
      unsubscribeSentimentsRef?.current()
    }
    unsubscribeSentimentsRef.current = await DailySentiments.retreiveSentiments(
      (data: DailySentiment | null, error: any) => {
        if (!error) {
          dispatch(setSubscribedSentimentsData({ data }))
        } else {
          dispatch(setSubscribedSentimentsError({ error }))
        }
      },
    )
  }, [])
}
