import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MdLock } from 'react-icons/md'
import { useSelector } from 'react-redux'
import {
  DefaultButton,
  DefaultText,
  LoadingSpinner,
  SecretInput,
} from 'src/core/components'
import WarningBanner from 'src/core/components/banners/warning/WarningBanner'
import { AuthService } from 'src/core/services'
import { RootState } from 'src/core/store/Store'
import { displayErrorMessage } from 'src/core/utils/alerts/ToastAlerts'

import './styles.scss'

const AccountDelete = () => {
  const [t] = useTranslation()
  const [actionLoading, setActionLoading] = useState(false)
  const [password, setPassword] = useState<string>('')
  const activePackage = useSelector(
    (state: RootState) => state.userDetails.activeSubscribtion,
  )

  const deleteAccount = async () => {
    setActionLoading(true)
    const isValidPassword = await AuthService.reAuthenticate(password)
    if (!isValidPassword) {
      displayErrorMessage(t('common:validation.invalidPassword'))
      setActionLoading(false)
      return
    }
    await AuthService.deleteAccount()
    setActionLoading(false)
  }

  if (!activePackage) {
    return <LoadingSpinner />
  }

  const isSubscribtionCancelled = activePackage?.cancel_at_period_end

  return (
    <div className='account-delete-settings-page mb-4'>
      <WarningBanner message={t('common:messages.irReversibleAction')} />
      <br />
      {isSubscribtionCancelled && (
        <>
          <Form.Group className='mb-3'>
            <SecretInput
              value={password}
              onChange={(event: any) => setPassword(event.target.value)}
              icon={<MdLock />}
              placeholder={t('common:fields.password')}
            />
          </Form.Group>
          <DefaultButton
            variant='danger'
            loading={actionLoading}
            onClick={deleteAccount}
            title={t('common:actions.deleteAcc')}
          />
        </>
      )}
      {!isSubscribtionCancelled && (
        <DefaultText title={t('settings:deleteAccountMsg')} />
      )}
    </div>
  )
}

export default AccountDelete
