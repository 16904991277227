import './styles.scss'

interface JournalingRowProps {
  logo: string
  title: string
  desc: string
  noMargin?: boolean
}

export const JournalingRow = ({
  logo,
  title,
  desc,
  noMargin,
}: JournalingRowProps) => {
  return (
    <div className={`feature-card d-flex ${noMargin ? 'mb-0' : ''}`}>
      <img className='feature-img' src={logo} />
      <div>
        <p className='feature-title'>{title}</p>
        <p className='feature-desc'>{desc}</p>
      </div>
    </div>
  )
}
