// @ts-nocheck
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useScreenshot } from 'use-react-screenshot'
import { CheckBox, DefaultButton, DefaultInput } from 'src/core/components'
import DefaultModal from 'src/core/components/modals/DefaultModal/DefaultModal'
import Users from 'src/core/services/firebase/firestore/Users'

import './styles.scss'

interface ValidationMsgs {
  firstName?: string
  lastName?: string
  streetAddress?: string
  city?: string
  state?: string
  zipCode?: string
  country?: string
  isAgree1?: string
  isAgree2?: string
  isAgree3?: string
  isAcceptedTerms?: string
}

const FormAgreement = () => {
  const [t] = useTranslation()

  const notes = t('auth:opra.notes', {
    returnObjects: true,
  }) as Array<string>

  const [loading, setLoading] = useState(false)

  const printRef = useRef()
  const [image, takeScreenshot] = useScreenshot()

  const [firstName, setFirstName] = useState<string | null>(null)
  const [lastName, setLastName] = useState<string | null>(null)
  const [streetAddress, setStreetAddress] = useState<string | null>(null)
  const [city, setCity] = useState<string | null>(null)
  const [state, setState] = useState<string | null>(null)
  const [zipCode, setZipCode] = useState<string | null>(null)
  const [country, setCountry] = useState<string | null>(null)
  const [isAcceptedTerms, setIsAcceptedTerms] = useState<Boolean | null>(false)
  const [validationMsgs, setValidationMsgs] = useState<ValidationMsgs>({})

  const validate = async () => {
    const validationObj: ValidationMsgs = {}
    if (!firstName || !firstName?.trim().length) {
      validationObj.firstName = t('common:validation.required')
    }
    if (!lastName || !lastName?.trim().length) {
      validationObj.lastName = t('common:validation.required')
    }
    if (!streetAddress || !streetAddress?.trim().length) {
      validationObj.streetAddress = t('common:validation.required')
    }
    if (!city || !city?.trim().length) {
      validationObj.city = t('common:validation.required')
    }
    if (!state || !state?.trim().length) {
      validationObj.state = t('common:validation.required')
    }
    if (!zipCode || !zipCode?.trim().length) {
      validationObj.zipCode = t('common:validation.required')
    }
    if (!country || !country?.trim().length) {
      validationObj.country = t('common:validation.required')
    }
    if (!isAcceptedTerms) {
      validationObj.isAcceptedTerms = t('common:validation.acceptTerms')
    }
    setValidationMsgs(validationObj)
    if (Object.keys(validationObj).length) {
      return true
    }
    return false
  }

  const onSubmit = async () => {
    setLoading(true)
    const hasError = await validate()
    if (hasError) {
      setLoading(false)
      return
    }
    try {
      const file = await takeScreenshot(printRef.current)
      await Users.uploadOPRAAgreement(file)
      setLoading(false)
      window.location.reload()
      return image // Does nothing
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  // const getImage = () => takeScreenshot(printRef.current)

  return (
    <DefaultModal
      visible
      title='OPRA Electronic Subscriber Agreement'
      titleVariant='secondary'
      bodyRef={printRef}
    >
      <div className='form-agreement-modal'>
        <p className='desc-text'>{t('auth:opra.notice1')}</p>
        <p className='desc-text'>{t('auth:opra.notice2')}</p>
        <hr className='default' />
        <p className='title-text'>{t('auth:opra.vendor')}</p>
        <p className='desc-text'>{t('auth:opra.representAndAgree')}</p>
        <p className='sub-title'>
          <span className='primary'>1.</span>{' '}
          {t('auth:opra.fullNameAndAddress')}
        </p>
        <div>
          <div className='d-flex'>
            <div className='flex-grow-1 me-3'>
              <DefaultInput
                placeholder={t('auth:opra.form.firstName')}
                onChange={(event: any) => setFirstName(event.target.value)}
                errorMsg={validationMsgs.firstName}
              />
            </div>
            <div className='flex-grow-1'>
              <DefaultInput
                placeholder={t('auth:opra.form.lastName')}
                onChange={(event: any) => setLastName(event.target.value)}
                errorMsg={validationMsgs.lastName}
              />
            </div>
          </div>
          <div className='d-flex mt-3'>
            <div className='flex-grow-1 me-3'>
              <DefaultInput
                placeholder={t('auth:opra.form.streetAddress')}
                onChange={(event: any) => setStreetAddress(event.target.value)}
                errorMsg={validationMsgs.streetAddress}
              />
            </div>
            <div className='flex-grow-1'>
              <DefaultInput
                placeholder={t('auth:opra.form.city')}
                onChange={(event: any) => setCity(event.target.value)}
                errorMsg={validationMsgs.city}
              />
            </div>
          </div>
          <div className='d-flex mt-3'>
            <div className='flex-grow-1 me-3'>
              <DefaultInput
                placeholder={t('auth:opra.form.state')}
                onChange={(event: any) => setState(event.target.value)}
                errorMsg={validationMsgs.state}
              />
            </div>
            <div className='flex-grow-1'>
              <DefaultInput
                placeholder={t('auth:opra.form.zipCode')}
                onChange={(event: any) => setZipCode(event.target.value)}
                errorMsg={validationMsgs.zipCode}
              />
            </div>
          </div>
          <div className='mt-3'>
            <DefaultInput
              placeholder={t('auth:opra.form.country')}
              onChange={(event: any) => setCountry(event.target.value)}
              errorMsg={validationMsgs.country}
            />
          </div>
        </div>
        <hr className='default' />
        {notes.map((note, index) => (
          <p className='sub-title' key={`note-${index}`}>
            <span className='primary'>{index + 1}.</span> {note}
          </p>
        ))}
        <hr className='default' />
        <p className='title-text'>{t('auth:opra.notice')}</p>
        <CheckBox
          className='checkbox-text mb-3'
          name='agree-4'
          checked={!!isAcceptedTerms}
          onChange={(e: any) => setIsAcceptedTerms(e.target.checked)}
          errorMsg={validationMsgs.isAcceptedTerms}
        >
          {t('auth:opra.agreeAbove')}
        </CheckBox>

        <div className='d-flex justify-content-end mt-3'>
          <DefaultButton
            className='px-4 w-fit-content'
            title='Submit Agreement'
            onClick={onSubmit}
            loading={loading}
          />
        </div>
      </div>
    </DefaultModal>
  )
}

export default FormAgreement
