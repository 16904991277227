import { createContext } from 'react'

export const ThemeContext = createContext({
  theme: '',
  /* eslint-disable */
  setTheme: (theme: string) => {},
})

export const getDefaultTheme = (): string => {
  const localStorageTheme = localStorage.getItem('default-theme')
  return localStorageTheme || 'dark'
}

export const setDefaultTheme = (theme: string) => {
  localStorage.setItem('default-theme', theme)
}
