import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { differenceBy, flatten, uniqBy } from 'lodash'
import { NotificationsService } from 'src/core/services'
import {
  NOTIFICATIONS_PER_SCROLL,
  NOTIFICATIONS_SUBSCRIPTION_COUNT,
} from 'src/modules/notifications/types'

import {
  FetchNotifications,
  NotificationsState,
  SetSubscribedNotError,
  SetSubscribedNotifications,
} from './types'

const initialSlice = {
  error: null,
  data: [],
  loading: true,
  isFetchedAll: false,
  isFetching: false,
}

const initialState: NotificationsState = {
  tsTechnicalAnalysis: initialSlice,
  tsNewsLetters: initialSlice,
  newNotification: undefined,
}

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async ({ type, lastDocumentId }: FetchNotifications) => {
    const response = await NotificationsService.getNotifications(
      type,
      lastDocumentId,
    )
    return { type, data: response }
  },
)

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setSubscribedNotificationsData: (
      state,
      action: PayloadAction<SetSubscribedNotifications>,
    ) => {
      const { type, data } = action.payload

      if (data.length < NOTIFICATIONS_SUBSCRIPTION_COUNT) {
        state[type].isFetchedAll = true
      }

      if (state[type].data.length > 0 && data.length > 0) {
        const newNotifications = differenceBy(data, state[type].data, 'id')
        if (newNotifications.length > 0) {
          state.newNotification = {
            ...state[type].data[0],
            notificationType: type,
          }
        }
      }

      state[type].data = uniqBy(flatten([data, state[type].data]), 'id')

      state[type].loading = false
    },
    setSubscribedNotError: (
      state,
      action: PayloadAction<SetSubscribedNotError>,
    ) => {
      const { type, error } = action.payload
      state[type].error = error
      state[type].loading = false
    },
    resetNotifications: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state, action) => {
      const { type } = action.meta.arg
      state[type].isFetching = true
    })
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      const { type, data } = action.payload

      if (data.length < NOTIFICATIONS_PER_SCROLL) {
        state[type].isFetchedAll = true
      }
      const newNotifications = uniqBy(flatten([state[type].data, data]), 'id') // append the NEWLY ADDED data to the start of the table
      state[type].data = newNotifications
      state[type].loading = false
      state[type].isFetching = false
    })
    builder.addCase(fetchNotifications.rejected, (state, action) => {
      const { type } = action.meta.arg
      state[type].error = action.error.message
      state[type].loading = false
      state[type].isFetching = false
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  setSubscribedNotificationsData,
  setSubscribedNotError,
  resetNotifications,
} = notificationsSlice.actions

export default notificationsSlice.reducer
