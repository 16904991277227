import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import cls from 'classnames'
import { format } from 'date-fns'
import { Order } from 'src/modules/orderFlow/types'

import './styles.scss'

import { Consolidation } from '../../consolidation/Consolidation'
import { Contract } from '../../contract/Contract'

type OrdersTableCellProps = {
  order: Order
  dataKey: string
}

const moneyFormatter = new Intl.NumberFormat('en', {
  notation: 'compact',
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'USD',
})

export const OrderTableCell = ({ dataKey, order }: OrdersTableCellProps) => {
  const [t] = useTranslation()

  if (dataKey === 'contract') {
    return (
      <td className={cls(dataKey.toLocaleLowerCase())}>
        <Contract orderContract={order[dataKey]} />
      </td>
    )
  }

  if (dataKey === 'type') {
    return (
      <td className={cls('pe-0', dataKey.toLocaleLowerCase())}>
        <Consolidation type={order[dataKey]} />
      </td>
    )
  }

  if (dataKey === 'isGoldenSweep') {
    return (
      <td className={cls(dataKey.toLocaleLowerCase())}>
        {order[dataKey] ? t('common:yes') : t('common:no')}
      </td>
    )
  }

  if (dataKey === 'time') {
    return (
      <td className={cls(dataKey.toLocaleLowerCase())}>
        {format(new Date(order[dataKey] * 1000), 'hh:mm:ss aa')}
      </td>
    )
  }

  if (dataKey === 'expirationDate') {
    const t = new Date(1970, 0, 1)
    t.setSeconds(order[dataKey].seconds)
    return (
      <td className={cls(dataKey.toLocaleLowerCase())}>
        {format(new Date(t), 'MMM dd, yyyy')}
      </td>
    )
  }

  if (dataKey === 'impliedVolatility') {
    return (
      <td className={cls(dataKey.toLocaleLowerCase())}>
        {`${Number(order[dataKey as keyof Order])
          .toFixed(2)
          ?.toString()}%`}
      </td>
    )
  }

  if (dataKey === 'strikePrice') {
    return (
      <td className={cls(dataKey.toLocaleLowerCase())}>
        {`$${(
          Math.round(Number(order[dataKey as keyof Order]) * 100) / 100
        ).toFixed(2)}`}
      </td>
    )
  }

  if (dataKey === 'premium') {
    return (
      <td className={cls(dataKey.toLocaleLowerCase())}>
        {moneyFormatter.format(order.premium)}
      </td>
    )
  }

  if (dataKey === 'boomAlgo') {
    return (
      <td className={cls(dataKey.toLocaleLowerCase())}>
        <ProgressBar now={(Number(order[dataKey as keyof Order]) * 100) / 12} />
      </td>
    )
  }

  if (dataKey === 'volume') {
    return (
      <td className={cls(dataKey.toLocaleLowerCase())}>
        {order[dataKey as keyof Order]?.toString() + ' / ' + order.openInterest}
      </td>
    )
  }

  return (
    <td className={cls(dataKey.toLocaleLowerCase())}>
      {order[dataKey as keyof Order]?.toString()}
    </td>
  )
}
