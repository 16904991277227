import { useTranslation } from 'react-i18next'

export const WatchListInfoText = ({
  title,
  info,
}: {
  title: string
  info: string | undefined
}) => {
  const [t] = useTranslation('')
  return (
    <div className='w-100 d-flex flex-column align-items-between'>
      <p className='info-title watchlist-info-text mb-6'>
        {t(`watchlist:${title}`)}
      </p>
      <p className='info-data watchlist-default-text'>{info}</p>
    </div>
  )
}
