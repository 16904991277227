import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ReportsState, SetReportsError, TradingStats } from './types'

const initialState: ReportsState = {
  error: null,
  states: {} as TradingStats,
  datePicker: {
    startDate: '',
    endDate: '',
  },
  loading: false,
}

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReportsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setReportsError: (state, action: PayloadAction<SetReportsError>) => {
      const { error } = action.payload
      state.error = error
      state.loading = false
    },
    setTJSummaryParams: (
      state,
      action: PayloadAction<{
        startDate: string
        endDate: string
      }>,
    ) => {
      state.datePicker = action.payload
    },
    setReportsStates: (state, action: PayloadAction<TradingStats>) => {
      state.states = action.payload
      state.loading = false
    },

    resetTJ: () => {
      return initialState
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setReportsLoading,
  setReportsError,
  setReportsStates,
  setTJSummaryParams,
  resetTJ,
} = reportsSlice.actions

export default reportsSlice.reducer
