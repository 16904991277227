import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/core/store/Store'

export const useIntercom = () => {
  const userDetails = useSelector((state: RootState) => state.userDetails.data)

  useEffect(() => {
    const script = document.createElement('script')

    if (userDetails) {
      script.innerHTML = `window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "ciwuobss",
      name: "${userDetails?.firstName} ${userDetails?.lastName}",
      user_id: "${userDetails?.userID}",
      email: "${userDetails?.emailAddress}",
      created_at: "${userDetails?.createDate}",
    };`
    } else {
      script.innerHTML = `window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "ciwuobss",
      };`
    }
    script.async = true
    document.body.appendChild(script)

    const intercomScript = document.createElement('script')
    intercomScript.innerHTML = `(function () {
        var w = window
        var ic = w.Intercom
        if (typeof ic === 'function') {
          ic('reattach_activator')
          ic('update', w.intercomSettings)
        } else {
          var d = document
          var i = function () {
            i.c(arguments)
          }
          i.q = []
          i.c = function (args) {
            i.q.push(args)
          }
          w.Intercom = i
          var l = function () {
            var s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://widget.intercom.io/widget/ciwuobss'
            var x = d.getElementsByTagName('script')[0]
            x.parentNode.insertBefore(s, x)
          }
          if (document.readyState === 'complete') {
            l()
          } else if (w.attachEvent) {
            w.attachEvent('onload', l)
          } else {
            w.addEventListener('load', l, false)
          }
        }
      })();`
    intercomScript.async = true
    document.body.appendChild(intercomScript)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(intercomScript)
    }
  }, [
    userDetails?.createDate,
    userDetails?.emailAddress,
    userDetails?.firstName,
    userDetails?.lastName,
    userDetails?.userID,
    userDetails,
  ])

  return useIntercom
}
