import SecondaryBG from 'src/assets/images/landing/SecondaryBG.png'

import './styles.scss'

import { StepRow, StepRowProps } from './StepRow/StepRow'

interface StepCardProps {
  isBackground?: boolean
  row1: StepRowProps
  row2: StepRowProps
}

export const StepCard = ({ isBackground, row1, row2 }: StepCardProps) => {
  const style = isBackground
    ? {
        backgroundImage: `url('${SecondaryBG}')`,
        backgroundSize: 'cover',
      }
    : {}

  return (
    <div
      className={`step-card w-100 ${isBackground ? 'dark' : ''}`}
      style={style}
    >
      <StepRow {...row1} />
      <StepRow {...row2} isSecondCard />
    </div>
  )
}
