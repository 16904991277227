import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

const checkIfEmpty = (
  value: number | null | undefined,
  formatToThousand: boolean,
) => {
  if (value === null || value === undefined || isNaN(value)) {
    return 'N/A'
  } else {
    if (formatToThousand) {
      if (value < 0) {
        return `-$${Math.abs(value)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
      }

      return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    }
    return value
  }
}

const convertToTime = (value: number | null | undefined) => {
  if (value === null || value === undefined || isNaN(value)) {
    return 'N/A'
  } else {
    const hours = new Date(value).getUTCHours()
    const minutes = new Date(value).getUTCMinutes()
    const seconds = (new Date(value).getUTCSeconds() / 60) * 100
    const secondsRounded = Math.ceil(seconds)
    return `${hours} hours, ${minutes}.${secondsRounded} minutes`
  }
}

export const StatsCard = () => {
  const dates = useSelector((state: RootState) => state.reports.datePicker)
  const startDateFormatted = new Date(dates.startDate).toLocaleDateString(
    'en-US',
    {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    },
  )
  const endDateFormatted = new Date(dates.endDate).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })

  const stats = useSelector((state: RootState) => state.reports.states)

  return (
    <div className='stats-card'>
      <div className='stats-card-header'>
        <div className='stats-card-title'>Your Stats</div>
        <div className='stats-card-subtitle'>
          (From {startDateFormatted} To {endDateFormatted})
        </div>
      </div>
      <div className='stats-card-body-container'>
        <div className='stats-card-body-left'>
          {/* TotalP&L */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>Total P&L</div>
            <div className='stats-card-body-left-item-value'>
              {checkIfEmpty(stats.netPL, true)}
            </div>
          </div>
          {/* Average Daily Trades */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Average Daily Trades
            </div>
            <div className='stats-card-body-left-item-value'>
              {checkIfEmpty(stats.avgDailyTrades, true)}
            </div>
          </div>
          {/* Average Losing Trade */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Average Winning Trade
            </div>
            <div className='stats-card-body-left-item-value'>
              {checkIfEmpty(Number(stats.avgWinningTrade.toFixed(2)), true)}
            </div>
          </div>
          {/* Average Losing Trade */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Average Losing Trade
            </div>
            <div className='stats-card-body-left-item-value'>
              {checkIfEmpty(stats.avgLosingTrade, true)}
            </div>
          </div>
          {/* Total Number of Trades */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Total Number of Trades
            </div>
            <div className='stats-card-body-left-item-value'>
              {stats.tradeCount ? stats.tradeCount : 'N/A'}
            </div>
          </div>
          {/* Number of Winning Trades */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Number of Winning Trades
            </div>
            <div className='stats-card-body-left-item-value'>
              {stats.winners ? stats.winners : 'N/A'}
            </div>
          </div>
          {/* Number of Losing Trades */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Number of Losing Trades
            </div>
            <div className='stats-card-body-left-item-value'>
              {stats.losers ? stats.losers : 'N/A'}
            </div>
          </div>
          {/* Number of Break-Even Trades */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Number of Break-Even Trades
            </div>
            <div className='stats-card-body-left-item-value'>
              {checkIfEmpty(stats.breakEvenTrades, false)}
            </div>
          </div>
          {/* Max Consecutive Wins */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Max Consecutive Wins
            </div>
            <div className='stats-card-body-left-item-value'>
              {checkIfEmpty(stats.maxConsecutiveTradeWins, false)}
            </div>
          </div>
          {/* Max Consecutive Losses */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Max Consecutive Losses
            </div>
            <div className='stats-card-body-left-item-value'>
              {checkIfEmpty(stats.maxConsecutiveTradeLosses, false)}
            </div>
          </div>
          {/* Total Commissions */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Total Commissions
            </div>
            <div className='stats-card-body-left-item-value'>
              {checkIfEmpty(stats.totalCommissions, true)}
            </div>
          </div>
          {/* Total Fees */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>Total Fees</div>
            <div className='stats-card-body-left-item-value'>
              {checkIfEmpty(stats.totalFees, true)}
            </div>
          </div>
          {/* Largest Profit */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Largest Profit
            </div>
            <div className='stats-card-body-left-item-value'>
              {checkIfEmpty(stats.largestTradeWin, true)}
            </div>
          </div>
          {/* Largest Loss */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>Largest Loss</div>
            <div className='stats-card-body-left-item-value'>
              {checkIfEmpty(stats.largestTradeLoss, true)}
            </div>
          </div>
          {/* Average Hold Time (all trades) */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Average Hold Time (all trades)
            </div>
            <div className='stats-card-body-left-item-value'>
              {convertToTime(stats.totalHoldTimeAllTrades)}
            </div>
          </div>
          {/* Average Hold Time (winning trades) */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Average Hold Time (winning trades)
            </div>
            <div className='stats-card-body-left-item-value'>
              {convertToTime(stats.totalHoldTimeWinningTrades)}
            </div>
          </div>
          {/* Average Hold Time (losing trades) */}
          <div className='stats-card-body-left-item'>
            <div className='stats-card-body-left-item-title'>
              Average Hold Time (losing trades)
            </div>
            <div className='stats-card-body-left-item-value'>
              {convertToTime(stats.totalHoldTimeLosingTrades)}
            </div>
          </div>
        </div>
        <div className='stats-card-body-right'>
          {/* Average Hold Time (break-even trades) */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>
              Average Hold Time (break-even trades)
            </div>
            <div className='stats-card-body-right-item-value'>
              {convertToTime(stats.totalHoldTimeBreakEvenTrades)}
            </div>
          </div>
          {/* Average Trade Profit & Loss */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>
              Average Trade Profit & Loss
            </div>
            <div className='stats-card-body-right-item-value'>
              {checkIfEmpty(Number(stats.avgTradePL.toFixed(2)), true)}
            </div>
          </div>
          {/* Profit Factor */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>
              Profit Factor
            </div>
            <div className='stats-card-body-right-item-value'>
              {stats.profitFactor ? stats.profitFactor.toFixed(2) : '0'}
            </div>
          </div>
          {/* Open Trades */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>Open Trades</div>
            <div className='stats-card-body-right-item-value'>
              {stats.openTrades ? stats.openTrades : '0'}
            </div>
          </div>
          {/* Total Trading Days */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>
              Total Trading Days
            </div>
            <div className='stats-card-body-right-item-value'>
              {stats.totalTradingDays ? stats.totalTradingDays : '0'}
            </div>
          </div>
          {/* Winning Days */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>Winning Days</div>
            <div className='stats-card-body-right-item-value'>
              {stats.daysWon}
            </div>
          </div>
          {/* Losing Days */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>Losing Days</div>
            <div className='stats-card-body-right-item-value'>
              {stats.daysLost}
            </div>
          </div>

          {/* Break-Even Days */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>
              Break-Even Days
            </div>
            <div className='stats-card-body-right-item-value'>
              {stats.breakEvenDays ? stats.breakEvenDays : '0'}
            </div>
          </div>
          {/* Logged Days */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>Logged Days</div>
            <div className='stats-card-body-right-item-value'>
              {stats.loggedDays ? stats.loggedDays : '0'}
            </div>
          </div>
          {/* Max Consecutive Winning Days */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>
              Max Consecutive Winning Days
            </div>
            <div className='stats-card-body-right-item-value'>
              {stats.maxConsecutiveWinningDays
                ? stats.maxConsecutiveWinningDays
                : '0'}
            </div>
          </div>
          {/* Max Consecutive Losing Days */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>
              Max Consecutive Losing Days
            </div>
            <div className='stats-card-body-right-item-value'>
              {stats.maxConsecutiveLosingDays
                ? stats.maxConsecutiveLosingDays
                : '0'}
            </div>
          </div>
          {/* Average Daily Profit & Loss */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>
              Average Daily Profit & Loss
            </div>
            <div className='stats-card-body-right-item-value'>
              {checkIfEmpty(stats.avgDailyPL, true)}
            </div>
          </div>
          {/* Average Winning Day Profit & Loss */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>
              Average Winning Day Profit & Loss
            </div>
            <div className='stats-card-body-right-item-value'>
              {checkIfEmpty(stats.avgWinningDayPL, true)}
            </div>
          </div>
          {/* Average Losing Day Profit & Loss */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>
              Average Losing Day Profit & Loss
            </div>
            <div className='stats-card-body-right-item-value'>
              {checkIfEmpty(stats.avgLosingDayPL, true)}
            </div>
          </div>
          {/* Largest Profitable Day */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>
              Largest Profitable Day
            </div>
            <div className='stats-card-body-right-item-value'>
              {checkIfEmpty(stats.largestProfitableDay, true)}
            </div>
          </div>
          {/* Largest Losing Day */}
          <div className='stats-card-body-right-item'>
            <div className='stats-card-body-right-item-title'>
              Largest Losing Day
            </div>
            <div className='stats-card-body-right-item-value'>
              {checkIfEmpty(stats.largestLosingDay, true)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
