import React, { useEffect, useRef, useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FixedList } from 'react-recycled-list'
import { useDispatch, useSelector } from 'react-redux'
import { find } from 'lodash'
import { LoadingSpinner } from 'src/core/components'
import { fetchWatchlist } from 'src/core/store/features/watchlist/watchlist'
import { RootState } from 'src/core/store/Store'

import './styles.scss'

import { WatchList } from '../../types'
import WatchlistDetailsModal from '../watchlistDetailsModal/WatchlistDetailsModal'

type RenderRow = {
  firstRenderedRowIndex: number
  firstRenderedDataIndex: number
  lastRenderedRowIndex: number
  lastRenderedDataIndex: number
  lastRowIndex: number
}

type WatchListTableProps = {
  height: number
  isSmall?: boolean
  setWatchListDetailsPage?: (id: string | null) => void
}

export const WatchListTable = ({
  height,
  isSmall,
  setWatchListDetailsPage,
}: WatchListTableProps) => {
  const [t] = useTranslation()

  const dispatch: any = useDispatch()
  const {
    data: watchList,
    loading: watchListLoading,
    isFetchedAll: isFetchedAllWatchList,
    isFetching: isFetchingWatchList,
    error,
  } = useSelector((state: RootState) => state.watchlist)

  const [formattedWatchList, setFormattedWatchList] = useState<
    (WatchList | undefined)[]
  >([])

  useEffect(() => {
    if (!watchList || watchList.length === 0) {
      return
    }
    if (!isFetchedAllWatchList) {
      return setFormattedWatchList([...watchList, undefined])
    } else {
      setFormattedWatchList(watchList)
    }
  }, [watchList])

  /**
   * Method used to fetch more tickers from the watchlist while scrolling
   */
  const getWatchList = async () => {
    const lastWatchList =
      watchList && watchList.length > 0
        ? watchList[watchList.length - 1]
          ? watchList[watchList.length - 1]
          : watchList[watchList.length - 2]
        : undefined

    dispatch(fetchWatchlist({ lastDocumentId: lastWatchList?.id }))
  }

  /**
   * Method that is triggered whenever the last row in the table is rendered
   */
  const onRenderedRowChange = (renderInfo: RenderRow) => {
    const { lastRenderedRowIndex, lastRowIndex } = renderInfo
    // If the last row is rendered (NOT visible yet!) and we are not already loading data, we fetch new data
    // If you want to fetch data when the last row is visible then use onVisibleRowChange
    if (
      !isFetchingWatchList &&
      !watchListLoading &&
      !isFetchedAllWatchList &&
      lastRowIndex > 0 &&
      lastRenderedRowIndex === lastRowIndex
    ) {
      getWatchList()
    }
  }
  const [watchListDetails, setWatchListDetails] = useState<string | null>(null)

  const displayWatchListDetails = (details: WatchList) => {
    setWatchListDetails(details.id)
    if (setWatchListDetailsPage) setWatchListDetailsPage(details.id)
  }

  const hideWatchListDetails = () => {
    setWatchListDetails(null)
  }

  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (setWatchListDetailsPage)
      if (listRef.current)
        listRef.current.parentElement?.classList.add('watch-list')
  }, [listRef])

  return watchListLoading ? (
    <div
      className={
        isSmall
          ? 'p-3'
          : 'p-3 d-flex d-flex align-items-center justify-content-center'
      }
    >
      <LoadingSpinner />
    </div>
  ) : error ? (
    <Container>
      <Row className='mt-4'>
        <Col sm={12}>
          <Alert variant='danger'>{t('common:loadFailed')}</Alert>
        </Col>
      </Row>
    </Container>
  ) : (
    <>
      <FixedList
        height={height}
        listProps={{
          ref: listRef,
        }}
        rowComponent={(props) => {
          return (
            <WatchListRow
              {...props}
              displayWatchListDetails={displayWatchListDetails}
              isSmall={isSmall}
            />
          )
        }}
        data={formattedWatchList}
        rowHeight={isSmall ? 80 : 100}
        onRenderedRowChange={onRenderedRowChange}
      />
      {watchListDetails && !setWatchListDetailsPage && (
        <WatchlistDetailsModal
          data={find(watchList, { id: watchListDetails })}
          hideWatchlistDetails={hideWatchListDetails}
        />
      )}
    </>
  )
}

type WatchListRowProps = {
  data: WatchList[]
  dataIndex: number
  top: number
  height: number
  displayWatchListDetails: Function
  isSmall?: boolean
}

const formatter = Intl.NumberFormat('en', {
  maximumFractionDigits: 2,
})

// eslint-disable-next-line
const WatchListRow = React.memo((props: WatchListRowProps) => {
  // the data here is the same data that is passed into the FixedList
  const { data, dataIndex, top, height, displayWatchListDetails, isSmall } =
    props

  const watchList = data[dataIndex]

  const getPriceDiff = (
    previousPrice: number | undefined,
    currentPrice: number | undefined,
  ) => {
    currentPrice = currentPrice ?? 0
    previousPrice = previousPrice ?? currentPrice

    return currentPrice - previousPrice
  }

  const getPriceDiffPercentage = (
    previousPrice: number | undefined,
    currentPrice: number | undefined,
  ) => {
    currentPrice = currentPrice ?? 0
    previousPrice = previousPrice ?? currentPrice

    const diff = getPriceDiff(previousPrice, currentPrice)

    return previousPrice ? (diff / previousPrice) * 100 : 0
  }

  let percentageClass = ''

  if (watchList !== undefined) {
    const change = getPriceDiffPercentage(
      watchList.ticker?.financials?.regularMarketPrice,
      watchList.ticker?.lastPrice,
    )

    percentageClass = change === 0 ? '' : change > 0 ? 'success' : 'danger'
  }

  // Important!, make sure you inline-style your component with the the provided top, height. Also make sure to set your container element to position absolute
  return (
    <div className={`${isSmall ? 'small' : ''} ${percentageClass}`}>
      <div
        style={{ top, height, position: 'absolute' }}
        className={`react-recycled-row watch-list-row ${
          isSmall ? 'px-4' : ''
        } ${isSmall && dataIndex === 0 ? 'pt-2' : ''}`}
        onClick={() => displayWatchListDetails(watchList)}
      >
        {watchList === undefined ? (
          <div className='h-100 d-flex align-items-center justify-content-center'>
            <LoadingSpinner />
          </div>
        ) : (
          <div className='m-0 p-0 h-100 d-flex align-items-center justify-content-between'>
            <div className='m-0 p-0'>
              <p className='symbol mb-1'>{watchList.ticker?.symbol}</p>
              <p className='name'>
                {watchList.ticker?.companyName
                  ? watchList.ticker?.companyName
                  : watchList.name}
              </p>
            </div>
            <div className='m-0 p-0 d-flex flex-column align-items-end'>
              <p className='price mb-1'>
                $
                {watchList.ticker?.lastPrice
                  ? formatter.format(watchList.ticker?.lastPrice)
                  : 0}
              </p>
              <p className='percentage'>
                $
                {formatter.format(
                  getPriceDiff(
                    watchList.ticker?.financials?.regularMarketPrice,
                    watchList.ticker?.lastPrice,
                  ),
                )}{' '}
                (
                {formatter.format(
                  getPriceDiffPercentage(
                    watchList.ticker?.financials?.regularMarketPrice,
                    watchList.ticker?.lastPrice,
                  ),
                )}
                %)
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
})
