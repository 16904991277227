import Form from 'react-bootstrap/Form'
import { FormCheckProps } from 'react-bootstrap/FormCheck'
import cls from 'classnames'

import './styles.scss'

type CheckBoxProps = FormCheckProps & {
  name: string
  errorMsg?: string
  className?: string
}

const CheckBox = ({
  name,
  children,
  onChange,
  checked,
  errorMsg,
  className,
  ...rest
}: CheckBoxProps) => {
  return (
    <div id='custom-checkbox'>
      <Form.Check
        {...rest}
        className={cls('checkbox-component', className)}
        type='checkbox'
      >
        <Form.Check.Input
          checked={checked}
          type='checkbox'
          id={name}
          onChange={onChange}
        />
        <Form.Check.Label className='label text' htmlFor={name}>
          {children}
        </Form.Check.Label>
      </Form.Check>
      {errorMsg && <p className='error'>{errorMsg}</p>}
    </div>
  )
}

export default CheckBox
