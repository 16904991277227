import { useTranslation } from 'react-i18next'

import './styles.scss'

import { WatchList } from '../../types'
import { Description, SectionTitle, WatchListInfoText } from './components'

interface Props {
  data: WatchList | undefined
}

const compactFormatter = Intl.NumberFormat('en', {
  notation: 'compact',
  maximumFractionDigits: 2,
})

export const WatchListAbout = ({ data }: Props) => {
  const [t] = useTranslation('')

  return (
    <>
      {/* About */}
      <SectionTitle title={t('watchlist:About')} />
      {/* Description */}
      <Description description={data?.ticker?.description} />
      {/* ceo, employees, headquarters & founded */}
      <div className='about d-flex flex-row justify-content-between algin-items-center'>
        {/* ceo */}
        <WatchListInfoText title='ceo' info={data?.ticker?.CEO} />
        {/* employees */}
        <WatchListInfoText
          title='employees'
          info={
            data?.ticker?.employees
              ? compactFormatter.format(data?.ticker?.employees)
              : '-'
          }
        />
        {/* headquarters */}
        <WatchListInfoText
          title='headquarters'
          info={`${data?.ticker?.city ? `${data?.ticker?.city},` : ''} ${
            data?.ticker?.state
          }`}
        />
        {/* founded */}
        <WatchListInfoText title='founded' info='-' />
      </div>
      {/* key Statistics */}
      <SectionTitle title={t('Key Statistics')} />
      {/* marketCap, priceEarnings, dividentYield & averageVolume */}
      <div className='d-flex flex-row justify-content-between'>
        {/* marketCap  */}
        <WatchListInfoText
          title='marketCap'
          info={
            data?.ticker?.financials && data?.ticker?.financials.marketCap
              ? compactFormatter.format(data?.ticker?.financials.marketCap)
              : '-'
          }
        />
        {/* priceEarnings */}
        <WatchListInfoText
          title='priceEarnings'
          info={
            data?.ticker?.financials &&
            data?.ticker?.financials.regularMarketVolume
              ? compactFormatter.format(
                  data?.ticker?.financials.regularMarketVolume,
                )
              : '-'
          }
        />
        {/* dividentYield */}
        <WatchListInfoText
          title='dividentYield'
          info={
            data?.ticker?.financials && data?.ticker?.financials.epsForward
              ? compactFormatter.format(data?.ticker?.financials.epsForward)
              : '-'
          }
        />
        {/* averageVolume */}
        <WatchListInfoText
          title='averageVolume'
          info={
            data?.ticker?.financials &&
            data?.ticker?.financials.averageDailyVolume10Day
              ? compactFormatter.format(
                  data?.ticker?.financials.averageDailyVolume10Day,
                )
              : '-'
          }
        />
      </div>
      {/* highToday, lowToday, openPrice & volume */}
      <div className='d-flex flex-row justify-content-between mt-4'>
        {/* highToday */}
        <WatchListInfoText
          title='highToday'
          info={
            data?.ticker?.financials &&
            data?.ticker?.financials.regularMarketDayHigh
              ? compactFormatter.format(
                  data?.ticker?.financials.regularMarketDayHigh,
                )
              : '-'
          }
        />
        {/* lowToday */}
        <WatchListInfoText
          title='lowToday'
          info={
            data?.ticker?.financials &&
            data?.ticker?.financials.regularMarketDayLow
              ? compactFormatter.format(
                  data?.ticker?.financials.regularMarketDayLow,
                )
              : '-'
          }
        />
        {/* openPrice */}
        <WatchListInfoText
          title='openPrice'
          info={
            data?.ticker?.financials &&
            data?.ticker?.financials.regularMarketOpen
              ? compactFormatter.format(
                  data?.ticker?.financials.regularMarketOpen,
                )
              : '-'
          }
        />
        {/* volume */}
        <WatchListInfoText
          title='volume'
          info={
            data?.ticker?.financials &&
            data?.ticker?.financials.regularMarketVolume
              ? compactFormatter.format(
                  data?.ticker?.financials.regularMarketVolume,
                )
              : '-'
          }
        />
      </div>
      {/* 52WeekHigh & 52WeekLow */}
      <div className='w-100 d-flex flex-row justify-content-between mt-4'>
        {/* 52WeekHigh */}
        <WatchListInfoText
          title='52WeekHigh'
          info={
            data?.ticker?.financials &&
            data?.ticker?.financials.fiftyTwoWeekHigh
              ? compactFormatter.format(
                  data?.ticker?.financials.fiftyTwoWeekHigh,
                )
              : '-'
          }
        />
        <div className='w-100' />
        <div className='w-100' />
        {/* 52WeekLow */}
        <WatchListInfoText
          title='52WeekLow'
          info={
            data?.ticker?.financials && data?.ticker?.financials.fiftyTwoWeekLow
              ? compactFormatter.format(
                  data?.ticker?.financials.fiftyTwoWeekLow,
                )
              : '-'
          }
        />
      </div>
    </>
  )
}
