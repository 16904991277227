import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Unsubscribe } from 'firebase/auth'
import { WatchListService } from 'src/core/services'
import {
  setSubscribedWatchlistData,
  setSubscribedWatchlistError,
} from 'src/core/store/features/watchlist/watchlist'

export const useSubWatchlist = () => {
  const dispatch = useDispatch()
  const unsubscribeWatchlistRef = useRef<Unsubscribe | null>()
  const unsubscribeTickersRef = useRef<Unsubscribe | null>()
  const unsubscribeDSRef = useRef<Unsubscribe | null>()

  useEffect(() => {
    subscribeWatchlist()
    return () => {
      if (unsubscribeWatchlistRef.current) {
        unsubscribeWatchlistRef?.current()
      }
      if (unsubscribeTickersRef.current) {
        unsubscribeTickersRef.current()
      }
      if (unsubscribeDSRef.current) {
        unsubscribeDSRef.current()
      }
    }
  }, [])

  /**
   * Method used to get real-time watchlist. It keeps updating the last {WATCHLIST_SUBSCRIPTION_COUNT} records added in the database
   */
  const subscribeWatchlist = useCallback(async () => {
    // delete any subscribtion before getting new data
    if (unsubscribeWatchlistRef.current) {
      unsubscribeWatchlistRef?.current()
    }
    unsubscribeWatchlistRef.current =
      await WatchListService.subscribeToWatchList(
        ({ data, unsubscribeTickers, unsubscribeDS }, error: any) => {
          unsubscribeTickersRef.current = unsubscribeTickers
          unsubscribeDSRef.current = unsubscribeDS
          if (!error) {
            dispatch(setSubscribedWatchlistData({ data }))
          } else {
            dispatch(setSubscribedWatchlistError({ error }))
          }
        },
      )
  }, [])
}
