import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore'
import {
  NEWS_PER_SCROLL,
  NEWS_SUBSCRIPTION_COUNT,
} from 'src/modules/news/types'

import { reportError } from '../../errorReporter'
import { FireStoreDB } from '../Firebase'

class NewsService {
  COLLECTION_NAME = 'stockNews'

  /**
   * subscribeToNews
   * Subscribe to News sorted by the date
   */
  async subscribeToNews(onValue: (data: any, error?: any) => void) {
    try {
      const docRef = query(
        collection(FireStoreDB, this.COLLECTION_NAME),
        orderBy('date', 'desc'),
        limit(NEWS_SUBSCRIPTION_COUNT),
      )
      const unsubscribe = onSnapshot(
        docRef,
        (docSnap) => {
          onValue(docSnap.docs.map((_doc) => ({ id: _doc.id, ..._doc.data() })))
        },
        (error) => {
          console.log(error)
        },
      )
      return unsubscribe
    } catch (e) {
      reportError(e)
      onValue(null, e as Error)
      return null
    }
  }

  /**
   * GET News with pagination (scrolling edition)
   * @param onValue
   * @param lastDocumentId
   */
  async getNews(lastDocumentId?: string) {
    let docRef = query(
      collection(FireStoreDB, `${this.COLLECTION_NAME}`),
      limit(NEWS_PER_SCROLL),
      orderBy('date', 'desc'),
    )
    if (lastDocumentId) {
      const lastDocRef = doc(FireStoreDB, this.COLLECTION_NAME, lastDocumentId)
      const lastDocumentSnap = await getDoc(lastDocRef)

      docRef = query(
        collection(FireStoreDB, this.COLLECTION_NAME),
        orderBy('date', 'desc'),
        startAfter(lastDocumentSnap),
        limit(NEWS_PER_SCROLL),
      )
    }

    const docs = await getDocs(docRef)
    return docs.docs.map((_doc: any) => ({ id: _doc.id, ..._doc.data() }))
  }
}

export default new NewsService()
