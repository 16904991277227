import React, { useContext, useEffect, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { useTranslation } from 'react-i18next'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import cls from 'classnames'
import {
  CheckBox,
  DefaultButton,
  DefaultText,
  SectionTitle,
} from 'src/core/components'
import { RootState } from 'src/core/store/Store'
import { ThemeContext } from 'src/core/utils/theme/ThemeContext'

import './styles.scss'

import { TFilterCondition, TOption } from '../../types'
import { FilterCondition } from '../filterCondition/FilterCondition'

const dropdownOptions = [
  {
    eventKey: 'is',
    label: 'Is',
  },
  {
    eventKey: 'is_not',
    label: 'Is Not',
  },
  {
    eventKey: 'is_greater_than_or_equal_to',
    label: 'Is Greater Than Or Equal To',
  },
  {
    eventKey: 'is_less_than_or_equal_to',
    label: 'Is Less Than Or Equal To',
  },
  {
    eventKey: 'is_greater_than',
    label: 'Is Greater Than',
  },
  {
    eventKey: 'is_less_than',
    label: 'Is Less Than',
  },
]

type FiltersModalProps = {
  onHide: () => void
  show: boolean
  onApplyFilter: (
    filters: {
      [condition: string]: TFilterCondition
    } | null,
  ) => void
  initialConditions: { [condition: string]: TFilterCondition } | null
}

const contractTypeValueOpts: TOption[] = [
  {
    label: 'CALL',
    value: 'CALL',
  },
  {
    label: 'PUT',
    value: 'PUT',
  },
]

const consolidationTypeValueOpts: TOption[] = [
  {
    label: 'Sweep',
    value: 'Sweep',
  },
  {
    label: 'Split',
    value: 'Split',
  },
  {
    label: 'Block',
    value: 'Block',
  },
]

export const FiltersModal = ({
  show,
  onHide,
  onApplyFilter,
  initialConditions,
  ...rest
}: FiltersModalProps) => {
  const [t] = useTranslation()
  const { theme } = useContext(ThemeContext)
  const tickerOptions = useSelector((state: RootState) => state.filters.tickers)
  const [conditions, setConditions] = useState<{
    [condition: string]: TFilterCondition
  } | null>(null)

  const [premiumFilterOption, setPremiumFilterOption] = useState({
    title: dropdownOptions[3].label,
    value: dropdownOptions[3].eventKey,
  })

  const [dteFilterOption, setDteFilterOption] = useState({
    title: dropdownOptions[0].label,
    value: dropdownOptions[0].eventKey,
  })

  useEffect(() => {
    if (show) {
      setConditions(initialConditions)
    }
  }, [show])

  const onChangeCondition = (conditionName: string, val: TFilterCondition) => {
    // if (!val || val.value === null) {
    //   return
    // }
    setConditions((prev: any) => ({
      ...prev,
      [conditionName]: {
        ...val,
      },
    }))
  }

  const onClickReset = () => {
    setConditions({})
    setPremiumFilterOption({
      title: dropdownOptions[3].label,
      value: dropdownOptions[3].eventKey,
    })
    setDteFilterOption({
      title: dropdownOptions[0].label,
      value: dropdownOptions[0].eventKey,
    })
    onApplyFilter(null)
  }

  return (
    <Modal
      {...rest}
      show={show}
      size='xl'
      id='filters-modal'
      aria-labelledby='filters-modal'
      centered
      className={cls('filters-modal', `theme-${theme}`)}
      onHide={onHide}
    >
      <Modal.Body className='filters-modal_body'>
        <Row className='header'>
          <Col md={8}>
            <SectionTitle size='lg' title={t('orderFlow:filters.filters')} />
          </Col>
          <Col md={4} className='text-end'>
            <AiFillCloseCircle onClick={onHide} className='close-icon' />
          </Col>
        </Row>
        <hr />
        <Row className='filters'>
          {/* Ticker */}
          <Col sm={12} md={6}>
            <FilterCondition
              name='ticker'
              label={t('orderFlow:table.ticker')}
              valueOptions={tickerOptions}
              value={conditions ? conditions?.ticker : null}
              initialValue={
                initialConditions ? initialConditions?.ticker : null
              }
              onChange={(val) => onChangeCondition('ticker', val)}
            />
          </Col>
          {/* Contract Type */}
          <Col sm={12} md={6}>
            <FilterCondition
              name='contractType'
              label={t('orderFlow:filters.contractType')}
              valueOptions={contractTypeValueOpts}
              value={conditions ? conditions?.contract : null}
              initialValue={
                initialConditions ? initialConditions?.contract : null
              }
              onChange={(val) => onChangeCondition('contract', val)}
            />
          </Col>
          {/* Consolidation Type */}
          <Col sm={12} md={6} className='mt-2'>
            <FilterCondition
              name='consolidationType'
              label={t('orderFlow:filters.consolidationType')}
              valueOptions={consolidationTypeValueOpts}
              value={conditions ? conditions?.type : null}
              initialValue={initialConditions ? initialConditions?.type : null}
              onChange={(val) => onChangeCondition('type', val)}
            />
          </Col>
          {/* Boom Algo */}
          <Col sm={12} md={6} className='mt-2'>
            <FilterCondition
              name='boomAlgo'
              label={t('orderFlow:filters.boomAlgo')}
              type='range'
              value={conditions ? conditions?.boomAlgo : null}
              initialValue={
                initialConditions ? initialConditions?.boomAlgo : null
              }
              onChange={(val) =>
                onChangeCondition('boomAlgo', {
                  operator: {
                    title: 'Is Greater Than Or Equal To',
                    value: 'is_greater_than_or_equal_to',
                  },
                  value: val.value,
                })
              }
            />
          </Col>
          {/* Expiration Date */}
          <Col sm={12} md={6} className='mt-2'>
            <FilterCondition
              name='expirationDate'
              label={t('orderFlow:filters.expirationDate')}
              type='date'
              value={conditions ? conditions?.expirationDate : null}
              initialValue={
                initialConditions ? initialConditions?.expirationDate : null
              }
              onChange={(val) =>
                onChangeCondition('expirationDate', {
                  operator: {
                    title: 'Is',
                    value: 'is',
                  },
                  value: val.value,
                })
              }
            />
          </Col>
          {/* Premium Filter */}
          <Col sm={12} md={6} className='mt-2'>
            <div className='d-flex align-items-center justify-content-between'>
              <FilterCondition
                name='premium'
                label={t('orderFlow:filters.premium')}
                type='input'
                value={conditions ? conditions?.premium : null}
                initialValue={
                  initialConditions ? initialConditions?.premium : null
                }
                onChange={(val) =>
                  onChangeCondition('premium', {
                    operator: dteFilterOption,
                    value: val.value,
                  })
                }
              />
              <Form>
                <Form.Group controlId='filterSelect'>
                  <div className='dropdown'>
                    <div className='label-div'>
                      <Form.Label />
                    </div>
                    <div>
                      <Dropdown
                        onSelect={(eventKey: any, event: any) => {
                          onChangeCondition('premium', {
                            operator: {
                              title: event.target.innerText,
                              value: eventKey,
                            },
                            value: conditions?.premium?.value as number,
                          })
                          setDteFilterOption({
                            title: event.target.innerText,
                            value: eventKey,
                          })
                        }}
                      >
                        <Dropdown.Toggle
                          variant='primary'
                          id='dropdown-basic'
                          disabled={!conditions?.premium?.value}
                          className='dropdown-toggle'
                        >
                          {dteFilterOption.title || 'Select a filter'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='dropdown-menu'>
                          {dropdownOptions.map((option, index) => {
                            return (
                              <Dropdown.Item
                                eventKey={option.eventKey}
                                key={index}
                              >
                                {option.label}
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </div>
          </Col>
          {/* Days To Expiration */}
          <Col sm={12} md={6} className='mt-2'>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  maxWidth: '270px',
                  marginRight: '30px',
                }}
              >
                <FilterCondition
                  name='dte'
                  label={t('orderFlow:filters.dte')}
                  type='input'
                  value={conditions ? conditions?.dte : null}
                  initialValue={
                    initialConditions ? initialConditions?.dte : null
                  }
                  onChange={(val) =>
                    onChangeCondition('dte', {
                      operator: premiumFilterOption,
                      value: val.value,
                    })
                  }
                  isPrefixNeeded={false}
                />
              </div>
              <Form>
                <Form.Group controlId='filterSelect'>
                  <div className='dropdown'>
                    <div className='label-div'>
                      <Form.Label />
                    </div>
                    <div>
                      <Dropdown
                        onSelect={(eventKey: any, event: any) => {
                          onChangeCondition('dte', {
                            operator: {
                              title: event.target.innerText,
                              value: eventKey,
                            },
                            value: conditions?.dte?.value as number,
                          })
                          setPremiumFilterOption({
                            title: event.target.innerText,
                            value: eventKey,
                          })
                        }}
                      >
                        <Dropdown.Toggle
                          variant='primary'
                          id='dropdown-basic'
                          disabled={!conditions?.dte?.value}
                          className='dropdown-toggle'
                        >
                          {premiumFilterOption.title || 'Select a filter'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='dropdown-menu'>
                          {dropdownOptions.map((option, index) => {
                            if (
                              option.eventKey !== 'is' &&
                              option.eventKey !== 'is_not'
                            ) {
                              return (
                                <Dropdown.Item
                                  eventKey={option.eventKey}
                                  key={index}
                                >
                                  {option.label}
                                </Dropdown.Item>
                              )
                            }
                            return null
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
        <Row className='mt-3'>
          {/* IsGoldenSweep */}
          <CheckBox
            name='IsGoldenSweep'
            checked={!!conditions?.isGoldenSweep?.value}
            onChange={(e: any) =>
              onChangeCondition('isGoldenSweep', {
                operator: e.target.checked
                  ? { title: 'Is', value: 'is' }
                  : null,
                value: e.target.checked,
              })
            }
          >
            <div className='d-inline-flex'>
              <span className='me-2'>
                <DefaultText title={t('orderFlow:filters.showOnly')} />
              </span>
              <DefaultText
                color='secondary'
                title={t('orderFlow:filters.goldenSweeps')}
              />
            </div>
          </CheckBox>
          {/* isVolAboveOI */}
          <CheckBox
            name='isVolAboveOI'
            checked={!!conditions?.isVolAboveOI?.value}
            onChange={(e: any) =>
              onChangeCondition('isVolAboveOI', {
                operator: e.target.checked
                  ? { title: 'Is', value: 'is' }
                  : null,
                value: e.target.checked,
              })
            }
          >
            <div className='d-inline-flex'>
              <span className='me-2'>
                <DefaultText title={t('orderFlow:filters.showOnly')} />
              </span>
              <DefaultText title={t('orderFlow:filters.volOi')} />
            </div>
          </CheckBox>
        </Row>
        <div className='actions'>
          <DefaultButton
            title={t('orderFlow:filters:resetFilters')}
            variant='outlined'
            onClick={onClickReset}
          />
          <DefaultButton
            title={t('orderFlow:filters:applyFilters')}
            onClick={() => onApplyFilter(conditions)}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}
