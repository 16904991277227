import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { TFilterCondition, TOption } from 'src/modules/orderFlow/types'
import { Ticker } from 'src/modules/watchList/types'

type SortType = 'asc' | 'desc'

export type FiltersState = {
  tickers: TOption[]
  filters: {
    [filterConditionName: string]: TFilterCondition
  } | null
  openModal: boolean
  sortBy?: {
    [key: string]: SortType
  } | null
}

const initialState: FiltersState = {
  tickers: [],
  filters: null,
  openModal: false,
  sortBy: {
    time: 'desc',
  },
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters: (
      state,
      action: PayloadAction<{
        filters: {
          [filterConditionName: string]: TFilterCondition
        } | null
      }>,
    ) => {
      state.filters = action.payload.filters
    },
    setTickers: (
      state,
      action: PayloadAction<{
        tickers: Ticker[] | null
      }>,
    ) => {
      state.tickers = action.payload.tickers
        ? action.payload.tickers.map((ticker: any) => {
            return { label: ticker.id, value: ticker.id }
          })
        : []
    },
    setSortBy: (
      state,
      action: PayloadAction<{
        sortBy: {
          [key: string]: SortType
        } | null
      }>,
    ) => {
      state.sortBy = action.payload.sortBy
    },
    setOpenFilterModal: (
      state,
      action: PayloadAction<{
        openModal: boolean
      }>,
    ) => {
      state.openModal = action.payload.openModal
    },
  },
})

export const { setFilters, setTickers, setOpenFilterModal, setSortBy } =
  filtersSlice.actions

export default filtersSlice.reducer
