import { Unsubscribe } from 'firebase/auth'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore'
import { findIndex } from 'lodash'
import {
  WATCH_LIST_PER_SCROLL,
  WATCH_LIST_SUBSCRIPTION_COUNT,
} from 'src/modules/watchList/types'

import { reportError } from '../../errorReporter'
import { FirebaseAuth, FireStoreDB } from '../Firebase'

class WatchListService {
  /**
   * subscribeToWatchList
   * Subscribe to Watch List sorted by SortOrder
   */
  async subscribeToWatchList(onValue: (data: any, error?: any) => void) {
    const COLLECTION_NAME = `users/${FirebaseAuth.currentUser?.uid}/watchlist`
    try {
      const docRef = query(
        collection(FireStoreDB, COLLECTION_NAME),
        orderBy('sortOrder', 'asc'),
        limit(WATCH_LIST_SUBSCRIPTION_COUNT),
      )
      let unsubscribeTickers: Unsubscribe | null
      let unsubscribeDS: Unsubscribe | null

      const unsubscribe = onSnapshot(
        docRef,
        async (docSnap) => {
          const docs: any = []
          const tickerIds = []
          for (const _doc of docSnap.docs) {
            const watchlistData = _doc.data()
            tickerIds.push(watchlistData.ticker.id)
            docs.push({
              id: _doc.id,
              ...watchlistData,
              tickerId: watchlistData.ticker.id,
            })
          }
          if (unsubscribeTickers) {
            unsubscribeTickers()
          }

          if (unsubscribeDS) {
            unsubscribeDS()
          }
          const batches = []
          for (let i = 0; i < tickerIds.length; i += 10) {
            batches.push(tickerIds.slice(i, i + 10))
          }
          const tickerDocs = await Promise.all(
            batches.map((batch) =>
              getDocs(
                query(
                  collection(FireStoreDB, 'tickers'),
                  where('symbol', 'in', batch),
                ),
              ),
            ),
          )
          const dsDocs = await Promise.all(
            batches.map(async (batch) =>
              getDocs(
                query(
                  collection(
                    FireStoreDB,
                    'dailySentiment',
                    await this.getLastDate(),
                    'tickers',
                  ),
                  where('id', 'in', batch),
                ),
              ),
            ),
          )
          for (const tickerDoc of tickerDocs) {
            for (const ticker of tickerDoc.docs) {
              const index = findIndex(docs, { tickerId: ticker.id })
              docs[index] = { ...docs[index], ticker: ticker.data() }
            }
          }
          for (const dsDoc of dsDocs) {
            for (const ds of dsDoc.docs) {
              const index = findIndex(docs, { tickerId: ds.id })
              docs[index] = { ...docs[index], ds: ds.data() }
            }
          }
          onValue({ data: docs, unsubscribeTickers, unsubscribeDS })

          //   unsubscribeTickers = onSnapshot(
          //     query(
          //       collection(FireStoreDB, 'tickers'),
          //       where('symbol', 'in', tickerIds.splice),
          //     ),
          //     async (tickerSnap) => {
          //       for (const tickerDoc of tickerSnap.docs) {
          //         const index = findIndex(docs, { tickerId: tickerDoc.id })
          //         docs[index] = { ...docs[index], ticker: tickerDoc.data() }
          //         if (docSnap.docs.length === docs.length) {
          //           onValue({ data: docs, unsubscribeTickers, unsubscribeDS })
          //         }
          //       }
          //     },
          //   )
          //   unsubscribeDS = onSnapshot(
          //     query(
          //       collection(
          //         FireStoreDB,
          //         'dailySentiment',
          //         await this.getLastDate(),
          //         'tickers',
          //       ),
          //       where('id', 'in', tickerIds),
          //     ),
          //     async (dsSnap) => {
          //       for (const dsDoc of dsSnap.docs) {
          //         const index = findIndex(docs, { tickerId: dsDoc.id })
          //         docs[index] = { ...docs[index], dailySentiment: dsDoc.data() }
          //         if (docSnap.docs.length === docs.length) {
          //           onValue({ data: docs, unsubscribeTickers, unsubscribeDS })
          //         }
          //       }
          //     },
          //   )
        },
        (error) => {
          console.log(error)
        },
      )
      return unsubscribe
    } catch (e) {
      reportError(e)
      onValue(null, e as Error)
      return null
    }
  }

  /**
   * GET Watch List with pagination (scrolling edition)
   * @param lastDocumentId
   */
  async getWatchList(lastDocumentId?: string) {
    const COLLECTION_NAME = `users/${FirebaseAuth.currentUser?.uid}/watchlist`
    let docRef = query(
      collection(FireStoreDB, `${COLLECTION_NAME}`),
      limit(WATCH_LIST_PER_SCROLL),
      orderBy('sortOrder', 'asc'),
    )
    if (lastDocumentId) {
      const lastDocRef = doc(FireStoreDB, COLLECTION_NAME)
      const lastDocumentSnap = await getDoc(lastDocRef)

      docRef = query(
        collection(FireStoreDB, COLLECTION_NAME),
        orderBy('sortOrder', 'asc'),
        startAfter(lastDocumentSnap),
        limit(WATCH_LIST_PER_SCROLL),
      )
    }

    const docs = await getDocs(docRef)
    return docs.docs.map((_doc: any) => ({ id: _doc.id, ..._doc.data() }))
  }

  async getChartWatchList(docId: string | null) {
    const today = new Date()
    const todayFormatted = today.toISOString().split('T')[0]
    const COLLECTION_NAME = `tickers/${docId}/oneDayChartTimes/${todayFormatted}`
    const d = await getDoc(doc(FireStoreDB, COLLECTION_NAME))
    if (!d.exists()) {
      const yesterday = new Date(today.setDate(today.getDate() - 1))
      const yesterdayFormatted = yesterday.toISOString().split('T')[0]
      const COLLECTION_NAME = `tickers/${docId}/oneDayChartTimes/${yesterdayFormatted}`
      const d = await getDoc(doc(FireStoreDB, COLLECTION_NAME))
      return d.data()
    }
    return d.data()
  }

  async getLastDate(): Promise<string> {
    try {
      let result = ''
      const q = query(collection(FireStoreDB, 'dailySentiment'))
      const querySnapshot = await getDocs(q)

      querySnapshot.forEach((dailySenti) => {
        // return last document date exist
        result = dailySenti.id
      })
      return result
    } catch (error) {
      reportError(error)
      return ''
    }
  }
}

export default new WatchListService()
