import { useState } from 'react'
import { Tab } from 'react-bootstrap'
import { AuthHeader } from 'src/core/components'

import './styles.scss'

import Information from './components/information/Information'
import SelectPackage from './components/selectPackage/SelectPackage'

const RegisterPage = () => {
  const [selectedPlan, setSelectedPlan] = useState(undefined)

  const selectedTab = selectedPlan ? 'information' : 'package'

  return (
    <div className='register-page'>
      <Tab.Container
        onSelect={() => {
          setSelectedPlan(undefined)
        }}
        activeKey={selectedTab}
        defaultActiveKey={selectedTab}
      >
        <AuthHeader />
        <div className='register-page-body'>
          <div className='section-content p-0'>
            <Tab.Content>
              <Tab.Pane eventKey='package'>
                <SelectPackage setSelectedPlan={setSelectedPlan} />
              </Tab.Pane>
              <Tab.Pane eventKey='information'>
                <Information selectedPlan={selectedPlan} />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  )
}

export default RegisterPage
