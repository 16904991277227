import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Unsubscribe } from 'firebase/auth'
import { differenceBy, flatten, uniqBy } from 'lodash'
import { OrdersService } from 'src/core/services'
import {
  ORDERS_PER_SCROLL,
  ORDERS_SUBSCRIPTION_COUNT,
} from 'src/modules/orderFlow/types'

import {
  FetchOrders,
  OrderState,
  SetSubscribedOrdersData,
  SetSubscribedOrdersError,
} from './types'

const initialState: OrderState = {
  error: null,
  unsub: undefined,
  data: [],
  loading: true,
  isFetchedAll: false,
  isFetching: false,
}

export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async ({
    // lastDocumentId,
    conditions,
  }: FetchOrders) => {
    const response = await OrdersService.getOrders(
      conditions,
      //  lastDocumentId
    )
    return { data: response }
  },
)

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setSubscribedOrdersData: (
      state,
      action: PayloadAction<SetSubscribedOrdersData>,
    ) => {
      const { data } = action.payload

      if (data.length < ORDERS_SUBSCRIPTION_COUNT) {
        state.isFetchedAll = true
      }

      // append the animation date to NEW orders only and add the to the start of the orders array
      const newOrders: any =
        state.data.length > 0
          ? differenceBy(data, state.data, 'id').map((newOrder) => {
              return {
                ...newOrder,
                animationDate: new Date(),
              }
            })
          : data
      state.data = flatten([newOrders, state.data])

      state.loading = false
    },
    setSubscribedOrdersError: (
      state,
      action: PayloadAction<SetSubscribedOrdersError>,
    ) => {
      const { error } = action.payload
      state.error = error
      state.loading = false
    },
    setUnsub: (state, action: PayloadAction<{ unsub: Unsubscribe | null }>) => {
      const { unsub } = action.payload
      state.unsub = unsub
    },
    resetOrders: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.pending, (state) => {
      state.isFetching = true
    })
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data.length < ORDERS_PER_SCROLL) {
        state.isFetchedAll = true
      }
      const newOrders = uniqBy(flatten([state.data, data]), 'id') // append the NEWLY ADDED data to the start of the table
      state.data = newOrders
      state.loading = false
      state.isFetching = false
    })
    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.error = action.error.message
      state.loading = false
      state.isFetching = false
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  setSubscribedOrdersData,
  setSubscribedOrdersError,
  setUnsub,
  resetOrders,
} = ordersSlice.actions

export default ordersSlice.reducer
