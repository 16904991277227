import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SetTJError, SetTJSelectedConnection, TradeJournalState } from './types'

const initialState: TradeJournalState = {
  error: null,
  connections: [],
  datePicker: {
    startDate: '',
    endDate: '',
  },
  selectedConnection: undefined,
  loading: true,
}

export const tjSlice = createSlice({
  name: 'tradeJournal',
  initialState,
  reducers: {
    setTJSelectedConnection: (
      state,
      action: PayloadAction<SetTJSelectedConnection>,
    ) => {
      const { data } = action.payload
      state.selectedConnection = data
    },
    setTJError: (state, action: PayloadAction<SetTJError>) => {
      const { error } = action.payload
      state.error = error
      state.loading = false
    },
    setTJSummaryParams: (
      state,
      action: PayloadAction<{
        startDate: string
        endDate: string
      }>,
    ) => {
      state.datePicker = action.payload
    },

    resetTJ: () => {
      return initialState
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setTJError,
  setTJSelectedConnection,
  setTJSummaryParams,
  resetTJ,
} = tjSlice.actions

export default tjSlice.reducer
