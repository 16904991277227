import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

const numberOfWords = 30

export const Description = ({
  description,
}: {
  description: string | undefined
}) => {
  const [t] = useTranslation('')

  const [isShowMoreDesc, setIsShowMoreDesc] = useState(false)

  const getClippedTitle = useCallback(
    (title: string | undefined) => {
      if (title === undefined) {
        return '-'
      }
      if (isShowMoreDesc) {
        return title
      }
      if (title.split(' ').length > numberOfWords) {
        return title.split(' ').slice(0, numberOfWords).join(' ')
      } else {
        return title
      }
    },
    [isShowMoreDesc],
  )

  const isShowMoreText =
    !isShowMoreDesc &&
    description &&
    description.split(' ').length > numberOfWords

  return (
    <p className='watchlist-default-text mb-4'>
      {getClippedTitle(description)}{' '}
      {isShowMoreText ? (
        <span
          className='primary show-text'
          onClick={() => {
            setIsShowMoreDesc(true)
          }}
        >
          {t('watchlist:showMore')}
        </span>
      ) : (
        <span
          className='primary show-text'
          onClick={() => {
            setIsShowMoreDesc(false)
          }}
        >
          {t('watchlist:showLess')}
        </span>
      )}
    </p>
  )
}
